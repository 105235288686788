import React, { useState, useEffect, useMemo } from "react";
import styles from "./index.module.css";
import { fetchRatePlans, deleteEnergyInvoice } from "./api/api";
import { transformDateFormat } from "../utils/utils";

import Table from "./Table";
import Pagination from "./Pagination";
import ResponsiveTable from "./ResponsiveTable";
import ModalExportSavings from "./ModalExportSavings";
import TableSearcher from "./TableSearcher";
import ToolBar from "./ToolBar";

const Index = () => {
  const [ratePlans, setRatePlans] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [headerclicked, setHeaderClicked] = useState(false);
  const [clickedInvoiceId, setClickedInvoiceId] = useState(null);
  const [openModal, setOpenModal] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchRatePlans(startDate, endDate);
        setRatePlans(data);
      } catch (error) {
        console.error("Error in fetchRatePlans:", error);
      } finally {
        setLoading(false);
      }
    };
    setSelectedRows([]);

    fetchData();
  }, [startDate, endDate]);

  const handleSelectedInvInnerBody = (invoiceiD) => {
    if (invoiceiD === clickedInvoiceId) {
      setClickedInvoiceId(null);
    } else {
      setClickedInvoiceId(invoiceiD);
    }
    setHeaderClicked(!headerclicked);
  };

  const handleRowSelect = (rowId, e) => {
    e.stopPropagation();
    const isSelected = selectedRows.includes(rowId);

    if (isSelected) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const filteredRatePlans = useMemo(() => {
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();

    if (!trimmedSearchTerm) {
      return ratePlans;
    }

    return ratePlans.filter((invoice) => {
      return Object.values(invoice.invoice).some((value) => {
        const strValue =
          value !== null && value !== undefined
            ? value.toString().toLowerCase()
            : "";

        if (trimmedSearchTerm === "luz" && strValue.includes("electric")) {
          return true;
        }

        if (trimmedSearchTerm === "creado" && strValue.includes("created")) {
          return true;
        }
        if (
          trimmedSearchTerm === "informe generado" &&
          strValue.includes("generated_report")
        ) {
          return true;
        }

        return strValue.includes(trimmedSearchTerm);
      });
    });
  }, [ratePlans, searchTerm]);

  const recordsPerPage = 10;
  const records = useMemo(() => {
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    return filteredRatePlans.slice(firstIndex, lastIndex);
  }, [filteredRatePlans, currentPage, recordsPerPage]);
  const numOfPages = Math.ceil(filteredRatePlans.length / recordsPerPage);

  const changeCurrentPage = (id) => {
    setCurrentPage(id);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
  };

  let transformedStartDate = transformDateFormat(startDate);
  let transformedEndDate = transformDateFormat(endDate);

  const setStartDateAndEndDate = (newStartDate) => {
    setStartDate(newStartDate);
    if (!endDate) {
      const today = new Date();
      const formattedToday = today.toISOString().split("T")[0];
      setEndDate(formattedToday);
    }
  };

  const handleExportButtonClick = () => {
    setOpenModal(true);
  };

  return (
    <section>
      {openModal && (
        <ModalExportSavings
          selectedRows={selectedRows}
          onClose={() => setOpenModal(false)}
        />
      )}
      <div className={styles.headerContent}>
        <h3>Procesamientos</h3>
      </div>
      <TableSearcher
        searchTerm={searchTerm}
        setStartDateAndEndDate={setStartDateAndEndDate}
        setEndDate={setEndDate}
        handleReset={handleReset}
        setSearchTerm={setSearchTerm}
        startDate={startDate}
        endDate={endDate}
        transformedStartDate={transformedStartDate}
        transformedEndDate={transformedEndDate}
        selectedRows={selectedRows}
      />

      {loading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        <>
          {selectedRows.length > 0 ? (
            <ToolBar
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              onExportButtonClick={handleExportButtonClick}
            />
          ) : (
            ""
          )}
        </>
      )}
      <Table
        records={records}
        selectedRows={selectedRows}
        handleRowSelect={handleRowSelect}
        deleteEnergyInvoice={deleteEnergyInvoice}
        handleSelectedInvInnerBody={handleSelectedInvInnerBody}
        clickedInvoiceId={clickedInvoiceId}
      />
      <ResponsiveTable
        records={records}
        selectedRows={selectedRows}
        handleRowSelect={handleRowSelect}
        handleSelectedInvInnerBody={handleSelectedInvInnerBody}
        clickedInvoiceId={clickedInvoiceId}
        currentPage={currentPage}
        totalPages={numOfPages}
        onChangePage={changeCurrentPage}
        deleteEnergyInvoice={deleteEnergyInvoice}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={numOfPages}
        onChangePage={changeCurrentPage}
      />
    </section>
  );
};

export default Index;
