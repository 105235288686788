import React, { useEffect, useState } from "react";
import styles from "./formStyles.module.css";
import { useFormik } from 'formik';
import {validationSchema} from "../validations/validationSchema"

//components
import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";
import Checkbox from "../../../reactComponents/checkbox/CheckBox";

import {findValueMatch } from '../utils/utils'; 

const InformacionPersonal = ({
  organization,
  users,
  outgoingEmails,
  urlSips
}) => {

 const formik = useFormik({
  initialValues: {
    organization: {
      name: organization.name,
      code: organization.code,
      phone: organization.phone,
      process_limit: organization.process_limit,
      variance_total_gas: organization.variance_total_gas,
      variance_total_luz:  organization.variance_total_luz,
      variance_total_luz_pyme: organization.variance_total_luz_pyme,
      min_adjusted_rate_plan_price_range: organization.min_adjusted_rate_plan_price_range,
      max_adjusted_rate_plan_price_range: organization.max_adjusted_rate_plan_price_range
    },
  },
  validationSchema: validationSchema,
});
  const [username, setUsername] = useState("");

  const name = organization.name;
  const code = organization.code;
  const phone = organization.phone;
  const currentMainUserId = organization.organization_main_user_id;

 const emailManager = [
   {name: "Amazon SES", id:"amazon_ses"},
   {name: "Mailjet", id:"mailjet"},
 ];
  useEffect(() => {
    const matchingUser = users.find((item) => item.id === currentMainUserId);
    if (matchingUser) {
      const userTitle = `${matchingUser.name} ${matchingUser.surname}`;
      setUsername(userTitle);
    } else {
      setUsername("User not found");
    }
  }, [currentMainUserId, users]);

  //adapt data to be used in the select component
  const usersData = users.map((item) => ({
    id: item.id,
    name: `${item.name} ${item.surname}`,
  }));

  const outgoingMailData = outgoingEmails.map((item, index) => ({
    id: item[0],
    name: item[0],
    stringId: index
  }));

  const placeholderString = findValueMatch(
    emailManager,
    organization.email_manager
  );


  //checkboxes
  const [checked2x, setChecked2x] = useState(organization.allow_2x);
  const [checkedGas, setCheckedGas] = useState(organization.allow_gas);
  const [checkedDual, setCheckedDual] = useState(organization.allow_dual);
  const [checked3x, setChecked3x] = useState(organization.allow_3x);
  const [checked6x, setChecked6x] = useState(organization.allow_6x);

  const [checkedAllowReqInvoices, setCheckcheckedAllowReqInvoices] = useState(
    organization.allow_request_invoices
  );
  const [checkedAllowWithoutInv, setCheckedAllowWithoutInv] = useState(
    organization.allow_manual_saving_calculations
  );
  const [checkedSavingsCalculations, setCheckedSavingsCalculations] = useState(
    organization.allow_saving_calculations
  );
  const [checkedSimulations, setCheckedSimulations] = useState(
    organization.allow_simulations
  );

  const [checkedContractInfo, setCheckedContractInfo] = useState(
    organization.extract_contract_info
  );
  const [checkedSaveData, setCheckedSaveData] = useState(
    organization.store_contract_information
  );
  const [checkedTelco, setCheckedTelco] = useState(organization.allow_telco);

  const checkboxDataFuncionalidades = [
    {
      onChange: () => setChecked2x(!checked2x),
      checked: checked2x,
      name: "organization[allow_2x]",
      id: "organization_allow_2x",
      label: "¿Permitir Luz 2.0TD?",
    },
    {
      onChange: () => setCheckedGas(!checkedGas),
      checked: checkedGas,
      name: "organization[allow_gas]",
      id: "organization_allow_gas",
      label: "¿Permitir Gas?",
    },
    {
      onChange: () => setCheckedDual(!checkedDual),
      checked: checkedDual,
      name: "organization[allow_dual]",
      id: "organization_allow_dual",
      label: "¿Permitir Dual 2.0TD?",
    },
    {
      onChange: () => setChecked3x(!checked3x),
      checked: checked3x,
      name: "organization[allow_3x]",
      id: "organization_allow_3x",
      label: "¿Permitir Luz 3.0TD?",
    },
    {
      onChange: () => setChecked6x(!checked6x),
      checked: checked6x,
      name: "organization[allow_6x]",
      id: "organization_allow_6x",
      label: "¿Permitir Luz 6.XTD?",
    },
    {
      onChange: () => setCheckcheckedAllowReqInvoices(!checkedAllowReqInvoices),
      checked: checkedAllowReqInvoices,
      name: "organization[allow_request_invoices]",
      id: "organization_allow_request_invoices",
      label: "¿Permitir Solicitar Facturas?",
    },
    {
      onChange: () => setCheckedAllowWithoutInv(!checkedAllowWithoutInv),
      checked: checkedAllowWithoutInv,
      name: "organization[allow_manual_saving_calculations]",
      id: "organization_allow_manual_saving_calculations",
      label: "¿Permitir procesamientos SIN Factura?",
    },    
    {
      onChange: () => setCheckedSavingsCalculations(!checkedSavingsCalculations),
      checked: checkedSavingsCalculations,
      name: "organization[allow_saving_calculations]",
      id: "organization_allow_saving_calculations",
      label: "¿Permitir comparativas?",
    },
    {
      onChange: () => setCheckedSimulations(!checkedSimulations),
      checked: checkedSimulations,
      name: "organization[allow_simulations]",
      id: "organization_allow_simulations",
      label: "¿Permitir simulaciones?",
    },
    {
      onChange: () => setCheckedContractInfo(!checkedContractInfo),
      checked: checkedContractInfo,
      name: "organization[extract_contract_info]",
      id: "organization_extract_contract_info",
      label: "¿Extraer datos contratación?",
    },
    {
      onChange: () => setCheckedSaveData(!checkedSaveData),
      checked: checkedSaveData,
      name: "organization[store_contract_information]",
      id: "organization_store_contract_information",
      label: "¿Guardar Datos de Contratación?",
    },
    {
      onChange: () => setCheckedTelco(!checkedTelco),
      checked: checkedTelco,
      name: "organization[allow_telco]",
      id: "organization_allow_telco",
      label: "¿Permitir telco?",
    }
  ];

  const [asignUserInv, setAsignUserInv] = useState(
    organization.enable_invoice_users_assigment
  );
  const [enableCustStatus, setEnableCustStatus] = useState(
    organization.enable_customer_status_management
  );
  const [contComparativa, setContComparativa] = useState(
    organization.offer_saving
  );
  const [intCRM, setintCRM] = useState(
    organization.crm_integration_permition
  );
  const [providerModificable, setProviderModificable] = useState(
    organization.provider_modificable
  );
  const [convert2To3, setConvert2To3] = useState(organization.convert_20_to_30);
  
  const [permitIncreasePrice, setPermitIncreasePrice] = useState(organization.allow_adjust_rate_plan_price);

  const [permitInvoiceRequestAutoProcess, setPermitInvoiceRequestAutoProcess] = useState(organization.permit_invoice_request_auto_process);

  const [permitInvoicerequestutoSavingsEmail, setPermitInvoicerequestutoSavingsEmail] = useState(organization.permit_invoice_request_auto_savings_email);
  
  const [calculateCommission, setCalculateCommission] = useState(organization.calculate_commission);

  const [getAnnualConsumptionFromSips, setAnnualConsumptionFromSips] = useState(organization.get_annual_consumption_from_sips);

  const [alertLowQualityInvoice, setalertLowQualityInvoice] = useState(organization.alert_low_quality_invoice);
  const [checkExcelColumnsName, setCheckExcelColumnsName] = useState(organization.check_excel_columns_name);
  const [allowLogoHomePage, setLogoHomePage] = useState(organization.allow_logo_home_page);
  const [phoneNumberRequired, setPhoneNumberRequired] = useState(organization.phone_number_required_with_invoice_request_invoice);

  const checkboxDataFuncAdicionales = [
    {
      onChange: () => setAsignUserInv(!asignUserInv),
      checked: asignUserInv,
      name: "organization[enable_invoice_users_assigment]",
      id: "organization_enable_invoice_users_assigment",
      label: "¿Permitir asignación de usuarios a facturas? ",
    },
    {
      onChange: () => setEnableCustStatus(!enableCustStatus),
      checked: enableCustStatus,
      name: "organization[enable_customer_status_management]",
      id: "organization_enable_customer_status_management",
      label: `¿Habilitar Gestión de "Estados de Procesamientos"? `,
    },
    {
      onChange: () => setContComparativa(!contComparativa),
      checked: contComparativa,
      name: "organization[offer_saving]",
      id: "organization_offer_saving",
      label: "¿Habilitar botón de contratación en Comparativas?",
    },
    {
      onChange: () => setintCRM(!intCRM),
      checked: intCRM,
      name: "organization[crm_integration_permition]",
      id: "organization_crm_integration_permition",
      label: "¿Habilitar integración con CRM?",
    },
    {
      onChange: () => setProviderModificable(!providerModificable),
      checked: providerModificable,
      name: "organization[provider_modificable]",
      id: "organization_provider_modificable",
      label: "¿Comercializadora modificable en procesamiento?",
    },
    {
      onChange: () => setConvert2To3(!convert2To3),
      checked: convert2To3,
      name: "organization[convert_20_to_30]",
      id: "organization_convert_20_to_30",
      label: "¿Permitir convertir Luz 2x a 3x?",
    },
    {
      onChange: () => setPermitIncreasePrice(!permitIncreasePrice),
      checked: permitIncreasePrice,
      name: "organization[allow_adjust_rate_plan_price]",
      id: "organization_allow_adjust_rate_plan_price",
      label: "¿Permitir Modificar Precios en Comparativas?",
    },
    {
      onChange: () => setPermitInvoiceRequestAutoProcess(!permitInvoiceRequestAutoProcess),
      checked: permitInvoiceRequestAutoProcess,
      name: "organization[permit_invoice_request_auto_process]",
      id: "organization_permit_invoice_request_auto_process",
      label: "¿Permitir autoprocesado de solicitud de factura?",
    },
    {
      onChange: () => setPermitInvoicerequestutoSavingsEmail(!permitInvoicerequestutoSavingsEmail),
      checked: permitInvoicerequestutoSavingsEmail,
      name: "organization[permit_invoice_request_auto_savings_email]",
      id: "organization_permit_invoice_request_auto_savings_email",
      label: "¿Permitir envío automático de ahorro de solicitud de factura?",
    },
    {
      onChange: () => setPhoneNumberRequired(!phoneNumberRequired),
      checked: phoneNumberRequired,
      name: "organization[phone_number_required_with_invoice_request_invoice]",
      id: "organization_phone_number_required_with_invoice_request_invoice",
      label: "Teléfono contacto obligatorio al adjuntar factura",
    },
    {
      onChange: () => setCalculateCommission(!calculateCommission),
      checked: calculateCommission,
      name: "organization[calculate_commission]",
      id: "organization_calculate_commission",
      label: "¿Habilitar Cálculo de Comisiones?",
    },
    {
      onChange: () => setalertLowQualityInvoice(!alertLowQualityInvoice),
      checked: alertLowQualityInvoice,
      name: "organization[alert_low_quality_invoice]",
      id: "organization_alert_low_quality_invoice",
      label: "¿Habilitar Notificación Alerta Baja Calidad Fichero?",
    },
    {
      onChange: () => setCheckExcelColumnsName(!checkExcelColumnsName),
      checked: checkExcelColumnsName,
      name: "organization[check_excel_columns_name]",
      id: "check_excel_columns_name",
      label: "¿Detectar Columnas No Reconocidas al Importar Excel?",
    },
    {
      onChange: () => {
        if (!urlSips) {
          alert_missing_user_sips();
          return;
        }
        setAnnualConsumptionFromSips(!getAnnualConsumptionFromSips);
      },
      checked: getAnnualConsumptionFromSips,
      name: "organization[get_annual_consumption_from_sips]",
      id: "organization_get_annual_consumption_from_sips",
      label: "¿Habilitar Obtención de Consumo Anual de SIPS?",
    },
    {
      onChange: () => setLogoHomePage(!allowLogoHomePage),
      checked: allowLogoHomePage,
      name: "organization[allow_logo_home_page]",
      id: "organization_allow_logo_home_page",
      label: "¿Habilitar Logo principal en la página de inicio?",
    }
  ];

  {
    /* -------------------- limite procesamientos */
  }
  const limitCount = organization.process_limit;

  const [usernotifications, setUserNotifications] = useState(
    organization.send_user_management_notifications
  );
  const [downloadFiles, setDownloadFiles] = useState(
    organization.allow_invoices_export
  );


  const limiteProcesamientoData = [
    {
      onChange: () => setUserNotifications(!usernotifications),
      checked: usernotifications,
      name: "organization[send_user_management_notifications]",
      id: "organization_send_user_management_notifications",
      label: "¿Permitir enviar notificaciones al usuario? ",
    },
    {
      onChange: () => setDownloadFiles(!downloadFiles),
      checked: downloadFiles,
      name: "organization[allow_invoices_export]",
      id: "organization_allow_invoices_export",
      label: "¿Permitir descarga de ficheros facturas? ",
    },
  ];

  return (
    <>
      <h3 className={styles.title}>Información Personal</h3>
      <div className={styles.formContainerBlock}>
        <Input
          label={
            <>
              Nombre <span style={{ color: "red" }}>*</span>
            </>
          }
          type="text"
          name="organization[name]"
          id="organization_name"
          placeholder={name}
          value={formik.values.organization.name}
          required="required"
          onChange={(e) => formik.handleChange(e)}
          error={formik.errors.organization?.name}
          touched={formik.touched.organization?.name}
        />
        <Input
          label={
            <>
              Código Organización <span style={{ color: "red" }}>*</span>
            </>
          }
          type="text"
          name="organization[code]"
          id="organization_code"
          placeholder={code}
          value={formik.values.organization.code}
          required="required"
          onChange={(e) => formik.handleChange(e)}
          error={formik.errors.organization?.code}
          touched={formik.touched.organization?.code}
        />
        <Input
          label={
            <>
              Teléfono <span style={{ color: "red" }}>*</span>
            </>
          }
          type="text"
          name="organization[phone]"
          id="organization_phone"
          placeholder={phone}
          value={formik.values.organization.phone}
          required="required"
          onChange={formik.handleChange}
          error={formik.errors.organization?.phone}
          touched={formik.touched.organization?.phone}
        />
      </div>
      <div className={styles.selectsContainer}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Usuario principal de la Organización"
          placeholder={username}
          data={usersData}
          dataSelected={[organization.organization_main_user_id]}
          selectId="organization_organization_main_user_id"
          selectName="organization[organization_main_user_id]"
          className={styles.titleSelect}
        />
        <span className={styles.space}></span>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Correo saliente"
          data={outgoingMailData}
          dataSelected={organization.email_account}
          selectId="organization_email_account"
          className={styles.titleSelect}
          selectName="organization[email_account]"
          placeholder={organization.email_account}
        />
      </div>
      <div className={styles.selectsContainer}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Gestor de envio de correos"
          data={emailManager}
          dataSelected={organization.email_manager}
          selectId="organization_email_manager"
          selectName="organization[email_manager]"
          placeholder={placeholderString}
        />
      </div>
      <div className={styles.checkboxContainer}>
        <div className={styles.checkboxDiv}>
          <h6>Funcionalidades</h6>
          <div className={styles.scrollDiv}>
            <div className={styles.checkBoxContent}>
              {checkboxDataFuncionalidades.map((item) => {
                return (
                  <Checkbox
                    key={item.id}
                    onChange={item.onChange}
                    checked={item.checked}
                    name={item.name}
                    id={item.id}
                    label={item.label}
                    title="Funcionalidades"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <span className={styles.space}></span>
        <div className={styles.checkboxDiv}>
          <h6>Funcionalidades Adicionales</h6>
          <div className={styles.scrollDiv}>
            <div className={styles.checkBoxContent}>
              {checkboxDataFuncAdicionales.map((item) => {
                return (
                  <Checkbox
                    key={item.id}
                    onChange={item.onChange}
                    checked={item.checked}
                    name={item.name}
                    id={item.id}
                    label={item.label}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {permitIncreasePrice && (
  <div className={styles.rangePriceContainer}>
    <h6 className={styles.headerColorBlock}>Configuración de Ajuste de Precios en Comparativas</h6>
    <p className={styles.smallText}>
     Defina un rango de precios para ajustar las comparativas: Esta funcionalidad le permite establecer un rango de precios aplicable a las comparativas. Al seleccionar un valor dentro de este rango, este se añadirá al importe base de cada precio de energia en las comparativas. 
    </p>
    <div className={styles.rangeInputs}>
      <div className={styles.formGroup}>
          <label>Precio Minimo Aplicable:</label>
          <Input
            type="text"
            name="organization[min_adjusted_rate_plan_price_range]"
            id="organization_min_adjusted_rate_plan_price_range"
            onChange={formik.handleChange}
            value={formik.values.organization?.min_adjusted_rate_plan_price_range}
            error={formik.errors.organization?.min_adjusted_rate_plan_price_range}
            touched={formik.touched.organization?.min_adjusted_rate_plan_price_range}  
          />
      </div>
      <div className={styles.formGroup}>
        <label>Precio Máximo Aplicable:</label>
        <Input
          type="text"
          name="organization[max_adjusted_rate_plan_price_range]"
          id="organization_max_adjusted_rate_plan_price_range"
          onChange={formik.handleChange}
          value={formik.values.organization?.max_adjusted_rate_plan_price_range}
          error={formik.errors.organization?.max_adjusted_rate_plan_price_range}
          touched={formik.touched.organization?.max_adjusted_rate_plan_price_range}  
        />
      </div>
    </div>
  </div>
)}
      <div className={styles.formBlockContainer}>
        <div className={styles.selectsContainer}>
          <div className={styles.limiteProcesamientosContainer}>
            <div className={styles.inputProcesamiento}>
              <h6 className={styles.headerColorBlock}>
                Límite de procesamiento
              </h6>
              <p className={styles.smallText}>
                Este campo indica el número máximo de procesamiento que se pueda
                realizar. Si no pone nada, es que no hay límite.
              </p>
              <Input
                type="text"
                name="organization[process_limit]"
                id="organization_process_limit"
                placeholder={limitCount}
                onChange={formik.handleChange}
                value={formik.values.organization?.process_limit}
                error={formik.errors.organization?.process_limit}
                touched={formik.touched.organization?.process_limit}
              />
            </div>
            <div className={styles.checkboxcontentLimite}>
              {limiteProcesamientoData.map((item) => {
                return (
                  <Checkbox
                    key={item.id}
                    onChange={item.onChange}
                    checked={item.checked}
                    name={item.name}
                    id={item.id}
                    label={item.label}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function alert_missing_user_sips() {
  var confirmationMessage = "Consumo Anual de SIPS no disponible";
  var confirmModalTitle = $('#confirm-modal-title');
  var confirmMsg = $('#confirm-msg');
  confirmModalTitle.text(confirmationMessage);
  confirmMsg.html("Para habilitar esta funcionalidad, es necesario tener conexión con SIPS.");
  $("#confirm-modal").modal("toggle");
  $('#btn-no').hide();
  $('#btn-yes').hide();
}

export default InformacionPersonal;
