import React, {useRef, useEffect} from "react";
import {
  ModalContainer,
  ModalContent,
  ModalTitle,
  CloseModalButton,
  Ul,
  Submit,
} from "../../../styles/modals";
import { IoCloseSharp } from "react-icons/io5";

export const ModalInfo = ({  closeModal }) => {
  const modalContentRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
    const handleClickOutside = (e) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(e.target)
    ) {
      closeModal();
    }
  };
  return (
    <ModalContainer>
      <ModalContent ref={modalContentRef}>
        <ModalTitle>Ten en cuenta lo siguiente:</ModalTitle>
        <CloseModalButton onClick={() => closeModal()}>
          <IoCloseSharp size={25} />{" "}
        </CloseModalButton>
        <Ul>
          <li>Adjunta una factura lo más <strong>reciente </strong>posible.</li>
          <li>
            Puedes adjuntar un<strong> PDF, imagen (jpg o png) </strong> o <strong>foto</strong> desde tu móvil.
          </li>
          <li>
            Puedes adjuntar un <strong> máximo de 5 facturas </strong>, para ello pulsa el botón
            "Añadir otra factura" y se desplegará un nuevo apartado.
          </li>
        </Ul>
        <div style={{ width: "100px", margin: "-10px auto 15px auto" }}>
          <Submit
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            Entendido
          </Submit>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};
