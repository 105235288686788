import styled from "styled-components";

export const ModalContainer = styled.div`
  z-index: 1031;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;
export const ModalContent = styled.div`
  background-color: #ffff;
  padding: 20px 20px 0px 20px;
  border-radius: 8px;
  max-width: 650px;
  width: 100%;
  max-height: 410px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ModalTitle = styled.h3`
  width: 100%;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
  color: #21103d;
`;
export const Comments = styled.div`
  margin-top: 18px;
  margin-bottom: 10px;
  height: 100%;
  overflow-y: scroll;
  padding-left: 20px;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #21103d;
    border-radius: 8px;
  }
`;

export const Submit = styled.button`
  border: none;
  background-color: #21103d;
  color: #fff;
  border-radius: 8px;
  padding: 8px 24px;
  margin-top: 15px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const CommentForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: red;
`;

export const CloseModalButton = styled.button`
  border: none;
  background-color: transparent;
  color: #21103d;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  :focus {
    outline: none;
  }
`;
export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
  margin: 10px 0px 10px 0px;
`;
export const Ul = styled.ul`
  margin-top: 20px;
  li {
    margin-bottom: 10px;
  }

`;

export const Button = styled.button`
  border: 2px solid #21103d;
  background-color: transparent;
  border-radius: 8px;
  color: #21103d;
  font-size: 20px;
  cursor: pointer;
  width: 100px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  :hover {
    background-color: #21103d;
    color: #fff;
  }
  :focus {
    outline: none;
  }
`;
