import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validateClient, idMatch, isTelfOrEmail } from "../edit/valition";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
const Index = () => {
  const handleSubmit = async (values) => {
    try {
      await axios.post(
        `/admin/clients`,
        {
          client: {
            name: values.name.trim(),
            nif: values.nif.trim().toUpperCase(),
            phone_number: values.telf,
            email: values.email.trim(),
            description: values.desc.trim(),
            id_type: values.idType,
          },
        },
        {
          headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        }
      );
      toast.success(<div>Cliente creado con éxito</div>);
      setTimeout(function() {
        window.location.href = "/admin/clients";
      }, 1500);
    } catch (error) {
      error.response?.data.errors.map(error => toast.error(<div>{error}</div>))
    }
  };

  const fieldGroup = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 20px",
    with: "90%",
  };
  const errorFeedback = {
    color: "red",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "5px",
    width: "100%",
    maxWidth: "300px",
    fontWeight: "bold",
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Toaster />
      <h2 style={{ color: "#21103d" }}>Crear cliente</h2>
      <Formik
        initialValues={{
          name: "",
          nif: "",
          telf: "",
          email: "",
          desc: "",
          idType: "",
        }}
        validationSchema={validateClient}
        onSubmit={(values, actions) => {
          if (!idMatch(values, actions)) return;
          if (!isTelfOrEmail(values, actions)) return;
          handleSubmit(values);
        }}
      >
        <Form
          style={{
            width: "100%",
            maxWidth: "700px",
            border: "4px solid #21103d",
            backgroundColor: "#f5f5f5",
            borderRadius: "10px",
            padding: "20px 0px",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}
        >
          <div style={fieldGroup}>
            <label
              style={{ color: "#343434d9", marginLeft: "-150px" }}
              htmlFor="name"
            >
              Nombre
            </label>
            <Field
              style={{
                border: "0px",
                borderRadius: "0.55rem",
                textAlign: "center",
                padding: "5px",
              }}
              type="text"
              id="name"
              name="name"
              placeholder="Nombre del cliente"
            />
            <div style={errorFeedback}>
              <ErrorMessage name="name" />
            </div>
          </div>

          <div style={fieldGroup}>
            <label
              style={{ color: "#343434d9", marginLeft: "-150px" }}
              htmlFor="nif"
            >
              DNI
            </label>
            <Field
              style={{
                border: "0px",
                borderRadius: "0.55rem",
                textAlign: "center",
                padding: "5px",
              }}
              type="text"
              id="nif"
              name="nif"
              placeholder="DNI del cliente"
            />
            <div style={errorFeedback}>
              <ErrorMessage name="nif" />
            </div>
          </div>

          <div style={fieldGroup}>
            <label
              style={{ color: "#343434d9", marginLeft: "-150px" }}
              htmlFor="telf"
            >
              Telefono
            </label>
            <Field
              style={{
                border: "0px",
                borderRadius: "0.55rem",
                textAlign: "center",
                padding: "5px",
              }}
              type="number"
              id="telf"
              name="telf"
              placeholder="Telefono del cliente"
            />
            <div style={errorFeedback}>
              <ErrorMessage name="telf" />
            </div>
          </div>

          <div style={fieldGroup}>
            <label
              style={{ color: "#343434d9", marginLeft: "-150px" }}
              htmlFor="email"
            >
              Correo
            </label>
            <Field
              style={{
                border: "0px",
                borderRadius: "0.55rem",
                textAlign: "center",
                padding: "5px",
              }}
              type="email"
              id="email"
              name="email"
              placeholder="Correo del cliente"
            />
            <div style={errorFeedback}>
              <ErrorMessage name="email" />
            </div>
          </div>
          <div style={fieldGroup}>
            <label
              style={{ color: "#343434d9", marginLeft: "-120px" }}
              htmlFor="desc"
            >
              Descripcion
            </label>
            <Field
              style={{
                border: "0px",
                borderRadius: "0.55rem",
                textAlign: "start",
                height: "80px",
                padding: "5px",
              }}
              as="textarea"
              id="desc"
              name="desc"
              placeholder="Descripcion del cliente"
            />
          </div>
          <div style={fieldGroup}>
            <label
              style={{ color: "#343434d9", marginLeft: "-150px" }}
              htmlFor="idType"
            >
              Tipo de ID
            </label>
            <Field
              as="select"
              id="idType"
              name="idType"
              style={{
                border: "0px",
                borderRadius: "0.55rem",
                textAlign: "center",
                padding: "5px",
                width: "220px",
              }}
            >
              <option value="" label="Seleccionar" />
              <option value="cif" label="CIF" />
              <option value="cif_other" label="CIF Otro" />
              <option value="nif" label="NIF" />
              <option value="nie" label="NIE" />
            </Field>
            <div style={errorFeedback}>
              <ErrorMessage name="idType" />
            </div>
          </div>
          <div
            style={{ ...fieldGroup, gridColumn: "1 / -1", textAlign: "center" }}
          >
            <button
              style={{
                border: "none",
                backgroundColor: "#21103d",
                padding: "5px 10px",
                borderRadius: "5px",
                color: "#f5f5f5",
              }}
              type="submit"
            >
              Crear cliente
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Index;
