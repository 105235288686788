import React, { useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  FiltersRow,
  FilterItem,
  TechFiltersRow,
} from "../../../styles/individualComponents/AddTelcoProduct";
import { Title4 } from "../../../styles/individualComponents/AddTelcoProduct";

const MobileFilters = ({ mobileFilters, handleMobileFilterChange, env_color }) => {
  const [selectedMinutesNationals, setSelectedMinutesNationals] = useState(0);
  const [selectedMinutesInternationals, setSelectedMinutesInternationals] = useState(0);
  const [selectedData, setSelectedData] = useState(0);
  const [showCallSmsFilters, setShowCallSmsFilters] = useState(false);
  const [showDataFilters, setShowDataFilters] = useState(false);

  useEffect(() => {
    if (mobileFilters.minsNationalsUnlimited) setSelectedMinutesNationals(0);
    if (mobileFilters.minsInternationalsUnlimited) setSelectedMinutesInternationals(0);
    if (mobileFilters.isDataUnlimited) setSelectedData(0);
  }, [mobileFilters]);

  useEffect(() => {
    handleMobileFilterChange('minsNationals', selectedMinutesNationals);
  }, [selectedMinutesNationals]);

  useEffect(() => {
    handleMobileFilterChange('minsInternationals', selectedMinutesInternationals);
  }, [selectedMinutesInternationals]);

  useEffect(() => {
    handleMobileFilterChange('numGb', selectedData);
  }, [selectedData]);

  const handleCheckboxChange = (filterType, value) => { // Shows and hide the slide bars according to the behaviour of the checkboxes 
    handleMobileFilterChange(filterType, value);
    if (['minsNationalsUnlimited', 'minsInternationalsUnlimited', 'isDataUnlimited'].includes(filterType)) {
      if (filterType === 'minsNationalsUnlimited') setSelectedMinutesNationals(0);
      if (filterType === 'minsInternationalsUnlimited') setSelectedMinutesInternationals(0);
      if (filterType === 'isDataUnlimited') setSelectedData(0);
    }
  };

  return (
    <>
      <Title4 env_color={env_color} onClick={() => setShowCallSmsFilters(!showCallSmsFilters)}>
        Filtros Llamadas/SMS&nbsp;
        {showCallSmsFilters ? (
          <IoIosArrowUp className="arrowIcon" />
        ) : (
          <IoIosArrowDown className="arrowIcon" />
        )}
      </Title4>
      {showCallSmsFilters && (
        <>
          <FiltersRow>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.minsNationalsUnlimited}
                  onChange={(e) => handleCheckboxChange('minsNationalsUnlimited', e.target.checked)}
                />
                Minutos nacionales ilimitados
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.minsInternationalsUnlimited}
                  onChange={(e) => handleCheckboxChange('minsInternationalsUnlimited', e.target.checked)}
                />
                Minutos internacionales ilimitados
              </label>
            </FilterItem>
          </FiltersRow>
          {!mobileFilters.minsNationalsUnlimited && (
            <FiltersRow>
              <FilterItem style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <span style={{ marginRight: '10px' }}>
                  Minutos nacionales:
                </span>
                <input
                  type="range"
                  min="0"
                  max="1000"
                  step="10"
                  value={selectedMinutesNationals}
                  onChange={(e) => setSelectedMinutesNationals(e.target.value)}
                  style={{ flexGrow: 1, marginRight: '10px' }}
                />
                <span>{selectedMinutesNationals} minutos</span>
              </FilterItem>
            </FiltersRow>
          )}
          {!mobileFilters.minsInternationalsUnlimited && (
            <FiltersRow>
              <FilterItem style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <span style={{ marginRight: '10px' }}>
                  Minutos internacionales:
                </span>
                <input
                  type="range"
                  min="0"
                  max="1000"
                  step="10"
                  value={selectedMinutesInternationals}
                  onChange={(e) => setSelectedMinutesInternationals(e.target.value)}
                  style={{ flexGrow: 1, marginRight: '10px' }}
                />
                <span>{selectedMinutesInternationals} minutos</span>
              </FilterItem>
            </FiltersRow>
          )}
          <FiltersRow>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.smsNationalsUnlimited}
                  onChange={(e) => handleMobileFilterChange('smsNationalsUnlimited', e.target.checked)}
                />
                SMS nacionales ilimitados
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.smsInternationalsUnlimited}
                  onChange={(e) => handleMobileFilterChange('smsInternationalsUnlimited', e.target.checked)}
                />
                SMS internacionales ilimitados
              </label>
            </FilterItem>
          </FiltersRow>
        </>
      )}

      <Title4 env_color={env_color} onClick={() => setShowDataFilters(!showDataFilters)}>
        Filtros Datos Móviles&nbsp;
        {showDataFilters ? (
          <IoIosArrowUp className="arrowIcon" />
        ) : (
          <IoIosArrowDown className="arrowIcon" />
        )}
      </Title4>
      {showDataFilters && (
        <>
          <FiltersRow>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.isDataShared}
                  onChange={(e) => handleCheckboxChange('isDataShared', e.target.checked)}
                />
                Datos compartidos
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.isDataUnlimited}
                  onChange={(e) => handleCheckboxChange('isDataUnlimited', e.target.checked)}
                />
                Datos ilimitados
              </label>
            </FilterItem>
          </FiltersRow>
          {!mobileFilters.isDataUnlimited && (
            <FiltersRow>
              <FilterItem style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <span style={{ marginRight: '10px' }}>
                  Datos Móviles:
                </span>
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  value={selectedData}
                  onChange={(e) => setSelectedData(e.target.value)}
                  style={{ flexGrow: 1, marginRight: '10px' }}
                />
                <span>{selectedData} GB</span>
              </FilterItem>
            </FiltersRow>
          )}
          <TechFiltersRow>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.tech3G}
                  onChange={(e) => handleMobileFilterChange('tech3G', e.target.checked)}
                />
                Tecnología 3G
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.tech4G}
                  onChange={(e) => handleMobileFilterChange('tech4G', e.target.checked)}
                />
                Tecnología 4G
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.tech5G}
                  onChange={(e) => handleMobileFilterChange('tech5G', e.target.checked)}
                />
                Tecnología 5G
              </label>
            </FilterItem>
          </TechFiltersRow>
          <FiltersRow>
            <span style={{ marginRight: '10px'}}>Roaming:</span>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.roaming.includes('No')}
                  onChange={(e) => handleCheckboxChange('roaming', e.target.checked ? [...mobileFilters.roaming, 'No'] : mobileFilters.roaming.filter(item => item !== 'No'))}
                />
                No
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.roaming.includes('Europa')}
                  onChange={(e) => handleCheckboxChange('roaming', e.target.checked ? [...mobileFilters.roaming, 'Europa'] : mobileFilters.roaming.filter(item => item !== 'Europa'))}
                />
                Europa
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.roaming.includes('Andorra')}
                  onChange={(e) => handleCheckboxChange('roaming', e.target.checked ? [...mobileFilters.roaming, 'Andorra'] : mobileFilters.roaming.filter(item => item !== 'Andorra'))}
                />
                Andorra
              </label>
            </FilterItem>
            <FilterItem>
              <label>
                <input
                  type="checkbox"
                  checked={mobileFilters.roaming.includes('Reino Unido')}
                  onChange={(e) => handleCheckboxChange('roaming', e.target.checked ? [...mobileFilters.roaming, 'Reino Unido'] : mobileFilters.roaming.filter(item => item !== 'Reino Unido'))}
                />
                Reino Unido
              </label>
            </FilterItem>
          </FiltersRow>
        </>
      )}
    </>
  );
};

export default MobileFilters;
