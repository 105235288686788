// informPrices.jsx

import axios from "axios";
const informPrices = async (params) => {
  const data = params
  try {
    const response = await axios.post("/api/v2/inform-prices-email", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response;
  } catch (error) {
    throw new Error(`Error in informPrices function: ${error.message}`);
  }
};

export default informPrices;
