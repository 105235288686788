const gasPriceAdapter = (gasPrice) => {
  let gasPricesAdapted = {
    id: gasPrice.id,
    planType: gasPrice.plan_type,
    fixedPrice: gasPrice.fixed_price,
    variablePrice: gasPrice.variable_price,
    indexedPrice: gasPrice.indexed_variable_price,
    discounts: {
      fixedDiscount: gasPrice.fixed_discount,
      variableDiscount: gasPrice.variable_discount,
    },
  };
  return gasPricesAdapted;
};
export default gasPriceAdapter;
