import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import {
  Status,
  TextField,
  SearchButton,
  SearchForm,
} from "../../../../styles/rate_plans";

export const Searcher = ({unMarkAllRecords, setFilters, filters }) => {
  const handleSubmit = (text, status) => {
    if (filters.status != status) {
      unMarkAllRecords();
    }
    setFilters({
      ...filters,
      textField: text,
      status: status,
    });
  };

  return (
    <SearchForm
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e.target.searcher.value, e.target.status.value);
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          width: "98%",
          borderBottom: "2px solid #21103D",
          display: "flex",
        }}
      >
        <TextField
          type="text"
          placeholder="Buscar por nombre, tipo o segmento de negocio"
          name="searcher"
        />
        <SearchButton type="submit">
          <AiOutlineSearch size={25} color={"#21103D"} />
        </SearchButton>
      </div>
      <Status >
        <strong style={{ marginRight: "10px" }}>Mostrar planes</strong>
        <select
          onChange={(e) => {
            handleSubmit(
              e.target.form.searcher.value,
              e.target.value
            );
          }}
          name="status"
        >
          <option value="Active">Activados</option>
          <option value="Inactive">Desactivados</option>
        </select>
      </ Status>
    </SearchForm>
  );
};
