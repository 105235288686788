import * as yup from "yup";

const phoneRegex = /^\(?([6-7]{1})\)?([0-9]{2})(\s)?([0-9]{3})(\s)?([0-9]{3})$/;

const nifRegex = {
  nif: /^\d{8}[A-Za-z]$/,
  cif: /(^[A-Fa-f](?:\d{8}|\d{7}[A-Ja-j])$)/,
  cif_other: /^[G-Wg-w]\d{8}$/,
  nie: /^[XYZxyz]\d{7}[A-Ha-hJ-NP-TV-Zj-np-tv-z]$/,
};

const email = yup.string().email("Correo invalido");

const phone = yup
  .string()
  .matches(phoneRegex, "Por favor ingrese un número de teléfono válido.");

const name = yup
  .string()
  .min(4, "Nombre demasiado corto")
  .max(30, "Nombre demasiado largo")
  .matches(/^[A-zÀ-ú\s]+$/, "Introduzca un nombre válido")
  .required("Debe introducir su nombre");


export const idMatch = (values, actions) => {
  if (values.nif === "" && values.idType === "") return true
  if (!values.nif.match(nifRegex[values.idType])) {
    actions.setErrors({
      nif: "El documento no coincide con el tipo seleccionado",
    });
    return false;
  }
  return true;
};

export const isTelfOrEmail = (values, actions) => {
  if (!values.telf && !values.email) {
    actions.setErrors({
      telf: "Debe introducir un teléfono o un correo",
    });
    return false;
  }
  return true;
};

export const validateClient = yup.object().shape({
  name: name,
  email: email,
  telf: phone,
  //idType: idType,
});
