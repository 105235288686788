import React, { useState } from "react";
import styles from "./historicoTarifario.module.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const HistoricoTarifarioGas = ({ gasPricesData }) => {
  const [toggle, setToggle] = useState(false);
  const handleToggleClick = () => {
    setToggle(!toggle);
  };

  const pricesGas = (gasPricesData) => {
    return (
      <div key={gasPricesData.id} className={styles.preciosContainer}>
        <div className={styles.historicoPrice}>
          <p>
            <span className={styles.tarifaDetails}> Tarifa de Acceso: </span>{" "}
            {gasPricesData.access_toll}
          </p>
          <p>
            <span className={styles.tarifaDetails}> Precio Fijo: </span>{" "}
            {gasPricesData.fixed_price}
          </p>
          <p>
            <span className={styles.tarifaDetails}> Precio Variable: </span>{" "}
            {gasPricesData.variable_price}
          </p>
          <p>
            <span className={styles.tarifaDetails}> Fecha Inicio: </span>{" "}
            {gasPricesData.created_at}
          </p>
          <p>
            <span className={styles.tarifaDetails}> Precio Energía P1: </span>{" "}
            {gasPricesData.closed_date}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.historicoContainer}>
      <p onClick={handleToggleClick} className={styles.button}>
        Histórico Precios
        {toggle ? (
          <IoMdArrowDropup className={styles.icon} />
        ) : (
          <IoMdArrowDropdown className={styles.icon} />
        )}
      </p>
      {toggle && gasPricesData.map(pricesGas)}
    </div>
  );
};

export default HistoricoTarifarioGas;
