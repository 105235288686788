import React from "react";
import styles from "./checkbox.module.css";

import Input from "../input/Input";
const Checkbox = ({ label, checked, onChange, title, name, id }) => {
  return (
    <div className={styles.checkboxContainer}>
      {/* Hidden input to ensure the value is sent to the server */}
      <Input type="hidden" value="false" name={name} id={id} />
      <div className={styles.checkboxWrapper}>
    <input
      type="checkbox"
      value="true"
      onChange={onChange}
      checked={checked}
      name={name}
      id={id}
      className={styles.checkbox}
    />
    <p className={styles.textCheckbox}>{label}</p>
  </div>
    </div>
  );
};

export default Checkbox;
