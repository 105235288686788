import React from "react";
import {
  Container,
  Title,
  Column,
  Price,
} from "../../../../styles/individualComponents/PlanDetails";

export const GasPrices = ({ pricing, isIndexedPrice }) => {
  const { variablePrice, fixedPrice, discounts, indexedPrice } = pricing;
  return (
    <Container>
      <Column>
        <Title>
          Precio variable {isIndexedPrice ? <> (indexado)</> : null}{" "}
        </Title>
        {isIndexedPrice ? (
          <>
            {indexedPrice > 0.0 ? (
              <Price>
                {indexedPrice} €/kWh
                {discounts.variableDiscount > 0.0 ? (
                  <span>(-{parseInt(discounts.variableDiscount)}% dto) </span>
                ) : null}{" "}
              </Price>
            ) : null}
          </>
        ) : (
          <Price>
            {variablePrice} €/kWh
            {discounts.variableDiscount > 0.0 ? (
              <span>(-{parseInt(discounts.variableDiscount)}% dto) </span>
            ) : null}{" "}
          </Price>
        )}
      </Column>
      <Column>
        <Title>Precio fijo</Title>
        {fixedPrice > 0.0 ? (
          <Price>
            {fixedPrice} €/mes{" "}
            {discounts.fixedDiscount > 0.0 ? (
              <span> (-{parseInt(discounts.fixedDiscount)}% dto)</span>
            ) : null}
          </Price>
        ) : null}
      </Column>
    </Container>
  );
};
