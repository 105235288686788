import React, { useEffect, useState } from "react";
import styles from "./searchAndMultiSelect.module.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";

const Index = ({ ratePlans, selectedPlans, selectId }) => {
  const [selectedOptions, setSelectedOptions] = useState(ratePlans);
  const [showListDiv, setShowListDiv] = useState(false);
  const [selected, setSelected] = useState(new Set(selectedPlans));
  const [inputValue, setInputValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectedOptions(ratePlans);
    setSelected(new Set(selectedPlans));
  }, [ratePlans, selectedPlans]);

  useEffect(() => {
    updateHiddenSelect(selected);
  }, [selected]);

  const toggleDiv = () => {
    setShowListDiv(!showListDiv);
    setInputValue("");
  };

  const updateHiddenSelect = (selected) => {
    const hiddenSelect = document.getElementById(selectId);

    if (hiddenSelect) {
      const existingOptions = hiddenSelect.querySelectorAll("option");

      existingOptions.forEach((option) => {
        option.removeAttribute("selected");
      });

      if (selected.size > 0) {
        selected.forEach((value) => {
          const option = hiddenSelect.querySelector(`option[value="${value}"]`);
          if (option) {
            option.setAttribute("selected", "selected");
          }
        });
      }
      hiddenSelect.style.display = "none";
    }
  };

  const toggleSelected = (item, e) => {
    setSelected((selected) => {
      const newSelected = new Set(selected);
      if (newSelected.has(item)) {
        newSelected.delete(item);
      } else {
        newSelected.add(item);
      }
      if (selectAll) {
        setSelectAll(false);
      }
      updateHiddenSelect(newSelected);
      return newSelected;
    });
  };

  const toggleSelectAll = () => {
    const itemsInFilter = selectedOptions.filter((option) =>
      option.rate_plan_name.toLowerCase().includes(inputValue.toLowerCase())
    );

    const allSelectedInFilter = itemsInFilter.every((item) =>
      selected.has(item.id)
    );

    if (allSelectedInFilter) {
      // If all items in the filter are already selected, unselect all items in the filter
      let itemsToUnselect = itemsInFilter.map((option) => option.id);

      // Remove the items to be unselected from the selected Set
      let updatedSelected = new Set(
        [...selected].filter((item) => !itemsToUnselect.includes(item))
      );

      setSelected(updatedSelected);
      updateHiddenSelect(updatedSelected);
      setSelectAll(false);
    } else {
      const updateSelected = new Set(selected); // Ensure immutability by creating a new Set
      // If at least one item in the filter is not selected, select all items in the filter
      let itemsToSelect = selectedOptions
        .filter((option) =>
          option.rate_plan_name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((option) => option.id);

      // Add the newly selected items to the Set
      for (const item of itemsToSelect) {
        updateSelected.add(item);
      }

      setSelected(updateSelected);
      updateHiddenSelect(updateSelected);
      setSelectAll(true);
    }
  };

  const searchFilter = () => {
    let allItems = selectedOptions;
    let match = [];

    if (inputValue) {
      match = selectedOptions.filter((plan) =>
        plan.rate_plan_name.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (match.length === 0) {
        return <div className={styles.errorMessage}>No hay coincidencias</div>;
      } else {
        allItems = match;
      }
    }

    return allItems.map((option) => (
      <li
        onClick={(e) => toggleSelected(option.id, e)}
        key={option.id}
        value={option.id}
        className={`${styles.listOptions} ${matchedSelected(option.id)}`}
      >
        {option.rate_plan_name + " | " + option.code + " | " + option.description}
      </li>
    ));
  };

  const matchedSelected = (item) => {
    return selected.has(item) ? styles.selectedItemColour : "";
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Planes tarifarios:</p>
      <div onClick={() => toggleDiv()} className={styles.selectHeader}>
        <span>{selected.size} planes seleccionados</span>
        {showListDiv ? (
          <IoMdArrowDropup className={styles.icon} />
        ) : (
          <IoMdArrowDropdown className={styles.icon} />
        )}
      </div>
      <div className={styles.toggledDiv}>
        {showListDiv && (
          <ul value={selectedOptions} className={styles.customSelector}>
            <div className={styles.searchBarContainer}>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  id="selectAllCheckbox"
                  name="selectAllCheckbox"
                  value="checked"
                  onChange={() => toggleSelectAll()}
                  checked={selectAll}
                />
                <label htmlFor="selectAllCheckbox">
                  Selecciona todos los planes
                </label>
              </div>
              <AiOutlineSearch className={styles.searchIcon} />
              <input
                className={styles.searchBar}
                placeholder="Buscar..."
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            {searchFilter()}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Index;
