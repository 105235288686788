export const findValueMatch = (array, valuePlaceholder) => {
  let matchingName = null;

  array.forEach((option) => {
    if (option.id === valuePlaceholder) {
      valuePlaceholder = option.id;
      matchingName = option.name;
    }
  });
  return matchingName;
};
