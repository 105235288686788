import React, { useState } from "react";
import styles from "./index.module.css";
import { CgImport } from "react-icons/cg";
import {
  energyInvoicesMassiveDownload
} from "./api/api";
import {
  MdSimCardDownload
} from "react-icons/md";

const ButtonsContainer = ({
  startDate,
  endDate,
  transformedEndDate,
  transformedStartDate,
}) => {
  const [showExports, setShowExports] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMassiveDownload = async () => {
    try {
      setLoading(true);
      await energyInvoicesMassiveDownload(
        transformedStartDate,
        transformedEndDate
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.buttonsDivContainer}>
      {loading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        ""
      )}

      <div>
        <button
          onClick={() => setShowExports(!showExports)}
          className={styles.exportBtn}
        >
          <CgImport className={styles.exportIcon} />
          Exportar
        </button>
        {showExports && (
          <div
            onClick={() => setShowExports(false)}
            className={styles.btnsLink}
          >
            <button className={styles.btn}>
              <a href="/admin/energy_invoices_dual.xlsx">Exportar Dual</a>
            </button>
            <button className={styles.btn}>
              <a href="/admin/energy_invoices_gas.xlsx">Exportar Gas</a>
            </button>
            <button className={styles.btn}>
              <a href="/admin/energy_invoices_luz_2x.xlsx">
                Exportar Luz 2.0TD
              </a>
            </button>
            <button className={styles.btn}>
              <a href="/admin/energy_invoices_luz_3x.xlsx">
                Exportar Luz 3.0TD
              </a>
            </button>
          </div>
        )}
      </div>
      {startDate !== "" && endDate !== "" ? (
        <button className={styles.exportBtn} onClick={handleMassiveDownload}>
          <MdSimCardDownload className={styles.exportIcon} />
          Descargar
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ButtonsContainer;
