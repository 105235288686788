import React from 'react';
import {
  Title2,
  ProductsGrid,
  ProductItem,
  Title3,
  ProductsWrapper,
} from "../../../styles/individualComponents/AddTelcoProduct";
import renderProductDetails from './renderProductDetails';

const ProductSelection = ({ filteredProducts, selectedProduct, handleProductSelect, env_color }) => {
  return (
    <>
      {filteredProducts.length > 0 ? (
        <>
          <Title2 env_color={env_color}>Elija el producto</Title2>
          <ProductsWrapper>
            <ProductsGrid>
              {filteredProducts.map((product) => (
                <ProductItem
                  key={product.id}
                  env_color={env_color}
                  isSelected={selectedProduct && selectedProduct.id === product.id}
                  onClick={() => handleProductSelect(product)}
                >
                  <Title3 env_color={env_color}>{product.name.charAt(0).toUpperCase() + product.name.slice(1).toLowerCase()}</Title3>
                  {renderProductDetails(product)}
                </ProductItem>
              ))}
            </ProductsGrid>
          </ProductsWrapper>
        </>
      ) : (
        <p style={{ color: 'red' , marginTop: '20px'}}>No hay productos disponibles</p>
      )}
    </>
  );
};

export default ProductSelection;
