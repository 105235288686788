import React from "react";
import { useFormik } from "formik";
import { validationSchema } from "../validations/validationSchema";
import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";
import styles from "../../organizaciones/edit/formStyles.module.css";
import { capitalizeFirstLetter, findValueMatch } from "../utils/utils";
const Index = ({ area, regions }) => {
  const formik = useFormik({
    initialValues: {
      area: {
        area_name: area.area_name,
        area_code: area.area_code,
        search_keyword_1: area.search_keyword_1,
        state: area.state,
      },
    },
    validationSchema: validationSchema,
  });

  const areaTypes = [
    {
      id: "provincia",
      name: "Provincia",
    },
    {
      id: "comunidad",
      name: "Comunidad",
    },
    {
      id: "otro",
      name: "Otro",
    },
  ];

  const customAreaPlaceholder = findValueMatch(regions, area.customer_area_id);

  return (
    <>
      <div class="form-row">
        <div class="col-md-6 col-12 mb-3">
          <Input
            label={
              <>
                Provincia <span style={{ color: "red" }}>*</span>
              </>
            }
            type="text"
            name="area[area_name]"
            id="area_area_name"
            placeholder={area.area_name}
            value={formik.values.area.area_name}
            required="required"
            onChange={(e) => formik.handleChange(e)}
            error={formik.errors.area?.area_name}
            touched={formik.touched.area?.area_name}
          />
        </div>
        <div class="col-md-6 col-12 mb-3">
          <Input
            label={
              <>
                Código <span style={{ color: "red" }}>*</span>
              </>
            }
            type="text"
            name="area[area_code]"
            id="area_area_code"
            placeholder={area.area_code}
            value={formik.values.area.area_code}
            required="required"
            onChange={(e) => formik.handleChange(e)}
            error={formik.errors.area?.area_code}
            touched={formik.touched.area?.area_code}
          />
        </div>

        <div class="col-md-6 col-12 mb-3">
          <Input
            label={
              <>
                Palabra clave <span style={{ color: "red" }}>*</span>
              </>
            }
            type="text"
            name="area[search_keyword_1]"
            id="area_search_keyword_1"
            placeholder={area.search_keyword_1}
            value={formik.values.area.search_keyword_1}
            required="required"
            onChange={(e) => formik.handleChange(e)}
            error={formik.errors.area?.search_keyword_1}
            touched={formik.touched.area?.search_keyword_1}
          />
        </div>
        <div class="col-md-6 col-12 mb-3">
          <Input
            label={
              <>
                Comunidad Autónoma <span style={{ color: "red" }}>*</span>
              </>
            }
            type="text"
            name="area[state]"
            id="area_state"
            placeholder={area.state}
            value={formik.values.area.state}
            required="required"
            onChange={(e) => formik.handleChange(e)}
            error={formik.errors.area?.state}
            touched={formik.touched.area?.state}
          />
        </div>
        <div class="col-md-6 col-12">
          <Select
            multiSelect={false}
            searchBar={false}
            name={
              <>
                Tipo de área
                <span style={{ color: "red" }}> *</span>
              </>
            }
            data={areaTypes}
            dataSelected={area.area_type}
            selectId="area_area_type"
            selectName="area[area_type]"
            placeholder={capitalizeFirstLetter(area.area_type)}
            className={styles.titleSelect}
          />
        </div>
        <div class="col-md-6 col-12">
          <Select
            multiSelect={false}
            searchBar={false}
            name={
              <>
                Seleccionar Región Cliente
                <span style={{ color: "red" }}> *</span>
              </>
            }
            data={regions}
            dataSelected={area.customer_area_id}
            selectId="area_customer_area_id"
            selectName="area[customer_area_id]"
            placeholder={customAreaPlaceholder}
            className={styles.titleSelect}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
