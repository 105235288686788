import { HiDuplicate } from "react-icons/hi";
import React from "react";

import useCustomModal from "../../../../hooks/useCustomModal";
import { BiSolidCommentDetail } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { LuImport } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import {
  ToolBarStyled,
  ActionButton,
  Actions,
} from "../../../../styles/rate_plans";
import { FaEuroSign } from "react-icons/fa";
import { MdChangeCircle } from "react-icons/md";
import axios from "axios";
import ModalHistory from "./ModalHistory";
import ModalExportSavings from "./ModalExportSavings";

export const ToolBar = ({
  markedRecords,
  unMarkAllRecords,
  fetchData,
  status,
}) => {
  const historyModal = useCustomModal();
  const exportSavingsModal = useCustomModal();
  const desactivar = async () => {
    try {
      await axios.get("/admin/rate_plans/change_multiple_status", {
        params: { ids: markedRecords },
      });
      toast.success(<div>Planes cambiados con éxito</div>);
      fetchData();
      unMarkAllRecords();
    } catch (error) {
      toast.error(
        <div>No se pudo realizar la acción sobre los planes seleccionados</div>
      );
    }
  };

  const eliminar = async () => {
    try {
      await axios.get("/admin/rate_plans/destroy_multiple", {
        params: { ids: markedRecords },
      });
      toast.success(<div>Planes eliminados con éxito</div>);
      fetchData();
      unMarkAllRecords();
    } catch (error) {
      toast.error(
        <div>No se pudo realizar la acción sobre los planes seleccionados</div>
      );
    }
  };
  const exportPlans = () => {
    const idsComoCadena = markedRecords.join(",");
    window.location.href = `/admin/rate_plans/export_selected_rate_plans.xlsx?rate_plan_ids=${idsComoCadena}`;
    unMarkAllRecords();
  };

  const duplicatePlant = () => {
    window.location.href = `/admin/rate_plans/${markedRecords[0]}/duplicate`;
    unMarkAllRecords();
  };

  return (
    <div>
      {historyModal.showModal ? (
        <ModalHistory
          closeModal={historyModal.closeModal}
          ratePlanId={markedRecords[0]}
          unMarkAllRecords={unMarkAllRecords}
        />
      ) : null}
      {exportSavingsModal.showModal ? (
        <ModalExportSavings
          closeModal={exportSavingsModal.closeModal}
          markedRecords={markedRecords}
          unMarkAllRecords={unMarkAllRecords}
        />
      ) : null}
      <Toaster />
      {markedRecords.length > 0 ? (
        <ToolBarStyled>
          {markedRecords.length > 1 ? (
            <div id="selected-plan">
              <IoCloseSharp
                size="18px"
                color="red"
                cursor="pointer"
                onClick={() => unMarkAllRecords()}
              />
              <strong>{markedRecords.length}</strong>&nbsp;planes seleccionados
            </div>
          ) : (
            <div id="selected-plan">
              <IoCloseSharp
                size="30px"
                cursor="pointer"
                color="red"
                onClick={() => unMarkAllRecords()}
              />
              <div
                style={{
                  display: "inline-block",
                  minWidth: "150px",
                }}
              >
                <strong>1</strong> plan seleccionado
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <ActionButton onClick={() => historyModal.openModal()}>
                  <BiSolidCommentDetail
                    style={{ marginTop: "2px" }}
                    size={18}
                  />
                  Ver historial
                </ActionButton>
                <ActionButton onClick={() => duplicatePlant()}>
                  <HiDuplicate size={18} />
                  Duplicar plan
                </ActionButton>
              </div>
            </div>
          )}
          <Actions>
            <ActionButton onClick={() => exportPlans()}>
              <LuImport size={20} />
              Exportar planes
            </ActionButton>
            <ActionButton onClick={() => exportSavingsModal.openModal()}>
              <FaEuroSign size={18} />
              Exportar ahorros
            </ActionButton>
            <ActionButton onClick={() => desactivar()}>
              <MdChangeCircle size={20} />
              {status === "Inactive" ? `Activar planes` : "Desactivar planes"}
            </ActionButton>
            <ActionButton onClick={() => eliminar()}>
              <MdDelete size={20} />
              Eliminar planes
            </ActionButton>
          </Actions>
        </ToolBarStyled>
      ) : null}
    </div>
  );
};
