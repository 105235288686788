import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiPlusCircle } from "react-icons/fi";
import {
  TextField,
  SearchButton,
  SearchForm,
} from "../../../../styles/rate_plans";

export const Searcher = ({ filters, setFilters }) => {
  const handleSubmit = (text) => {
    setFilters({
      ...filters,
      textField: text,
    });
  };
  return (
    <SearchForm
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e.target.searcher.value);
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          width: "98%",
          borderBottom: "2px solid #21103D",
          display: "flex",
        }}
      >
        <TextField
          type="text"
          placeholder="Buscar por nombre, identificador o telefono"
          name="searcher"
        />
        <SearchButton type="submit">
          <AiOutlineSearch size={25} color={"#21103D"} />
        </SearchButton>
      </div>
    </SearchForm>
  );
};
