import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  color: #21103d;
  height: 85%;

`;

export const Title = styled.h2`
  color: #21103d;
  margin-left: 3%;
`;

export const Th = styled.th`
  color: #f5f5f5;
  background-color: #21103d;
  text-align: center;
  padding: 10px;
`;

export const Td = styled.td`
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #21103d;
`;

export const Tr = styled.tr`
  cursor: pointer;
  height: 50px;
  background-color: ${(props) =>
    props.isMarked ? "RGBA(0, 219, 255, 0.4)" : "#f5f5f5"};
  :hover {
    background-color: RGBA(0, 219, 255, 0.6);
  }
`;
export const CustomTable = styled.table`
  transition: all 0.4s ease-in-out;
  width: 100%;
`;

export const TableContainer = styled.div`
  height: 65vh;
  overflow-x: scroll;
  overflow-y: scroll;
  width: 96%;
  padding-right: 10px;
  margin-left: 2%;
  position: absolute;
  bottom: 10px;
  top: 200px;
  transition: all 0.4s ease-in-out;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #21103d;
    border-radius: 8px;
  }

  @media (max-width: 1400px) {
    top: 300px;
    bottom: 0px;
  }
  @media (max-width: 500px) {
    top: ${(props) => (props.showToolBar ? "400px" : "270px")};
    height: 75vh;
  }
`;
export const ToolBarStyled = styled.div`
  width: 95%;
  min-height: 45px;
  margin-left: 2%;
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #00dbff;
  border-radius: 10px;
  height: auto;
  background-color: RGBA(0, 219, 255, 0.3);
  padding: 5px 0px;
  #selected-plan {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1400px) {
    margin-top: 80px;
    flex-direction: column;
  }
`;

export const TextField = styled.input`
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: start;
  :focus {
    outline: none;
  }
`;
export const SearchButton = styled.button`
  border: none;
  padding: 5px 10px;
  background-color: transparent;

  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const ActionButton = styled.div`
  border: none;
  color: #21103d;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  font-size: 14px;
  display: flex;
  padding: 2px 4px;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const SearchForm = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 3%;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const Actions = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1400px) {
    margin-top: 10px;
  }
`;

export const ExportsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 65px;
  margin-right: 8%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1400px) {
    top: 120px;
    margin-right: 30px;
  }
  @media (max-width: 500px) {
    top: 180px;
    margin-right: 5px;
    width: 100%;
  }
`;

export const ExportButton = styled.a`
  display: inline-block;
  border-radius: 8px;
  background-color: #efefef;
  color: #21103d;
  padding: 5px 15px;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    color: #21103d;
    text-decoration: none;
  }
`;

export const TypesContainer = styled.div`
  position: absolute;
  background-color: #efefef;
  left: 6px;
  top: 30px;
  width: 120px;
  border-radius: 8px;
  z-index: 3;
  border: 2px solid #21103d;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  div {
    cursor: pointer;
    padding: 5px 10px;

    :hover {
      background-color: RGBA(0, 219, 255, 0.6);
    }
  }
`;

export const Status = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 25px;
    margin-left: 0px;
  }
`;

const appearAnimation = keyframes`
  0% {
    transform: translateX(300px); 
  }
  100% {
    transform: translateX(0);
  }
`;

const disappearAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
`;

export const SideBarContainer = styled.div`
  color: #21103d;
  width: 300px;
  position: fixed;

  background-color: #ffffff;
  border-left: 2px solid #21103d;
  bottom: 0;
  top: 0;

  z-index: 9999999;
  right: 0;
  animation: ${(props) =>
      props.isVisible ? appearAnimation : disappearAnimation}
    0.4s ease-out forwards;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 5px;
`;

export const SideBarHeader = styled.div`
  width: 100%;
  display: flex;
`;

export const TableButton = styled.button`
  border: 1px solid #21103d;
  border-radius: 8px;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
