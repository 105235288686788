import { string } from "prop-types";
import styles from "./select.module.css";
import { useEffect, useState } from "react";

const useCustomSelect = (
  data,
  dataSelected,
  selectId,
  multiSelect,
  reactSetter,
  reactSetAll
) => {

  const convertDataToObject = () =>{
  if(typeof dataSelected !== "object"){
    return [dataSelected]
  } else {
    return dataSelected
  }
}  

  const [selectedOptions, setSelectedOptions] = useState(data);
  const [showListDiv, setShowListDiv] = useState(false);
  const [selected, setSelected] = useState(new Set(convertDataToObject()));
  const [inputValue, setInputValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItemString, setSelectedItemString] = useState("");

  useEffect(() => {
    setSelectedOptions(data);
    setSelected(new Set(convertDataToObject()));
    const hiddenSelect = document.getElementById(selectId);
    if (hiddenSelect) {
      hiddenSelect.style.display = "none";
    }
  }, [data, dataSelected, selectId]);

  const toggleDiv = () => {
    setShowListDiv(!showListDiv);
    setInputValue("");
  };

  const updateHiddenSelect = (selected) => {
    const hiddenSelect = document.getElementById(selectId);

    if (hiddenSelect) {
      const existingOptions = hiddenSelect.querySelectorAll("option");

      existingOptions.forEach((option) => {
        option.removeAttribute("selected");
        option.selected = false;
      });

      if (selected.size > 0) {
        selected.forEach((value) => {
          let option;
          if (value) {
            option = hiddenSelect.querySelector(`option[value="${value}"]`);
          } else {
            option =
              hiddenSelect.querySelector(`option:not([value])`) ||
              hiddenSelect.querySelector(`option[value=""]`);
          }
          option.selected = true;
          option?.setAttribute("selected", "selected");
        });
      }
      hiddenSelect.style.display = "none";
    }
  };

  const toggleSelected = (item) => {

    setSelected((prevSelected) => {
      const newSelected = new Set(prevSelected);
      console.log(newSelected, "initial select")
      if (newSelected.has(item)) {
        newSelected.delete(item);
        console.log(newSelected, "deletes?")
      } else {
        if (multiSelect) {
          newSelected.add(item);
        } else {
          newSelected.clear();
          newSelected.add(item)
        }
      }
      if (selectAll) {
        setSelectAll(false);
      }
      if (reactSetter) {
        setTimeout(() => {
          reactSetter(newSelected, item);
        }, 1);
      }
      updateHiddenSelect(newSelected);
      console.log(newSelected)
      return newSelected;
    });
  };

  const toggleSelectAll = () => {
    const itemsInFilter = selectedOptions.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    const allSelectedInFilter = itemsInFilter.every((item) =>
      selected.has(item.id)
    );

    if (allSelectedInFilter) {
      let itemsToUnselect = itemsInFilter.map((option) => option.id);

      let updatedSelected = new Set(
        [...selected].filter((item) => !itemsToUnselect.includes(item))
      );

      setSelected(updatedSelected);
      updateHiddenSelect(updatedSelected);
      setSelectAll(false);
    } else {
      const updateSelected = new Set(selected);

      let itemsToSelect = selectedOptions
        .filter((option) =>
          option.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((option) => option.id);

      for (const item of itemsToSelect) {
        updateSelected.add(item);
      }
      reactSetAll ? reactSetAll(updateSelected) : null;
      setSelected(updateSelected);
      updateHiddenSelect(updateSelected);
      setSelectAll(true);
    }
  };


  const matchedSelected = (item) => {
      return selected.has(item) ? styles.selectedItemColour : "";
  };

  return {
    selectedOptions,
    showListDiv,
    selected,
    inputValue,
    selectAll,
    selectedItemString,
    toggleDiv,
    toggleSelected,
    toggleSelectAll,
    matchedSelected,
    setSelectedItemString,
    setInputValue,
  };
};

export default useCustomSelect;
