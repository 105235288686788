import React, { useState } from "react";
import styles from "./formStyles.module.css";

import { AiOutlineInfoCircle } from "react-icons/ai";

//adapter
import { findValueMatch } from "../utils/utils";

//components
import Select from "../../../reactComponents/select/Select";
import Checkbox from "../../../reactComponents/checkbox/CheckBox";
import Modal from "../../../reactComponents/modal/Modal";

const configComuComerciales = ({ organization }) => {
  //modal
  const [showConfComuComercialesModal, setShowComuComercialesModal] =
    useState(false);
    
  const confcomuComercialesData = [
    {
      header: "¿Habilitar 'Envío de Comparativas' via email?",
      text: "Permite el envío de comparativas al cliente vía email. Para ello \
      tendrán que estar configurados los distintos templates para 2.0TD, 3.0TD o \
      Gas.",
    },
    {
      header: "¿Habilitar 'Envío de Comparativas' via whatsapp?",
      text: "Permite el envío de comparativas al cliente vía whatsapp. Para ello \
      tendrá que estar previamente configurada la cuenta de whatsapp del cliente \
      para poder usar correctamente esta funcinolidad.",
    },
    {
      header: "¿Habilitar 'Informar Precios' en comparativa?",
      text: "Permite el envío de precios (sin comparativa ni ahorro) al cliente \
      vía email. Para ello tendrán que estar configurados los distintos templates \
      para 2.0TD, 3.0TD o Gas.",
    },
    {
      header: "¿Habilitar Informar Precios en Pantalla de inicio?",
      text: "Permite informar vía email desde la pantalla de inicio (Home/Landing) \
      de la aplicación de eSave. Para ello tendrán que estar configurados los distintos \
      templates para 2.0TD, 3.0TD o Gas.",
    },
    {
      header: "¿Calcular precios indexados para informar precios?",
      text: "Habilita que se puedan informar precios de Tarifas Indexadas vía email. En \
      caso de activarse para las tarifas indexadas, se calculará la media de los precios \
      diarios de Energía de los últimos 6 meses que estén cargados en la herramienta.",
    },
    {
      header: "¿Habilitar 'Informe Agregado de Procesamientos'",
      text: "Permite la generación de un informe (PDF) de ahorro agregando distintos \
      procesamientos que se podrá seleccionar desde el Registro de procesamientos de \
      la aplicación de eSave.",
    },
    {
      header: "¿Habilitar 'Generar Informe (PDF)' en Pantalla de Comparativa?",
      text: "Habilita la opción para que los usuarios puedan generar un informe (PDF) \
      de ahorro de la comparativa seleccionada. Esta opción estará disponibles desde la \
      pantalla de comparativas de cada procesamiento.",
    },
    {
      header: "¿Habilitar 'Generar Informe (PDF)' en Pantalla de Simulación?",
      text: "Habilita la opción para que los usuarios puedan generar un informe (PDF) \
      de ahorro de la simulación seleccionada. Esta opción estará disponibles desde la \
      pantalla de visaulización de resultados de la simulación.",
    },
    {
      header: "¿Enviar Email con correo personal?",
      text: "Si se habilita esta opción, entonces las comunicaciones comerciales que se \
      realicen vía email se enviarán desde el correo del usuario que está realizando \
      la acción. Para ello el dominio del correo tiene que haber sido previamente dado de \
      alta y validado en la infraestructura de AWS de eSave.",
    },
    {
      header: "¿Poner en CC el correo personal en envío de Comparativa?",
      text: "Si se habilita esta opción, entonces se pondrá en copia (cc) al usuario \
      en todas las comunicaciones comerciales que se realicen vía email desde la app \
      de eSave. Para un correcto funcionamiento, todos los usuarios de la plataforma \
      tendrían que disponer de un correo válido y operativo.",
    },
    {
      header: "¿Permitir envío de comparativas con ajustes?",
      text: "Si se habilita esta opción, se permitirá enviar comparativas cuando se \
      hayan realizado ajustes de potencia y/o consumo.",
    },
    
  ];
  //checkboxes
  const [comuniComercial, setComuniComercial] = useState(false);
  const [sendSavEmail, setSendSavEmail] = useState(
    organization.send_savings_email
  );
  
  const [sendSavWhatsApp, setSendSavWhatsApp] = useState(
    organization.send_savings_whatsapp
  );

  const [sendContractActive, setSendContractActive] = useState(
    organization.is_send_contract_active
  );

  const [contractInfPricActive, setContractInfPriActive] = useState(
    organization.is_inform_prices_active
  );
  const [calcIndexedPricForInfPrices, setCalcIndexedPricForInfPrices] =
    useState(organization.calculate_indexed_prices_for_inform_prices);

  const [generateAgregatedRepo, setGenerateAgregateRepo] = useState(
    organization.generate_aggregated_report
  );
  const [exportSavingsPdf, setExportSavingsPdf] = useState(
    organization.export_savings_pdf
  );
  const [exportSimulationsPdf, setExportSimulationsPdf] = useState(
    organization.export_simulations_pdf
  );
  const [sendWithPersonalEmail, setSendWithPersonalEmail] = useState(
    organization.send_with_personal_email
  );
  const [ccUserOnSavingsEmail, setCcUserOnSavingsEmail] = useState(
    organization.cc_user_on_savings_email
  );
  const [allowSendAdjustmentSaving, setAllowSendAdjustmentSaving] = useState(
    organization.allow_send_adjustment_saving || false
  );


  const configComComerciales = [
    {
      onChange: () => setSendSavEmail(!sendSavEmail),
      checked: sendSavEmail,
      name: "organization[send_savings_email]",
      id: "organization_send_savings_email",
      label: `¿Habilitar "Envío de Comparativas" via email?`,
    },
    {
      onChange: () => setSendSavWhatsApp(!sendSavWhatsApp),
      checked: sendSavWhatsApp,
      name: "organization[send_savings_whatsapp]",
      id: "organization_send_savings_whatsapp",
      label: `¿Habilitar "Envío de Comparativas" via whatsapp?`,
    },
    {
      onChange: () => setSendContractActive(!sendContractActive),
      checked: sendContractActive,
      name: "organization[is_send_contract_active]",
      id: "organization_is_send_contract_active",
      label: `¿Habilitar "Informar Precios" en comparativa? `,
    },
    {
      onChange: () => setContractInfPriActive(!contractInfPricActive),
      checked: contractInfPricActive,
      name: "organization[is_inform_prices_active]",
      id: "organization_is_inform_prices_active",
      label: `¿Habilitar "Informar Precios" en Pantalla de inicio?`,
    },
    {
      onChange: () =>
        setCalcIndexedPricForInfPrices(!calcIndexedPricForInfPrices),
      checked: calcIndexedPricForInfPrices,
      name: "organization[calculate_indexed_prices_for_inform_prices]",
      id: "organization_calculate_indexed_prices_for_inform_prices",
      label: `¿Calcular precios indexados para informar precios?`,
    },
    {
      onChange: () => setGenerateAgregateRepo(!generateAgregatedRepo),

      checked: generateAgregatedRepo,
      name: "organization[generate_aggregated_report]",
      id: "organization_generate_aggregated_report",
      label: `¿Habilitar "Informame Agregado de Procesamientos"?`,
    },
    {
      onChange: () => setExportSavingsPdf(!exportSavingsPdf),
      checked: exportSavingsPdf,
      name: "organization[export_savings_pdf]",
      id: "organization_export_savings_pdf",
      label: `¿Habilitar "Exportar PDF" en Pantalla de Comparativa?`,
    },
    {
      onChange: () => setExportSimulationsPdf(!exportSimulationsPdf),
      checked: exportSimulationsPdf,
      name: "organization[export_simulations_pdf]",
      id: "organization_export_simulations_pdf",
      label: `¿Habilitar "Exportar PDF" en Pantalla de Simulación?`,
    },
    {
      onChange: () => setSendWithPersonalEmail(!sendWithPersonalEmail),
      checked: sendWithPersonalEmail,
      name: "organization[send_with_personal_email]",
      id: "organization_send_with_personal_email",
      label: `¿Enviar Email con correo personal?`,
    },
    {
      onChange: () => setCcUserOnSavingsEmail(!ccUserOnSavingsEmail),
      checked: ccUserOnSavingsEmail,
      name: "organization[cc_user_on_savings_email]",
      id: "organization_cc_user_on_savings_email",
      label: `¿Poner en CC el correo personal en envío de Comparativa?`,
    },
    {
      onChange: () => setAllowSendAdjustmentSaving(!allowSendAdjustmentSaving),
      checked: allowSendAdjustmentSaving,
      name: "organization[allow_send_adjustment_saving]",
      id: "organization_allow_send_adjustment_saving",
      label: `¿Permitir envío de comparativas con ajustes?`,
    },
  ];

  const invoiceReqNotificationTypes = [
    {
      id: "email",
      name: "Notificación por Email",
    },
    {
      id: "sms",
      name: "Notificación por SMS",
    },
    {
      id: "sms_whatsapp_link",
      name: "Notificación por SMS + WhatsApp",
    },
  ];

  const negativeSavingsOptions = [
    {
      id: "allow",
      name: "Permitir",
    },
    {
      id: "warn",
      name: "Avisar Previamente",
    },
    {
      id: "not_allow",
      name: "No Permitir",
    },
  ];

  const negativeSavingsPlaceholder = findValueMatch(
    negativeSavingsOptions,
    organization.negative_savings_options
  );

  const defOfferedSavings = [
    {
      id: "highest_savings",
      name: "Mayor ahorro",
    },
    { 
      id: "smallest_savings", 
      name: "Menor ahorro" 
    },
    {
      id: "smallest_savings_over_annual_min_savings",
      name: "Menor ahorro sobre el ahorro mínimo anual",
    },
  ];
  const placeholderaOffSavings = findValueMatch(
    defOfferedSavings,
    organization.default_offered_savings_code
  );

  return (
    <>
      {showConfComuComercialesModal && (
        <Modal
          data={confcomuComercialesData}
          onClose={() => setShowComuComercialesModal(false)}
        />
      )}
      <h3 className={styles.title}>
        Configuración Comunicaciones Comerciales{" "}
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowComuComercialesModal(true)}
        />
      </h3>
      <div className={styles.scrollDiv}>
        <div className={styles.checkBoxContent}>
          {configComComerciales.map((item) => {
            return (
              <Checkbox
                key={item.id}
                onChange={item.onChange}
                checked={item.checked}
                name={item.name}
                id={item.id}
                label={item.label}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.selectsCntainer}>
        <Select
          multiSelect={true}
          searchBar={true}
          name="Tipos de Notificación (Solicitud Facturas)"
          data={invoiceReqNotificationTypes}
          placeholder={organization.invoice_request_notification_types}
          dataSelected={organization.invoice_request_notification_types}
          selectId="organization_invoice_request_notification_types_"
          selectName="organization[invoice_request_notification_types][]"
        />
        <Select
          multiSelect={false}
          searchBar={true}
          name="Comunicaciones Comparativas Ahorro Negativo"
          data={negativeSavingsOptions}
          placeholder={negativeSavingsPlaceholder}
          dataSelected={organization.negative_savings_options}
          selectId="organization_negative_savings_options"
          selectName="organization[negative_savings_options]"
        />
        <Select
          multiSelect={false}
          searchBar={false}
          name="Código de ahorro ofrecido por defecto"
          data={defOfferedSavings}
          dataSelected={organization.default_offered_savings_code}
          selectId="organization_default_offered_savings_code"
          selectName="organization[default_offered_savings_code]"
          placeholder={placeholderaOffSavings}
        />
      </div>
    </>
  );
};

export default configComuComerciales;
