import React from "react";
import styles from "./index.module.css";

const Pagination = ({ currentPage, totalPages, onChangePage }) => {
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  return (
    <div className={`${styles.pagination}`}>
      <span
        className={styles.arrow}
        onClick={currentPage > 1 ? () => onChangePage(currentPage - 1) : null}
      >
        <a href="#">&lt;</a>
      </span>
      {generatePageNumbers().map((pageNum) => (
        <span
          onClick={() => onChangePage(pageNum)}
          key={pageNum}
          className={
            pageNum === currentPage ? styles.currentPage : styles.pageNum
          }
        >
          <a href="#" onClick={() => onChangePage(pageNum)}>
            {pageNum}
          </a>
        </span>
      ))}
      <span
        className={styles.arrow}
        onClick={
          currentPage < totalPages ? () => onChangePage(currentPage + 1) : null
        }
      >
        <a href="#">&gt;</a>
      </span>
    </div>
  );
};

export default Pagination;
