import React, { useState } from "react";
import styles from "./formStyles.module.css";
import { useFormik } from 'formik';
import {validationSchema} from "../validations/validationSchema"

import { AiOutlineInfoCircle } from "react-icons/ai";

//components
import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";
import Checkbox from "../../../reactComponents/checkbox/CheckBox";
import Modal from "../../../reactComponents/modal/Modal";

const recDeImportesOrigen = ({ organization }) => {

  const formik = useFormik({
    initialValues: {
      organization: {
        default_p1_supplied: organization.default_p1_supplied,
        default_p2_supplied: organization.default_p2_supplied,
        default_invoiced_days: organization.default_invoiced_days,
        show_e_inv_not_older_than: organization.show_e_inv_not_older_than,
      },
    },
    validationSchema: validationSchema,
  });
  // modal
  const [showRecImpOrigenModal, setShowrecImpOrigenModal] = useState(false);
  const recImportOrigenData = [
    {
      header: "¿Habilitar recálculo de importe en orígen?",
      text: "Si se activa entonces se habilitará la lógica para recalcular el importe \
      del procesamiento realizado en caso de que la factura disponga de alguna condición \
      regulatoria (IVA, peajes, otros conceptos regulados) distinta a la que esté vigente \
      en el momento de realización del procesamiento.",
    },
    {
      header: "¿Recalcular peaje en precios de Potencia y Energía?",
      text: "Se recalculará el importe total de la factura teniendo en cuenta el posible \
      cambio en los procios de peajes de Energía o Potencia que pueda haber entre lo que \
      refleja la factura y la situación actual del mercado energético.",
    },
    {
      header: "¿Descontar SVA del Total (en orígen)?",
      text: "Si se activa entonces se suprimirá el coste del Servicio en las facturas que \
      lleven incluído cualquier concepto categorizado como tal (Servicio de Valor Añadido). \
      Esto tendrá un impacto de reducción del importe total de la factura.",
    },
    {
      header: "¿Permitir recalcular importe total manualmente?",
      text: "Habilita una funcionalidad que permite al usuario recalcular el importe total \
      de la factura en base a los cambios realizados en otros conceptos de la misma.",
    },
    {
      header: "¿Recalcular IVA en factura orígen?",
      text: "Si se activa entonces se habilita el recálculo de la factura para los casos \
      donde el IVA de la misma sea distinto al IVA actual vigente en el mercado para esa \
      tipología de facturas.",
    },
    {
      header: "¿Incluir anualización de Tope de Gas en Ahorro Anual?",
      text: "Activa la lógica para tener en cuenta las variaciones del coste 'Tope de \
      Gas' en el cálculo del ahorro anual obtenido en la herramienta.",
    },
  ];

  const [recalculateElectricImport, setRecalculateElectricImport] = useState(
    organization.recalculate_electric_import
  );
  const [recalculateTdEnergyPowerCosts, setRecalculateTdEnergyPowerCosts] =
    useState(organization.recalculate_td_energy_power_costs);

  const [removeSvaOnOrigin, setRemoveSvaOnOrigin] = useState(
    organization.remove_sva_on_origin
  );

  const [recalculateTotalCost, setRecalculateTotalCost] = useState(
    organization.recalculate_total_cost
  );

  const [recalculateIvaGas, setRecalculateIvaGas] = useState(
    organization.recalculate_iva_gas
  );

  const [annualTopeGasRecalculation, setAnnualTopeGasRecalculation] = useState(
    organization.annual_tope_gas_recalculation
  );

  const recImpDeOrigen = [
    {
      onChange: () => setRecalculateElectricImport(!recalculateElectricImport),
      checked: recalculateElectricImport,
      name: "organization[recalculate_electric_import]",
      id: "organization_recalculate_electric_import",
      label: `¿Habilitar recálculo de importe en orígen?`,
    },
    {
      onChange: () =>
        setRecalculateTdEnergyPowerCosts(!recalculateTdEnergyPowerCosts),
      checked: recalculateTdEnergyPowerCosts,
      name: "organization[recalculate_td_energy_power_costs]",
      id: "organization_recalculate_td_energy_power_costs",
      label: `¿Recalcular peaje en precios de Potencia y Energía?`,
    },
    {
      onChange: () => setRemoveSvaOnOrigin(!removeSvaOnOrigin),
      checked: removeSvaOnOrigin,
      name: "organization[remove_sva_on_origin]",
      id: "organization_remove_sva_on_origin",
      label: `¿Descontar SVA del Total (en orígen)?`,
    },
    {
      onChange: () => setRecalculateTotalCost(!recalculateTotalCost),
      checked: recalculateTotalCost,
      name: "organization[recalculate_total_cost]",
      id: "organization_recalculate_total_cost",
      label: `¿Permitir recalcular importe total manualmente?`,
    },
    {
      onChange: () => setRecalculateIvaGas(!recalculateIvaGas),
      checked: recalculateIvaGas,
      name: "organization[recalculate_iva_gas]",
      id: "organization_recalculate_iva_gas",
      label: `¿Recalcular IVA en factura orígen?`,
    },
    {
      onChange: () =>
        setAnnualTopeGasRecalculation(!annualTopeGasRecalculation),
      checked: annualTopeGasRecalculation,
      name: "organization[annual_tope_gas_recalculation]",
      id: "organization_annual_tope_gas_recalculation",
      label: `¿Incluir anualización de Tope de Gas en Ahorro Anual?`,
    },
  ];

  {
    /* --------------------   Configuracion API */
  }

  const apiVersion2x = [
    {
      id: "v3",
      name: "v3",
    },
    {
      id: "v2",
      name: "v2",
    },
  ];

  const apiVersionTd = [
    {
      id: "v3",
      name: "v3",
    },
  ];

  const shortLinkType = [
    {
      id: "cuttly",
      name: "Cuttly",
    },
    { id: "bitly", name: "Bitly" },
  ];

  const extractAddressType = [
    {
      id: "none",
      name: "None",
    },
    { id: "google", name: "Google" },
  ];

  return (
    <>
      {showRecImpOrigenModal && (
        <Modal
          data={recImportOrigenData}
          onClose={() => setShowrecImpOrigenModal(false)}
        />
      )}
      <h3 className={styles.title}>
        Recálculos de Importes en Orígen
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowrecImpOrigenModal(true)}
        />
      </h3>
      <div className={styles.scrollDiv}>
      <div className={styles.checkBoxContent}>
        {recImpDeOrigen.map((item) => {
          return (
            <Checkbox
              key={item.id}
              onChange={item.onChange}
              checked={item.checked}
              name={item.name}
              id={item.id}
              label={item.label}
            />
          );
        })}
      </div>
      </div>
      <h3 className={styles.title}>Valores defecto Luz 2x</h3>
      <div className={styles.defectoLuzContainer}>
      <div>
          <h6 className={styles.headerColorBlock}>Potencia P1</h6>
          <Input
            type="text"
            name="organization[default_p1_supplied]"
            id="organization_default_p1_supplied"
            placeholder={organization.default_p1_supplied}
            value={formik.values.organization.default_p1_supplied ?? ""}
            onChange={(e) => formik.handleChange(e) }
            error={formik.errors.organization?.default_p1_supplied}  
            touched={formik.touched.organization?.default_p1_supplied}
          />
        </div>
        <div>
          <h6 className={styles.headerColorBlock}>Potencia P2</h6>
          <Input
            type="text"
            name="organization[default_p2_supplied]"
            id="organization_default_p2_supplied"
            placeholder={organization.default_p2_supplied}
            value={formik.values.organization.default_p2_supplied ?? ""}
            onChange={(e) => formik.handleChange(e) }
            error={formik.errors.organization?.default_p2_supplied}  
            touched={formik.touched.organization?.default_p2_supplied}
          />
        </div>
        <div>
          <h6 className={styles.headerColorBlock}>Nº días</h6>
          <Input
            type="text"
            name="organization[default_invoiced_days]"
            id="organization_default_invoiced_days"
            placeholder={organization.default_invoiced_days}
            value={formik.values.organization.default_invoiced_days ?? ""}
            onChange={(e) => formik.handleChange(e)}
            error={formik.errors.organization?.default_invoiced_days} 
            touched={formik.touched.organization?.default_invoiced_days}
          />
        </div>
      </div>
      <h3 className={styles.title}>Configuración API</h3>
      <div className={styles.configApiContainer}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Version API Tarifas 2X:"
          data={apiVersion2x}
          placeholder={organization.api_version_2x}
          dataSelected={organization.api_version_2x}
          selectId="organization_api_version_2x"
          selectName="organization[api_version_2x]"
 
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Version API Tarifas TD:"
          data={apiVersionTd}
          placeholder={organization.api_version_td}
          dataSelected={organization.api_version_td}
          selectId="organization_api_version_td"
          selectName="organization[api_version_td]"

        />
      </div>
      <div className={styles.configApiContainer}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Tipo de Short URL:"
          data={shortLinkType}
          dataSelected={organization.short_link_type}
          placeholder={organization.short_link_type}
          selectId="organization_short_link_type"
          selectName="organization[short_link_type]"
      
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Tipo de Extraer Dirección Completa:"
          data={extractAddressType}
          dataSelected={organization.extract_address_type}
          placeholder={organization.extract_address_type}
          selectId="organization_extract_address_type"
          selectName="organization[extract_address_type]"
 
        />
      </div>
      <>
        <Input
          type="text"
          label="Máxima antigüedad de facturas en consultas"
          name="organization[show_e_inv_not_older_than]"
          id="organization_show_e_inv_not_older_than"
          value={formik.values.organization.show_e_inv_not_older_than ?? ""}
          onChange={formik.handleChange}
          error={formik.errors.organization?.show_e_inv_not_older_than}
          touched={formik.touched.organization?.show_e_inv_not_older_than}
        />
      </>

      {/* to be revised */}
      {/* <div className={styles.reactContainer}>
        <Checkbox
          // onChange={item.onChange}
          // checked={item.checked}
          // name={item.name}
          // id={item.id}
          label="React"
        />
      </div> */}
    </>
  );
};

export default recDeImportesOrigen;
