// RatePlanTable.js
import React, { useState } from "react";
import styles from "./index.module.css";
import {
  MdOutlineRemoveRedEye,
  MdDelete,
  MdSimCardDownload,
  MdOutlineEuroSymbol,
} from "react-icons/md";
import { FaBoltLightning } from "react-icons/fa6";
import { GiWaterDrop } from "react-icons/gi";
import { RiWaterFlashFill } from "react-icons/ri";

import { formatDate } from "../utils/utils";

const Table = ({
  records,
  selectedRows,
  handleRowSelect,
  deleteEnergyInvoice,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDeleteEnergyInvoice = async (invoiceId) => {
    try {
      setLoading(true);
      await deleteEnergyInvoice(invoiceId);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log(records);
  return (
    <table className={styles.table}>
      {loading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        ""
      )}
      <thead className={styles.tHead}>
        <tr>
          <th>ID</th>
          <th>Modelo</th>
          <th>Tarifa</th>
          <th>Tipo</th>
          <th>Cups</th>
          <th>Comercial</th>
          <th>Organización</th>
          <th>Campaña</th>
          <th>Estado</th>
          <th>Fecha</th>
          <th>Cliente</th>
          <th>¿Ahorro enviado?</th>
          <th className={styles.actionsSpace}>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {records.map((invoice, index) => (
          <tr
            key={index}
            className={`${styles.tableRow} ${
              selectedRows.includes(invoice.invoice.id)
                ? styles.selectedRow
                : ""
            }`}
            onClick={(e) => handleRowSelect(invoice.invoice.id, e)}
          >
            <td>{invoice.invoice.id}</td>
            <td>{invoice.invoice.business_unit}</td>
            <td>
              {invoice.invoice.invoice_type === "electric" ? (
                <>
                  <FaBoltLightning className={styles.lightIcon} />{" "}
                  <span>Luz</span>
                </>
              ) : invoice.invoice.invoice_type === "gas" ? (
                <>
                  <GiWaterDrop className={styles.gasIcon} /> <span>Gas</span>
                </>
              ) : invoice.invoice.invoice_type === "dual" ? (
                <>
                  <RiWaterFlashFill className={styles.dualIcon} />{" "}
                  <span>Dual</span>
                </>
              ) : (
                ""
              )}
            </td>

            <td>{invoice.gas_access_toll ? invoice.gas_access_toll : invoice.access_toll}</td>
            <td>
              {invoice.invoice.invoice_type === "dual" ? (
                <span>
                  {invoice.cups}
                  <br />
                  {invoice.gas_cups}
                </span>
              ) : invoice.cups ? (
                invoice.cups
              ) : invoice.gas_cups ? (
                invoice.gas_cups
              ) : (
                ""
              )}
            </td>
            <td></td>
            <td>{invoice.organization_name}</td>
            <td>{invoice.organization_code}</td>
            <td>
              {invoice.invoice.customer_status === "created"
                ? ""
                : invoice.invoice.customer_status === "generated_report"
                ? "informe generado"
                : invoice.invoice.customer_status}
            </td>
            <td>{formatDate(invoice.invoice.created_at)}</td>
            <td></td>
            <td></td>
            <td>
              <div className={styles.actionsContainer}>
                {invoice.savings.length > 0 ? (
                  <a
                    onClick={(e) => e.stopPropagation()}
                    href={`/energy_invoices/${invoice.invoice.id}/saving_calculations`}
                  >
                    <MdOutlineEuroSymbol
                      className={`${styles.icons} ${styles.compIcon}`}
                    />
                    <span className={styles.hoverTextComp}>
                      Ver comparativa
                    </span>
                  </a>
                ) : (
                  ""
                )}
                <span
                  onClick={(e) => e.stopPropagation()}
                  className={styles.acciones}
                >
                  <a href={`/energy_invoices/${invoice.invoice.id}`}>
                    <MdOutlineRemoveRedEye
                      className={`${styles.icons} ${styles.eyeIcon}`}
                    />
                    <span className={styles.hoverTextEye}>
                      Ver procesamiento
                    </span>
                  </a>
                  <a
                    href={`/api/v2/energy_invoices/${invoice.invoice.id}/download_input_document`}
                    className="invoice-download"
                    download
                  >
                    <MdSimCardDownload
                      className={`${styles.icons} ${styles.downloadIcon}`}
                    />
                    <span className={styles.hoverTextDownload}>Descargar</span>
                  </a>
                  <a>
                    <MdDelete
                      className={`${styles.icons} ${styles.trashIcon}`}
                      onClick={() =>
                        handleDeleteEnergyInvoice(invoice.invoice.id)
                      }
                    />
                    <span className={styles.hoverTextDelete}>Eliminar</span>
                  </a>
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
