import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SubmitButton = styled.button`
  margin-top: 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: ${props => props.env_color};
  width: 260px;
  border: 2px solid ${props => props.env_color}; /* Ajuste aquí */
  border-radius: 50px;
  padding: 15px 30px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: #ffffff;
    color: ${props => props.env_color};
  }

  :active {
    border: none;
  }
`;

export const Title1 = styled.h1`
  font-size: 30px;
  margin-top: -15px;
  color: ${props => props.env_color};
  margin-bottom: 8px;

  @media only screen and (max-width: 1000px) {
    font-size: 30px;
  }
`;

export const Title2 = styled.h2`
  font-size: 20px;
  color: ${props => props.env_color || '#05849c'};
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: auto;
  text-align: left;

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const Title3 = styled.h2`
  font-size: 20px;
  color: ${props => props.env_color};
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: auto;
  text-align: center;

  @media only screen and (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const Title4 = styled.h3`
  font-size: 16px;
  color: ${props => props.env_color || '#05849c'};
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 20px;
  margin-right: auto;
  text-align: left;

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }
`;

export const FilterTitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Text = styled.div`
  margin-top: 3px;
  text-align: center;
`;

export const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const FilterItem = styled.div`
  flex: 1;
  margin-right: 1rem;

  label {
    display: flex;
    align-items: center;
    font-size: 16px; /* Ajusta el tamaño de fuente para consistencia */
    color: #333; /* Color de texto consistente */
    white-space: nowrap; /* Esto evita que el texto se divida en varias líneas */
  }

  input {
    margin-right: 0.5rem; /* Añade espacio entre el checkbox y el texto */
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const TechFiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  label {
    white-space: nowrap;
    margin-right: 1rem;
  }

  label:last-child {
    margin-right: 0;
  }
`;

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas de igual tamaño */
  gap: 10px; /* Espacio entre los productos */
  justify-content: center; /* Centra el grid en su contenedor */

  @media (max-width: 600px) {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }
`;

export const ProductItem = styled.div`
  cursor: pointer;
  padding: 10px;
  margin: 8px;
  border: ${props => props.isSelected ? '4px solid ' + (props.env_color || '#ddd') : '2px solid ' + (props.env_color || '#ddd')}; /* Borde más grueso cuando seleccionado */
  border-radius: 8px;
  background-color: ${props => (props.isSelected ? '#f0f0f0' : '#fff')};
  transition: border 0.3s ease, background-color 0.3s ease;
  user-select: none;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ProductsWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  width: 100%;
`;