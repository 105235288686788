import React from "react";
import styles from "./index.module.css";
import {
  MdDelete,
  MdOutlineInsertDriveFile,
  MdInsertDriveFile,
  MdSimCardDownload,
} from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { downloadSelectedInvoices } from "./api/api";
import { CgExport } from "react-icons/cg";

const ToolBar = ({ selectedRows, setSelectedRows, onExportButtonClick }) => {
  return (
    <div className={styles.selectedItemActionsContainer}>
      <div className={styles.selectedItemsInfo}>
        <IoIosClose
          onClick={() => setSelectedRows([])}
          className={`${styles.icons} ${styles.deleteIcon}`}
        />
        <p className={styles.selectedItemsText}>
          {selectedRows.length === 1
            ? selectedRows.length + " procesamiento seleccionado"
            : selectedRows.length > 1
            ? selectedRows.length + " procesamientos seleccionados"
            : ""}
        </p>
      </div>

      <div className={styles.iconsContainer}>
        <div
          onClick={() => downloadSelectedInvoices(selectedRows)}
          className={styles.iconContent}
        >
          <MdSimCardDownload className={styles.icons} />
          <span className={styles.iconText}> Descargar</span>
        </div>
        <div className={styles.iconContent}>
          <a
            href={`/admin/energy_invoices/partial_aggregated_report/${selectedRows}`}
          >
            <span className={styles.iconContent}>
              <MdOutlineInsertDriveFile
                className={`${styles.icons} ${styles.partialIcon}`}
              />
              <span className={styles.iconText}>Generar informe parcial</span>
            </span>
          </a>
        </div>
        <div className={styles.iconContent}>
          <a
            href={`/admin/energy_invoices/complete_aggregated_report/${selectedRows}`}
          >
            <span className={styles.iconContent}>
              <MdInsertDriveFile
                className={`${styles.icons} ${styles.totalIcon}`}
              />
              <span className={styles.iconText}>Generar informe completo</span>
            </span>
          </a>
        </div>
        <div className={styles.iconContent} onClick={onExportButtonClick}>
          <CgExport className={`${styles.icons} ${styles.trashIcon}`} />
          <span className={styles.iconText}> Exportar ahorros</span>
        </div>
        <div className={styles.iconContent}>
          <MdDelete className={`${styles.icons} ${styles.trashIcon}`} />
          <span className={styles.iconText}>Eliminar</span>
        </div>
      </div>
    </div>
  );
};

export default ToolBar;
