import axios from "axios";

export const fetchRatePlans = async (startDate, endDate) => {
  try {
    const response = await axios.get(
      "/admin/energy_invoices/index_energy_invoices"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const deleteEnergyInvoice = async (invoiceId) => {
  try {
    const url = `/admin/energy_invoices/${invoiceId}`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    });
    return response.data;
  } catch (error) {
    window.location.reload(true);
    console.error("Error fetching data:", error);
  }
};

export const downloadSelectedInvoices = async (invoiceIds) => {
  const ko = window.location.href.match(
    /index_luz_2x_ko|index_luz_3x_ko|index_gas_ko/
  )
    ? true
    : false;

  try {
    const response = await axios.get(
      "/admin/energy_invoices_download_selected_invoices",
      {
        params: {
          energy_invoice_ids: invoiceIds,
          ko: ko,
        },
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([response.data], { type: "application/zip" });
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = "Invoices.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const energyInvoicesMassiveDownload = async (startDate, endDate) => {
  try {
    if (window.location.pathname.match(/luz/)) {
      invoice_type = "electric";
    } else if (window.location.pathname.match(/gas/)) {
      invoice_type = "gas";
    }

    const path =
      "/admin/energy_invoices_massive_download" +
      `?start_date=${startDate}&end_date=${endDate}`;

    await sendPetition(path);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const sendPetition = async (path) => {
  try {
    const response = await axios.get(path, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Invoices.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const exportSavings = (selectedRows, positiveSaving) => {
  axios
    .post("/admin/energy_invoices/export_saving_calculation.xlsx", {
      energy_invoice_ids: selectedRows,
      positive_saving: positiveSaving,
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/zip" });
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "Savings.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return error;
    });
};
