import React from 'react';
import {
  FiltersRow,
  FilterItem
} from "../../../styles/individualComponents/AddTelcoProduct";

const FiberFilters = ({ fiberFilters, handleFiberFilterChange }) => {
  const speed = fiberFilters.fibreSpeed || 100;

  return (
    <>
      <div style={{ marginBottom: '20px' }}></div>
      <FiltersRow>
        <FilterItem>
          <label>
            <input
              type="checkbox"
              checked={fiberFilters.fibreType === 'adsl'}
              onChange={(e) => handleFiberFilterChange('fibreType', e.target.checked ? 'adsl' : null)}
            />
            ADSL
          </label>
        </FilterItem>
        <FilterItem>
          <label>
            <input
              type="checkbox"
              checked={fiberFilters.fibreType === 'fibra'}
              onChange={(e) => handleFiberFilterChange('fibreType', e.target.checked ? 'fibra' : null)}
            />
            Fibra
          </label>
        </FilterItem>
      </FiltersRow>
      {fiberFilters.fibreType === 'fibra' && (
        <FiltersRow>
          <FilterItem style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span style={{ marginRight: '10px' }}>
              Velocidad de la fibra:
            </span>
            <input
              type="range"
              min="100"
              max="2000"
              step="100"
              value={speed}
              onChange={(e) => handleFiberFilterChange('fibreSpeed', e.target.value)}
              style={{ flexGrow: 1, marginRight: '10px' }}
            />
            <span>{speed} Mbps</span>
          </FilterItem>
        </FiltersRow>
      )}
    </>
  );
};

export default FiberFilters;
