import React from "react";
import styles from "./textArea.module.css";
import { MdErrorOutline } from "react-icons/md";

const TextArea = (props) => {
  return (
    <>
    <div>
      <div className={styles.textAreacontainer}>
        <label className={`${props.className} ${props.labelClassName || ""}`}>
          {props.labelText}
        </label>
        <p className={`${props.className} ${props.textClassName || ""}`}>{props.smallText}</p>

        <textarea
          id={props.id}
          name={props.name}
          className={props.error ? styles.textAreaSizeError : styles.textAreaSize}
          value={props.value}
          defaultValue={props.defaultValue}
          required={props.required}
          onChange={props.onChange}
        ></textarea>
        {props.error && <MdErrorOutline className={styles.iconError}/>} 
        {props.touched && props.error && props.value}
        <span id={styles.error}>{props.error}</span>
      </div>
    </div>
    </>
  );
};

export default TextArea;
