import React from "react";
import {
  Container,
  Title,
  Column,
  Price,
} from "../../../../styles/individualComponents/PlanDetails";

export const ElectricPrices = ({ pricing, isIndexedPrice }) => {
  const {
    energyPrices,
    indexedPrices,
    energyDiscounts,
    powerPrices,
    powerDiscounts,
  } = pricing;
  var energyCount = -1;
  var powerCount = -1;
  return (
    <Container>
      <Column>
        <Title>
          Precios de energía {isIndexedPrice ? <> (indexado)</> : null}
        </Title>
        {pricing.dhType.includes("1te") ? (
          <Price key={energyCount}>
            {energyPrices[0] != null && <>
              P1: {parseFloat(energyPrices[0]).toFixed(6).replace(".", ",")} €/kWh{" "}
              {energyDiscounts[0] > 0 && <>(-{parseInt(energyDiscounts[0])}% dto)</>}
            </>}
          </Price>
        ) : (
          <>
            {energyPrices.map((price) => {
              if (price !== null) {
                energyCount = energyCount + 1;
                return (
                  <Price key={energyCount}>
                    P{energyCount + 1}: {parseFloat(price).toFixed(6).replace(".", ",")} €/kWh{" "}
                    {energyDiscounts[energyCount] > 0 && !isIndexedPrice ? (
                      <>(-{parseInt(energyDiscounts[energyCount])}% dto)</>
                    ) : null}
                  </Price>
                );
              }
            })}
          </>
        )}
      </Column>
      <Column>
        <Title>Precios de potencia</Title>
        {powerPrices.map((price) => {
          if (price !== null) {
            powerCount = powerCount + 1;
            return (
              <Price key={powerCount}>
                P{powerCount + 1}: {parseFloat(price).toFixed(6).replace(".", ",")} €/kW{" "}
                {powerDiscounts[powerCount] > 0 ? (
                  <>(-{parseInt(powerDiscounts[powerCount])}% dto)</>
                ) : null}
              </Price>
            );
          }
        })}
      </Column>
    </Container>
  );
};
