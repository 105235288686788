import React from "react";
import { FileName } from "../../../styles/individualComponents/add_form";
import { IoCloseSharp } from "react-icons/io5";
const FilesMap = ({ files, setfile }) => {
  const handleDelete = (name) => {
    const newFiles = files.filter((file) => file.name !== name);
    setfile(newFiles);
  };
  return (
    <div style={{ position: "relative", zIndex: "8", margin: "10px 0px" }}>
      {files.map((file, index) => (
        <FileName key={index}>
          {file.name}
          <IoCloseSharp
            onClick={() => handleDelete(file.name)}
            size={20}
            style={{ marginLeft: "10px", cursor: "pointer", color: "red" }}
          />
        </FileName>
      ))}
    </div>
  );
};
export default FilesMap;
