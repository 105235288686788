import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import styles from "./formStyles.module.css";
import {validationSchema} from "../validations/validationSchema"

import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";

import TextArea from "../../../reactComponents/textArea/TextArea";
const ComComerciales = ({ organization, flatRates, reqInvoicesData, informPricesData, informTelcoPricesData, 
  savingCalcData, savingTelcoCalcData, accDeacData, accUnlockData, welcomeMailData, otherEmailTempl,pdfDownloadEmail,
  pdfSimulationEmail,  luz_2xExcel, luz_3xExcel, gasExcel, dualExcel, selectedSCalcExcel, failedInvoicesExcel,
  rPlansSCalcExcel, current_user_roles }) => {
    const formik = useFormik({
      initialValues: {
        organization: {
          r_plan_info_display_fmt_inform_pr: 
          organization.r_plan_info_display_fmt_inform_pr
        },
      },
      validationSchema: validationSchema,
    });

  const [htmlCorreo, setHtmlCorreo] = useState(
    organization.invoice_request_email_template
  );

  const [htmlFooter, setHtmlFooter] = useState(
    organization.savings_email_footer
  );

  const [htmlHeader, setHtmlHeader] = useState(
    organization.savings_email_header
  );

  const handleHtmlCorreoChange = (e) => {
    setHtmlCorreo(e.target.value);
  };

  const handleHtmlFooterChange = (e) => {
    setHtmlFooter(e.target.value);
  };

  const handleHtmlHeaderChange = (e) => {
    setHtmlHeader(e.target.value);
  };

  //selects

  const getTemplateName = (templateId, templateData) => {
    const matchingTemplate = templateData.find(
      (item) => item.id === templateId
    );
    return matchingTemplate ? matchingTemplate.name : "";
  };

  const [reqInvoice, setReqInvoice] = useState(() =>
    getTemplateName(
      organization.request_invoice_email_template_id,
      reqInvoicesData
    )
  );
  const [infPrecios, setInfPrecios] = useState(() =>
    getTemplateName(
      organization.inform_prices_email_template_id,
      informPricesData
    )
  );
  const [telcoInfPrecios, setTelcoInfPrecios] = useState(() =>
  getTemplateName(
    organization.tlc_inform_prices_email_template_id,
    informTelcoPricesData
  )
);
  const [savingCalc, setSavingCalc] = useState(() =>
    getTemplateName(
      organization.saving_calculation_email_template_id,
      savingCalcData
    )
  );
  const [telcoSavingCalc, setTelcoSavingCalc] = useState(() =>
  getTemplateName(
    organization.tlc_saving_calculation_email_template_id,
    savingTelcoCalcData
  )
);
  const [deactUser, setDeactUser] = useState(() =>
    getTemplateName(
      organization.account_desactivation_email_template_id,
      accDeacData
    )
  );
  const [accUnlock, setAccUnlock] = useState(() =>
    getTemplateName(
      organization.account_unlock_email_template_id,
      accUnlockData
    )
  );
  const [welcomeUser, setWelcomeUser] = useState(() =>
    getTemplateName(
      organization.welcome_mail_email_template_id,
      welcomeMailData
    )
  );
  const [otherTemplates, setOtherTemplates] = useState(() =>
    getTemplateName(organization.other_email_template_id, otherEmailTempl)
  );

  const [pdfDownload, setPdfDownload] = useState(() =>
    getTemplateName(
      organization.pdf_download_email_template_id,
      pdfDownloadEmail
    )
  );
  const [pdfSimulation, setPdfSimulation] = useState(() =>
    getTemplateName(
      organization.pdf_simulation_email_template_id,
      pdfSimulationEmail
    )
  );

  const [luz_2x, setLuz_2x] = useState(()=> getTemplateName(organization.luz_2x_email_template_id, luz_2xExcel));
  const [luz_3x, setLuz_3x] = useState(()=> getTemplateName(organization.luz_3x_email_template_id, luz_3xExcel));
  const [gas, setGas] = useState(()=> getTemplateName(organization.gas_email_template_id, gasExcel));
  const [dual, setDual] = useState(()=> getTemplateName(organization.dual_email_template_id, dualExcel));
  const [selectedSCalc, setSelectedSCalc] = useState(()=> getTemplateName(organization.selected_s_calc_email_template_id, selectedSCalcExcel));
  const [failedInvoices, setFailedInvoices] = useState(()=> getTemplateName(organization.failed_invoices_email_template_id, failedInvoicesExcel));
  const [rPlansSCalc, setRPlansSCalc] = useState(()=> getTemplateName(organization.r_plans_s_calc_email_template_id, rPlansSCalcExcel));

  useEffect(() => {
    setReqInvoice(
      getTemplateName(
        organization.request_invoice_email_template_id,
        reqInvoicesData
      )
    );
    setInfPrecios(
      getTemplateName(
        organization.inform_prices_email_template_id,
        informPricesData
      )
    );
    setTelcoInfPrecios(
      getTemplateName(
        organization.tlc_inform_prices_email_template_id,
        informTelcoPricesData
      )
    );
    setSavingCalc(
      getTemplateName(
        organization.saving_calculation_email_template_id,
        savingCalcData
      )
    );
    setTelcoSavingCalc(
      getTemplateName(
        organization.tlc_saving_calculation_email_template_id,
        savingTelcoCalcData
      )
    );
    setDeactUser(
      getTemplateName(
        organization.account_desactivation_email_template_id,
        accDeacData
      )
    );
    setAccUnlock(
      getTemplateName(
        organization.account_unlock_email_template_id,
        accUnlockData
      )
    );
    setWelcomeUser(
      getTemplateName(
        organization.welcome_mail_email_template_id,
        welcomeMailData
      )
    );
    setOtherTemplates(
      getTemplateName(organization.other_email_template_id, otherEmailTempl)
    );
    setPdfDownload(
      getTemplateName(
        organization.pdf_download_email_template_id,
        pdfDownloadEmail
      )
    );
    setPdfSimulation(
      getTemplateName(
        organization.pdf_simulation_email_template_id,
        pdfSimulationEmail
      )
    );
 
    setLuz_2x(getTemplateName(organization.luz_2x_email_template_id, luz_2xExcel));
    setLuz_3x(getTemplateName(organization.luz_3x_email_template_id, luz_3xExcel));
    setGas(getTemplateName(organization.gas_email_template_id, gasExcel));
    setDual(getTemplateName(organization.dual_email_template_id, dualExcel));
    setSelectedSCalc(getTemplateName(organization.selected_s_calc_email_template_id, selectedSCalcExcel));
    setFailedInvoices(getTemplateName(organization.failed_invoices_email_template_id, failedInvoicesExcel));
    setRPlansSCalc(getTemplateName(organization.r_plans_s_calc_email_template_id, rPlansSCalcExcel));

}, [organization.request_invoice_email_template_id, reqInvoicesData, 
  organization.inform_prices_email_template_id, informPricesData, 
  organization.saving_calculation_email_template_id, savingCalcData,
  organization.tlc_saving_calculation_email_template_id, savingTelcoCalcData, 
  organization.tlc_inform_prices_email_template_id, informTelcoPricesData,
  organization.account_desactivation_email_template_id, accDeacData,
  organization.account_unlock_email_template_id, accUnlockData,
  organization.welcome_mail_email_template_id, welcomeMailData,
  organization.other_email_template_id, otherEmailTempl,
  organization.pdf_download_email_template_id, pdfDownloadEmail,
  organization.pdf_simulation_email_template_id, pdfSimulationEmail,
  organization.luz_2x_email_template_id, luz_2xExcel,
  organization.luz_3x_email_template_id, luz_3xExcel,
  organization.gas_email_template_id, gasExcel,
  organization.dual_email_template_id, dualExcel,
  organization.selected_s_calc_email_template_id, selectedSCalcExcel,
  organization.failed_invoices_email_template_id, failedInvoicesExcel,
  organization.r_plans_s_calc_email_template_id, rPlansSCalcExcel,
]);

  const flatRatesData = flatRates.map((item) => ({
    id: item[1],
    name: item[0],
  }));

  const findValueMatch = (array, valuePlaceholder) => {
    let matchingName = null;

    array.forEach((option) => {
      if (option.id === valuePlaceholder) {
        valuePlaceholder = option.id;
        matchingName = option.name;
      }
    });
    return matchingName;
  };

  const flatRatesString = findValueMatch(
    flatRatesData,
    organization.allow_compare_flat_rates
  );

 
  const updateSolicInvoice = [{ id: null, name: null, plantilla: "Plantilla por defecto" }, ...reqInvoicesData];

    const updateInformPrices = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...informPricesData,
    ]; 

    const updateInformTelcoPrices = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...informTelcoPricesData,
    ];

    const calcSavings = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...savingCalcData,
    ];

    const calcTelcoSavings = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...savingTelcoCalcData,
    ];

    const deactUserr = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...accDeacData,
    ];

    const unlockAccount = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...accUnlockData,
    ];
    const welcomeUserEm = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...welcomeMailData,
    ];
    const downloadPdf = [
    { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...pdfDownloadEmail,
    ];
    const simulatePdf = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...pdfSimulationEmail,
    ];
    const otherTempl = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
      ...otherEmailTempl,
    ];
    const luz2xExcel = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...luz_2xExcel,
    ];
    const luz3xExcel = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...luz_3xExcel,
    ];
    const gasExcelTempl = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...gasExcel,
    ];
    const dualExcelTempl = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...dualExcel,
    ];
    const selectedSCalcExcelTempl = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...selectedSCalcExcel,
    ];
    const failedInvoicesExcelTempl = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...failedInvoicesExcel,
    ];
    const rPlansSCalcExcelTempl = [
      { id: null, name: null, plantilla: "Plantilla por defecto" },
        ...rPlansSCalcExcel,
    ];

      console.log(typeof organization.saving_calculation_email_template_id);
      const foundItemName = (array, value) => {
        let matchingName = null;
        array.forEach((option) => {
          if (option.id === value) {
            matchingName = option.name;
          }
        });
        return matchingName;
      }

console.log(updateInformPrices, "inform");
console.log(updateSolicInvoice, "solic");
  return (
    <>
      <h3 className={styles.title}> Comunicaciones comerciales</h3>
      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Solicitud de facturas'"
          data={updateSolicInvoice}
          placeholder={
            organization.request_invoice_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  updateSolicInvoice,
                  organization.request_invoice_email_template_id
                )
          }
          selectId="organization_request_invoice_email_template_id"
          selectName="organization[request_invoice_email_template_id]"
          dataSelected={[organization.request_invoice_email_template_id]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Informar precios'"
          data={updateInformPrices}
          placeholder={
            organization.inform_prices_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  updateInformPrices,
                  organization.inform_prices_email_template_id
                )
          }
          selectId="organization_inform_prices_email_template_id"
          selectName="organization[inform_prices_email_template_id]"
          dataSelected={[organization.inform_prices_email_template_id]}
        />
      </div>
      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Cálculo de ahorro'"
          data={calcSavings}
          placeholder={
            organization.saving_calculation_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  calcSavings,
                  organization.saving_calculation_email_template_id
                )
          }
          selectId="organization_saving_calculation_email_template_id"
          selectName="organization[saving_calculation_email_template_id]"
          dataSelected={[organization.saving_calculation_email_template_id]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Desactivación de usuario'"
          data={deactUserr}
          placeholder={
            organization.account_desactivation_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  deactUserr,
                  organization.account_desactivation_email_template_id
                )
          }
          selectId="organization_account_desactivation_email_template_id"
          selectName="organization[account_desactivation_email_template_id]"
          dataSelected={[organization.account_desactivation_email_template_id]}
        />
      </div>
      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Desbloqueo de cuenta'"
          data={unlockAccount}
          placeholder={
            organization.account_unlock_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  unlockAccount,
                  organization.account_unlock_email_template_id
                )
          }
          selectId="organization_account_unlock_email_template_id"
          selectName="organization[account_unlock_email_template_id]"
          dataSelected={[organization.account_unlock_email_template_id]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Bienvenida de usuario'"
          data={welcomeUserEm}
          placeholder={
            organization.welcome_mail_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  welcomeUserEm,
                  organization.welcome_mail_email_template_id
                )
          }
          selectId="organization_welcome_mail_email_template_id"
          selectName="organization[welcome_mail_email_template_id]"
          dataSelected={[organization.welcome_mail_email_template_id]}
        />
      </div>

      <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Descargar PDF'"
          data={downloadPdf}
          placeholder={
            organization.pdf_download_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  downloadPdf,
                  organization.pdf_download_email_template_id
                )
          }
          selectId="organization_pdf_download_email_template_id"
          selectName="organization[pdf_download_email_template_id]"
          dataSelected={[organization.pdf_download_email_template_id]}
        />
        <div className={styles.space}></div>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas de 'Simular PDF'"
          data={simulatePdf}
          placeholder={
            organization.pdf_simulation_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                  simulatePdf,
                  organization.pdf_simulation_email_template_id
                )
          }
          selectId="organization_pdf_simulation_email_template_id"
          selectName="organization[pdf_simulation_email_template_id]"
          dataSelected={[organization.pdf_simulation_email_template_id]}
        />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Luz 2x'"
            data={luz2xExcel}
            placeholder={
              organization.luz_2x_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  luz2xExcel,
                    organization.luz_2x_email_template_id
                  )
            }
            selectId="organization_luz_2x_email_template_id"
            selectName="organization[luz_2x_email_template_id]"
            dataSelected={[organization.luz_2x_email_template_id]}
          />
          <div className={styles.space}></div>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Luz 3x'"
            data={luz3xExcel}
            placeholder={
              organization.luz_3x_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  luz3xExcel,
                    organization.luz_3x_email_template_id
                  )
            }
            selectId="organization_luz_3x_email_template_id"
            selectName="organization[luz_3x_email_template_id]"
            dataSelected={[organization.luz_3x_email_template_id]}
          />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Gas'"
            data={gasExcelTempl}
            placeholder={
              organization.gas_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  gasExcelTempl,
                    organization.gas_email_template_id
                  )
            }
            selectId="organization_gas_email_template_id"
            selectName="organization[gas_email_template_id]"
            dataSelected={[organization.gas_email_template_id]}
          />
          <div className={styles.space}></div>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamiento Dual'"
            data={dualExcelTempl}
            placeholder={
              organization.dual_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  dualExcelTempl,
                    organization.dual_email_template_id
                  )
            }
            selectId="organization_dual_email_template_id"
            selectName="organization[dual_email_template_id]"
            dataSelected={[organization.dual_email_template_id]}
          />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Ahorros calculados seleccionados'"
            data={selectedSCalcExcelTempl}
            placeholder={
              organization.selected_s_calc_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  selectedSCalcExcelTempl,
                    organization.selected_s_calc_email_template_id
                  )
            }
            selectId="organization_selected_s_calc_email_template_id"
            selectName="organization[selected_s_calc_email_template_id]"
            dataSelected={[organization.selected_s_calc_email_template_id]}
          />
          <div className={styles.space}></div>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Procesamientos inválidos'"
            data={failedInvoicesExcelTempl}
            placeholder={
              organization.failed_invoices_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  failedInvoicesExcelTempl,
                    organization.failed_invoices_email_template_id
                  )
            }
            selectId="organization_failed_invoices_email_template_id"
            selectName="organization[failed_invoices_email_template_id]"
            dataSelected={[organization.failed_invoices_email_template_id]}
          />
        </div>
        <div className={styles.comComercialesSelects}>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Excel de 'Ahorros de las tarifas seleccionadas'"
            data={rPlansSCalcExcelTempl}
            placeholder={
              organization.r_plans_s_calc_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  rPlansSCalcExcelTempl,
                    organization.r_plans_s_calc_email_template_id
                  )
            }
            selectId="organization_r_plans_s_calc_email_template_id"
            selectName="organization[r_plans_s_calc_email_template_id]"
            dataSelected={[organization.r_plans_s_calc_email_template_id]}
          />
          <div className={styles.space}></div>
          <Select
            multiSelect={false}
            searchBar={true}
            name="Otras plantillas"
            data={otherTempl}
            placeholder={
              organization.other_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                  otherTempl,
                    organization.other_email_template_id
                  )
            }
            selectId="organization_other_email_template_id"
            selectName="organization[other_email_template_id]"
            dataSelected={[organization.other_email_template_id]}
          />
        </div>
        <div className={styles.comComercialesSelects}>
        <Select
          multiSelect={false}
          searchBar={true}
          name="Plantillas 'Informar precios Telco'"
          data={updateInformTelcoPrices}
          placeholder={
            organization.tlc_inform_prices_email_template_id === null
              ? "Plantilla por defecto"
              : foundItemName(
                updateInformTelcoPrices,
                  organization.tlc_inform_prices_email_template_id
                )
          }
          selectId="organization_tlc_inform_prices_email_template_id"
          selectName="organization[tlc_inform_prices_email_template_id]"
          dataSelected={[organization.tlc_inform_prices_email_template_id]}
        />
        <div className={styles.space}></div>
        <Select
            multiSelect={false}
            searchBar={true}
            name="Plantillas de 'Cálculo de ahorro telco'"
            data={calcTelcoSavings}
            placeholder={
              organization.tlc_saving_calculation_email_template_id === null
                ? "Plantilla por defecto"
                : foundItemName(
                    calcTelcoSavings,
                    organization.tlc_saving_calculation_email_template_id
                  )
            }
            selectId="organization_tlc_saving_calculation_email_template_id"
            selectName="organization[tlc_saving_calculation_email_template_id]"
            dataSelected={[organization.tlc_saving_calculation_email_template_id]}
          />
          <div className={styles.space}></div>
        </div>

      <TextArea
        labelText="HTML Correo 'Solicitud Factura'"
        name="organization[invoice_request_email_template]"
        id="organization_invoice_request_email_template"
        labelClassName={styles.headerColor}
        smallText=""
        defaultValue={htmlCorreo}
        onChange={(e) => handleHtmlCorreoChange(e)}
      />
      <TextArea
        labelText="HTML Footer 'Envío Ahorro"
        name="organization[savings_email_footer]"
        id="organization_savings_email_footer"
        labelClassName={styles.headerColor}
        smallText=""
        defaultValue={htmlFooter}
        onChange={(e) => handleHtmlFooterChange(e)}
      />
      <TextArea
        labelText="HTML Header 'Envío Ahorro'"
        name="organization[savings_email_header]"
        id="organization_savings_email_header"
        labelClassName={styles.headerColor}
        smallText=""
        defaultValue={htmlHeader}
        onChange={(e) => handleHtmlHeaderChange(e)}
      />
      <Select
        multiSelect={false}
        searchBar={true}
        name="Gestión facturas tipo: 'Tarifa Plana'"
        data={flatRatesData}
        placeholder={foundItemName(
          flatRatesData,
          organization.allow_compare_flat_rates
        )}
        dataSelected={organization.allow_compare_flat_rates}
        selectId="organization_allow_compare_flat_rates"
        selectName="organization[allow_compare_flat_rates]"
      />

      {(current_user_roles.some((rol) => rol.name === "Admin")) &&
        <>
          <Input
            label={
              <>
              Formato de visualización de datos de tarifario en informar precios <span style={{ color: "red" }}>*</span>
              </>
            }
            type="text"
            required="required"
            name="organization[r_plan_info_display_fmt_inform_pr]"
            id="organization_r_plan_info_display_fmt_inform_pr"
            placeholder={organization.r_plan_info_display_fmt_inform_pr}
            value={formik.values.organization.r_plan_info_display_fmt_inform_pr}
            onChange={formik.handleChange}
            error={formik.errors.organization?.r_plan_info_display_fmt_inform_pr}
            touched={formik.touched.organization?.r_plan_info_display_fmt_inform_pr}
          />
        </>
      }
    </>
  );
};
export default ComComerciales;
