import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.css";
import { exportSavings } from "./api/api";

const ModalExportSavings = ({ onClose, selectedRows }) => {
  const [positiveSaving, setPositiveSaving] = useState(false);
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, onClose]);

  return (
    <div className={styles.modal}>
      <div ref={modalRef} className={styles.modalContent}>
        <h4>Selecciona los filtros a aplicar para exportar los ahorros</h4>
        <h5>
          {selectedRows.length}
          {selectedRows.length > 0 ? (
            <span className={styles.itemsSelectedText}>
              {" "}
              procesamientos seleccionados
            </span>
          ) : (
            <span className={styles.itemsSelectedText}>
              {" "}
              procesamiento seleccionado
            </span>
          )}
        </h5>
        <div className={styles.exportCheckbox}>
          ¿Extraer solo comparativas con ahorro positivo?
          <input
            className={styles.exportSavingsInput}
            type="checkbox"
            checked={positiveSaving}
            onChange={() => setPositiveSaving(!positiveSaving)}
          ></input>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={`${styles.btn} ${styles.btnExtractComp}`}
            onClick={exportSavings}
          >
            Extraer comparativa
          </button>
          <button
            onClick={onClose}
            className={`${styles.btn} ${styles.btnClose}`}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalExportSavings;
