import * as Yup from "yup";

export const validationSchema = Yup.object({
  area: Yup.object({
    area_name: Yup.string()
      .required("Provincia es obligatorio")
      .matches(
        /^[a-zA-Z]+$/,
        "Nombre solo puede contener letras, sin números ni signos"
      ),
    area_code: Yup.string()
      .required("Código es obligatorio")
      .matches(/^[0-9]+$/, "Código debe contener solo números"),
    search_keyword_1: Yup.string()
      .required("Palabra clave es obligatorio")
      .matches(
        /^[a-zA-Z]+$/,
        "Palabra clave solo puede contener letras, sin números ni signos"
      ),
    state: Yup.string()
      .required("Comunidad Autónoma es obligatorio")
      .matches(
        /^[a-zA-Z]+$/,
        "Comunidad Autónoma solo puede contener letras, sin números ni signos"
      ),
  }),
});
