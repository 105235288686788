import React from 'react';
import MobileFilters from "./MobileFilters";
import LandlineFilters from "./LandlineFilters";
import FiberFilters from "./FiberFilters";

export const FilterComponents = ({ productType, mobileFilters, handleMobileFilterChange, landlineFilters, handleLandlineFilterChange, fiberFilters, handleFiberFilterChange }) => {
  return (
    <>
      {productType === 'movil' && (
        <MobileFilters // Applies the mobile filtering
          mobileFilters={mobileFilters}
          handleMobileFilterChange={handleMobileFilterChange}
        />
      )}
      {productType === 'fijo' && (
        <LandlineFilters // Applies the land line filtering
          landlineFilters={landlineFilters}
          handleLandlineFilterChange={handleLandlineFilterChange}
        />
      )}
      {productType === 'fibra' && (
        <FiberFilters // Applies the fiber filtering
          fiberFilters={fiberFilters}
          handleFiberFilterChange={handleFiberFilterChange}
        />
      )}
    </>
  );
};
