const telcoProductAdapter = (data) => {
  let adaptTelcoProduct = data.map((telcoProduct) => {
    return {
      id: telcoProduct.id,
      name: telcoProduct.telco_product_name,
      description: telcoProduct.telco_product_description,
      type: telcoProduct.telco_product_type,
      status: telcoProduct.status,
      priority: telcoProduct.priority,
      providerId: telcoProduct.provider_id,
      businessSection: telcoProduct.business_section,
      code: telcoProduct.code,
      displayCode: telcoProduct.display_code,
      regex: telcoProduct.regex,
      fibreSpeed: telcoProduct.fibre_speed,
      fibreType: telcoProduct.fibre_type,
      landlineNumMinNationals: telcoProduct.landline_num_min_nationals,
      landlineNumMinInternationals: telcoProduct.landline_num_min_internationals,
      landlineIsMinsNationalsUnlimited: telcoProduct.landline_is_mins_nationals_unlimited,
      landlineIsMinsInternationalsUnlimited: telcoProduct.landline_is_mins_internationals_unlimited,
      roaming: telcoProduct.roaming,
      numSmsNationals: telcoProduct.num_sms_nationals,
      numSmsInternationals: telcoProduct.num_sms_internationals,
      isTextNationalsUnlimited: telcoProduct.is_text_nationals_unlimited,
      isTextInternationalsUnlimited: telcoProduct.is_text_internationals_unlimited,
      numGb: telcoProduct.num_gb,
      isDataUnlimited: telcoProduct.is_data_unlimited,
      isDataShared: telcoProduct.is_data_shared,
      mobileDataGeneration: telcoProduct.mobile_data_generation,
      mobileNumMinNationals: telcoProduct.mobile_num_min_nationals,
      mobileNumMinInternationals: telcoProduct.mobile_num_min_internationals,
      mobileIsMinsNationalsUnlimited: telcoProduct.mobile_is_mins_nationals_unlimited,
      mobileIsMinsInternationalsUnlimited: telcoProduct.mobile_is_mins_internationals_unlimited,
    };
  });

  return adaptTelcoProduct;
};

export default telcoProductAdapter;
