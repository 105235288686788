import React from "react";
import { ExportsContainer, ExportButton } from "../../../../styles/rate_plans";
import { CgImport, CgExport } from "react-icons/cg";
import { FiPlusCircle } from "react-icons/fi";
 
 
export const Exports = () => {
  return (
    <>
      <ExportsContainer>
        <ExportButton target="_blank" href="/admin/clients.xlsx">
          <CgImport style={{ marginBottom: "4px" }} size={20} />
          Exportar
        </ExportButton>
        <ExportButton target="_blank" href="/admin/data_imports/new">
          <CgExport style={{ marginBottom: "4px" }} size={20} />
          Importar
        </ExportButton>
        <ExportButton href="/admin/clients/new">
          <FiPlusCircle
            style={{ marginBottom: "3px", marginRight: "2px" }}
            size={20}
          />
          Crear cliente
        </ExportButton>
      </ExportsContainer>
    </>
  );
};