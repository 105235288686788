// ActionsNavbar.js

import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "./index.module.css";

import ButtonsContainer from "./ButtonsContainer";

const ActionsNavbar = ({
  searchTerm,
  setStartDateAndEndDate,
  setEndDate,
  handleReset,
  setSearchTerm,
  startDate,
  endDate,
  transformedStartDate,
  transformedEndDate,
}) => {
  return (
    <div className={styles.actionsNavbar}>
      <span>
        <input
          className={styles.inputSearch}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Buscar por CUPS, Tipo, Tarifa... "
          type="search"
        />
        <AiOutlineSearch className={styles.searchIcon} />
      </span>
      <ButtonsContainer
        transformedStartDate={transformedStartDate}
        transformedEndDate={transformedEndDate}
        startDate={startDate}
        endDate={endDate}
      />
      <div className={styles.searchDateContainer}>
        <input
          className={styles.inputDate}
          type="date"
          id="startDate"
          value={startDate || ""}
          onChange={(e) => setStartDateAndEndDate(e.target.value)}
        />
        <span style={{ padding: "0px 10px" }}> - </span>
        <input
          className={styles.inputDate}
          type="date"
          id="endDate"
          value={endDate || ""}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button className={styles.btnDate} onClick={handleReset}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default ActionsNavbar;
