import React, { useState, useRef } from "react";
import { FaCircleInfo } from "react-icons/fa6";

import axios from "axios";
import FilesMap from "./FilesMap";
import {
  UploadInput,
  Label,
  P,
  Form,
  MainButton,
  SubmitButton,
  Container,
} from "../../../styles/individualComponents/add_form";
import useCustomModal from "../../../hooks/useCustomModal";
import { ModalError } from "./ModalError";
import { ModalInfo } from "./ModalInfo";
import toast, { Toaster } from "react-hot-toast";

const Ahorro = ({env_color}) => {
  const [file, setfile] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const handleAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const ErrorModal = useCustomModal();
  const InfoModal = useCustomModal();

function postData(data) {
  // Apply the overlay
  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.background = 'rgba(0, 0, 0, 0.5)'; // Adjust the transparency as needed
  overlay.style.zIndex = '1031';
  document.body.appendChild(overlay);

  const loadingToastId = toast.loading("Procesando factura...", {
    style: {
      background: env_color, 
      color: '#fff',
      borderRadius: '8px', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      fontWeight: 'bold',
      marginTop: '40vh',
      zIndex: '10032',
    },
  });

  axios
    .post("/api/v2/parse-energy-invoice", data)
    .then((res) => {
      window.location.href = `/energy_invoices/${res.data.idRegistro}`;
    })
    .catch((error) => {
      document.body.removeChild(overlay);
      toast.dismiss(loadingToastId);
      ErrorModal.openModal();
      setErrorInfo(error);
      setfile([]);
    });
}


  const handleSubmit = (e) => {
    e.preventDefault();
    if (file.length > 5) {
      alert("El número de archivos no puede ser mayor a 5!");
    } else if (file.length == 0) {
      window.location.href = `/energy_invoices/new/electric_2x_invoice`;
    } else {
      let form = new FormData();
      let fileCounter = 1;
      file.forEach((element) => {
        form.append(`subidaFichero${fileCounter}`, element, element.name);
        fileCounter++;
      });

      form.append("extractContractInfo", "no");
      form.append("extractSavings", "no");
      form.append("contactPerson", "null");
      form.append("phoneNumber", "null");

      postData(form);
    }
  };

  const saveFile = (e) => {
    const FilesArr = Array.from(e.target.files);
    const filesAdmited = [];
    FilesArr.map((archivo) => {
      if (archivo.size > 5000000) {
        alert("El tamaño del archivo no puede ser mayor a 5MB");
        return;
      }
      if (
        archivo.type !== "application/pdf" &&
        archivo.type !== "image/png" &&
        archivo.type !== "image/jpeg" &&
        archivo.type !== "image/webp"
      ) {
        alert("El archivo debe ser un PDF o una imagen");
        return;
      }
      filesAdmited.push(archivo);
    });
    if (filesAdmited.length != 0) {
      setfile([...file, ...filesAdmited]);
    }
    e.target.value = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    setIsDragging(false);
  };
  return (
    <Container>
      <Toaster />
      {ErrorModal.showModal ? (
        <ModalError errorInfo={errorInfo} closeModal={ErrorModal.closeModal} />
      ) : null}
      {InfoModal.showModal ? (
        <ModalInfo closeModal={InfoModal.closeModal} />
      ) : null}
      <Form
        env_color={env_color}
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onSubmit={(e) => handleSubmit(e)}

      >
        <P env_color={env_color}>
          Paso 1: Adjunta tu factura o arrastra los ficheros{" "}
          <FaCircleInfo
            color={env_color}
            size={26}
            cursor="pointer"
            onClick={(e) => InfoModal.openModal()}
            style={{ marginBottom: "5px", marginLeft: "10px" }}
          />
        </P>
        <MainButton env_color={env_color} onClick={handleAttachClick} type="button">
          ADJUNTAR FACTURA
        </MainButton>
        <FilesMap setfile={setfile} files={file} />
        <SubmitButton type="submit">PROCESAR</SubmitButton>
        <Label>
          <UploadInput
            ref={fileInputRef}
            accept=".pdf, .jpg, .jpeg, .png, .webp"
            onChange={(e) => saveFile(e)}
            type="file"
            multiple
          />
        </Label>
      </Form>
    </Container>
  );
};

export default Ahorro;
