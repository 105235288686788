import React, { useState } from "react";
import styles from "./formStyles.module.css";
import { useFormik } from 'formik';
import {validationSchema} from "../validations/validationSchema"
import { findValueMatch } from "../utils/utils";

import { AiOutlineInfoCircle } from "react-icons/ai";

//components
import Input from "../../../reactComponents/input/Input";
import Select from "../../../reactComponents/select/Select";
import Modal from "../../../reactComponents/modal/Modal";
import TextArea from "../../../reactComponents/textArea/TextArea";

const planesTarifYComparativas = ({
  organization,
  ratePlans,
  selectedRatePlans,
  benefits,
  selectedBenefits,
}) => {
  const formik = useFormik({
    initialValues: {
      organization: {
        min_annual_savings: organization.min_annual_savings,
      },
    },
    validationSchema: validationSchema,
  });

  //modals
  const [showPlanTarYCompModal, setShowPlanTarYCompModal] = useState(false);

  const planTarifYComparativaData = [
    {
      header: "",
      text: "Permite cargar tantos planes tarifarios como existan en la comercializadora destino.",
    },
  ];

  const ratePlansData = ratePlans.map((item) => ({
    id: item.id,
    name: item.rate_plan_name + " | " + item.code + " | " + item.plan_description,
  }));

  const ratePlansOrgLevel = [
    { name: "Por Organización del Proceso", id: "organization" },
    { name: "Por Campaña", id: "campaign" },
    { name: "Por Organización del Usuario", id: "user_organization" },
    { name: "Planes Asociados al Usuario", id: "user"}
  ];

  //textAreas

  const [planesLuz, setPlanesLuz] = useState(
    organization.filter_luz_rate_plans_code
  );

  const [planesGas, setPlanesGas] = useState(
    organization.filter_gas_rate_plans_code
  );
  const [planesDual, setPlanesDualGas] = useState(
    organization.filter_dual_rate_plans_code
  );
  const [logicDiscountServices, setlogicDiscountServices] = useState(
    organization.logic_discount_services
  );

  const handlePlanesLuzChange = (e) => {
    setPlanesLuz(e.target.value);
  };
  const handlePlanesGasChange = (e) => {
    setPlanesGas(e.target.value);
  };
  const handlePlanesDualChange = (e) => {
    setPlanesDualGas(e.target.value);
  };

  const ratePlansOrgPlaceholder = findValueMatch(
    ratePlansOrgLevel,
    organization.rate_plans_org_level
  );

  const logicDiscountServicesData = [
    {name:"Aplicar el Descuento mayor (máximo)",id:"max_discount"},
    {name:"Sumar los Descuentos", id:"sum_discounts"},
  ];

  const logicDiscountServicesPlaceholder = findValueMatch(
    logicDiscountServicesData,
    organization.logic_discount_services
  );

  return (
    <>
      {showPlanTarYCompModal && (
        <Modal
          data={planTarifYComparativaData}
          onClose={() => setShowPlanTarYCompModal(false)}
        />
      )}
      <h3 className={styles.title}>
        Planes Tarifarios y Comparativas
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowPlanTarYCompModal(true)}
        />
      </h3>
      <Input
        type="text"
        name="organization[min_annual_savings]"
        id="organization_min_annual_savings"
        placeholder={organization.min_annual_savings}
        value={formik.values.organization?.min_annual_savings}
        onChange={formik.handleChange}
        label="Ahorro Mínimo Anual"
        error={formik.errors.organization?.min_annual_savings}  
        touched={formik.touched.organization?.social_bond_financing_value}
      />
      <Select
        multiSelect={true}
        searchBar={true}
        name="Nivel de selección del tarifario:"
        data={ratePlansOrgLevel}
        placeholder={ratePlansOrgPlaceholder}
        dataSelected={organization.rate_plans_org_level}
        selectId="organization_rate_plans_org_level_"
        selectName="organization[rate_plans_org_level][]"

      />
      <Select
        multiSelect={true}
        searchBar={true}
        name="Planes tarifarios"
        data={ratePlansData}
        dataSelected={selectedRatePlans}
        selectId="organization_rate_plan_ids"
        selectName="organization[rate_plan_ids][]"

      />

      <Select
        multiSelect={false}
        searchBar={true}
        name="Lógica Acumulación Descuentos de Servicios"
        data={logicDiscountServicesData}
        dataSelected={organization.logic_discount_services}
        selectId="organization_logic_discount_services"
        selectName="organization[logic_discount_services]"
        placeholder={logicDiscountServicesPlaceholder}
      />

      <TextArea
        labelText="Lógica filtrado Planes Luz 2.0TD"
        name="organization[filter_luz_rate_plans_code]"
        id="organization_filter_luz_rate_plans_code"
        smallText='Manejar la variable "rate_plans" y la variable self (energy_invoice)'
        defaultValue={planesLuz}
        onChange={(e) => handlePlanesLuzChange(e)}
        labelClassName={styles.headerColor}
    
      />
      <TextArea
        labelText="Lógica filtrado Planes Gas"
        name="organization[filter_gas_rate_plans_code]"
        id="organization_filter_gas_rate_plans_code"
        smallText='Manejar la variable "rate_plans" y la variable self (energy_invoice)'
        defaultValue={planesGas}
        onChange={(e) => handlePlanesGasChange(e)}
        labelClassName={styles.headerColor}

      />
      <TextArea
        labelText="Lógica filtrado Planes DUAL"
        name="organization[filter_dual_rate_plans_code]"
        id="organization_filter_dual_rate_plans_code"
        smallText='Manejar la variable "rate_plans" y la variable self (energy_invoice)'
        defaultValue={planesDual}
        onChange={(e) => handlePlanesDualChange(e)}
        labelClassName={styles.headerColor}

      />

      <Select
        multiSelect={true}
        searchBar={true}
        name="Beneficios cliente"
        data={benefits}
        dataSelected={selectedBenefits}
        placeholder={selectedBenefits}
        selectId="organization_benefit_ids"
        selectName="organization[benefit_ids][]"
    
      />
    </>
  );
};

export default planesTarifYComparativas;
