import React from 'react';
import { Text } from "../../../styles/individualComponents/AddTelcoProduct";

const renderProductDetails = (selectedProduct) => {
  if (!selectedProduct) {
    return null;
  }
  let productDetails;
  switch (selectedProduct.type) {
    case 'Movil':
        productDetails = (
        <div>
          {selectedProduct.isDataUnlimited !== undefined && (
            <Text>
              <strong>Datos:</strong>
              {selectedProduct.isDataUnlimited ? (
                <span> Ilimitados</span>
              ) : (
                <span> {selectedProduct.numGb} GB</span>
              )}
            </Text>
          )}
          {selectedProduct.mobileDataGeneration && (
            <Text>
              <strong>Tecnología:</strong> {selectedProduct.mobileDataGeneration.toUpperCase()}
            </Text>
          )}
          {selectedProduct.isDataShared !== undefined && (
            <Text>
              <strong>Datos compartidos:</strong> {selectedProduct.isDataShared ? 'Sí' : 'No'}
            </Text>
          )}
          {selectedProduct.mobileIsMinsNationalsUnlimited !== undefined && (
            <Text>
              <strong>Llamadas Nacionales:</strong>
              {selectedProduct.mobileIsMinsNationalsUnlimited ? (
                <span> Minutos ilimitados</span>
              ) : (
                <span> {selectedProduct.mobileNumMinNationals} minutos</span>
              )}
            </Text>
          )}
          {selectedProduct.mobileIsMinsInternationalsUnlimited !== undefined && (
            <Text>
              <strong>Llamadas Internacionales:</strong>
              {selectedProduct.mobileIsMinsInternationalsUnlimited ? (
                <span> Minutos ilimitados</span>
              ) : (
                <span> {selectedProduct.mobileNumMinInternationals} minutos</span>
              )}
            </Text>
          )}
          {selectedProduct.isTextNationalsUnlimited !== undefined && (
            <Text>
              <strong>Mensajes Nacionales:</strong>
              {selectedProduct.isTextNationalsUnlimited ? (
                <span> SMSs ilimitados</span>
              ) : (
                <span> {selectedProduct.numSmsNationals} SMSs</span>
              )}
            </Text>
          )}
          {selectedProduct.isTextInternationalsUnlimited !== undefined && (
            <Text>
              <strong>Mensajes Internacionales:</strong>
              {selectedProduct.isTextInternationalsUnlimited ? (
                <span> SMSs ilimitados</span>
              ) : (
                <span> {selectedProduct.numSmsIntenationals} SMSs</span>
              )}
            </Text>
          )}
          {selectedProduct.roaming && selectedProduct.roaming.length > 0 && !selectedProduct.roaming.includes('No') && (
            <Text>
              <strong>Tipo de Roaming:</strong> {selectedProduct.roaming.join(', ')}
            </Text>
          )}
        </div>
      );
      break;

    case 'Fijo':
        productDetails = (
        <div>
          {selectedProduct.landlineIsMinsNationalsUnlimited !== undefined && (
            <Text>
              <strong>Llamadas Nacionales:</strong>
              {selectedProduct.landlineIsMinsNationalsUnlimited ? (
                <span> Minutos ilimitados</span>
              ) : (
                selectedProduct.landlineNumMinNationals !== null && (
                  <span> {selectedProduct.landlineNumMinNationals} minutos</span>
                )
              )}
            </Text>
          )}
          {selectedProduct.landlineIsMinsInternationalsUnlimited !== undefined && (
            <Text>
              <strong>Llamadas Internacionales:</strong>
              {selectedProduct.landlineIsMinsInternationalsUnlimited ? (
                <span> Minutos ilimitados</span>
              ) : (
                selectedProduct.landlineNumMinInternationals !== null && (
                  <span> {selectedProduct.landlineNumMinInternationals} minutos</span>
                )
              )}
            </Text>
          )}
        </div>
      );
      break;

    case 'Fibra':
        productDetails = (
        <div>
          {selectedProduct.fibreSpeed !== undefined && (
            <Text>
              <strong>Velocidad de fibra:</strong> {selectedProduct.fibreSpeed} Mbps
            </Text>
          )}
          {selectedProduct.fibreType !== undefined && selectedProduct.fibreType !== null && (
            <Text>
              <strong>Tipo de conexión:</strong> {selectedProduct.fibreType}
            </Text>
          )}
        </div>
      );
      break;
    
    default:
        productDetails = null;
  }

  return productDetails;
};

export default renderProductDetails;
