// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".modal-module__modalContainer___33rBp {\n    width: 100%;\n    height: 100%;\n    background-color: rgba(117, 117, 117, 0.549);\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 2;\n\n\n}\n\n.modal-module__modalContent___Oxw7e {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -webkit-transform: translate(-50%, -50%);\n            transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 20px;\n    text-align: center;\n    padding: 30px;\n    border-radius: 20px;\n\n\n  }\n\n.modal-module__button___34rvc {\n    margin-top: 20px;\n    border: solid 1px #00DBFF;\n    background-color: rgba(157,242,255,0.59216);\n    color: #00DBFF;\n    border-radius: 2px;\n    padding: 2px 10px;\n  }", "",{"version":3,"sources":["modal.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,4CAA4C;IAC5C,eAAe;IACf,MAAM;IACN,OAAO;IACP,UAAU;;;AAGd;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,wCAAgC;YAAhC,gCAAgC;IAChC,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,mBAAmB;;;EAGrB;;AAEA;IACE,gBAAgB;IAChB,yBAAyB;IACzB,2CAA2B;IAC3B,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB","file":"modal.module.css","sourcesContent":[".modalContainer {\n    width: 100%;\n    height: 100%;\n    background-color: rgba(117, 117, 117, 0.549);\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 2;\n\n\n}\n\n.modalContent {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 20px;\n    text-align: center;\n    padding: 30px;\n    border-radius: 20px;\n\n\n  }\n\n  .button {\n    margin-top: 20px;\n    border: solid 1px #00DBFF;\n    background-color: #9df2ff97;\n    color: #00DBFF;\n    border-radius: 2px;\n    padding: 2px 10px;\n  }"]}]);
// Exports
exports.locals = {
	"modalContainer": "modal-module__modalContainer___33rBp",
	"modalContent": "modal-module__modalContent___Oxw7e",
	"button": "modal-module__button___34rvc"
};
module.exports = exports;
