import { useState, useEffect } from "react";
import axios from "axios";

const useTableData = ({ url, initialFilter }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({ ...initialFilter });
  const [markedRecords, setMarkedRecords] = useState([]);
  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);
  useEffect(() => {
    fetchData();
  }, [currentPage, filters]);

  const fetchData = async () => {
    try {
      const response = await axios.get(url + currentPage, {
        params: filters,
      });
      setData(response.data);
    } catch (error) {
      if (error.response.status == 404) {
        setData(null);
      }
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const setPage = (page) => {
    setCurrentPage(page);
  };

  const markRecord = (id) => {
    const index = markedRecords.indexOf(id);
    if (index === -1) {
      setMarkedRecords([...markedRecords, id]);
    } else {
      setMarkedRecords(markedRecords.filter((record) => record !== id));
    }
  };

  const markAllRecords = () => {
    let ids = [];
    data.forEach((element) => {
      const index = markedRecords.indexOf(element.id);
      if (index === -1) {
        ids.push(element.id);
      }
    });
    setMarkedRecords([...markedRecords, ...ids]);
  };

  const unMarkAllRecords = () => {
    setMarkedRecords([]);
  };

  return {
    data,
    fetchData,
    currentPage,
    nextPage,
    prevPage,
    setPage,
    filters,
    setFilters,
    markedRecords,
    markRecord,
    markAllRecords,
    unMarkAllRecords,
  };
};

export default useTableData;
