import * as Yup from 'yup';
import DOMPurify from 'dompurify';


// Function to preserve ERB tags during sanitization
const sanitizeWithERB = (input) => {
  if (typeof input !== 'string') {
    return input; // Return the input as is if it's not a string
  }
  // Preserve ERB tags by replacing them with placeholders
  const placeholder = '%%ERB_TAG%%';
  const erbTagPattern = /(<%=.*?%>)/g;
  const placeholders = [];
  let sanitizedInput = input.replace(erbTagPattern, (match) => {
    placeholders.push(match);
    return placeholder;
  });

  // Sanitize the input without ERB tags
  sanitizedInput = DOMPurify.sanitize(sanitizedInput);

  // Restore ERB tags
  placeholders.forEach((erbTag) => {
    sanitizedInput = sanitizedInput.replace(placeholder, erbTag);
  });

  return sanitizedInput;
};

// Function to check if input is safe
const isSafeContent = (value) => {
  const sanitizedValue = sanitizeWithERB(value);
  return sanitizedValue === value;
};

// Function that checks is string contains some chars
const has_special_chars = (value) => {
  const specialCharacters = ["'", '"', '`', '´'];
  if (value) {
    return !specialCharacters.some(char => value.includes(char));
  }
  return true;
};

export const validationSchema = Yup.object({
  organization: Yup.object({
    name: Yup.string()
      .required('Nombre es obligatorio')
      .matches(/^[\w|ó|á|é|í|ó|ú|\s|\-]+$/, 'Nombre solo puede contener letras, números y los siguiente simbolos - y _'),
    code: Yup.string()
      .required('Código es obligatorio')
      .matches(/^[^\d]+$/, 'Código solo puede contener letras y signos, sin números'),
    phone: Yup.string()
      .required('Número de teléfono es obligatorio')
      .test('phone-validation', 'Formato de teléfono inválido', function (value) {
        if (!value) {
          return false; // Required validation is handled separately
        }
        const isNineDigitsStartingWith679 = /^[679][0-9]{8}$/.test(value);
        const isNineDigitsWith0034 = /^(0034)[679][0-9]{8}$/.test(value);
        const isThirteenDigitsWith0034 = /^(0034)[679][0-9]{8}$/.test(value);
        return isNineDigitsStartingWith679 || isNineDigitsWith0034 || isThirteenDigitsWith0034;
      })
      .test('phone-message', 'El número de teléfono debe empezar con 6, 7 u 9 y tener 9 dígitos, o empezar con "0034" seguido de 6, 7 u 9 y tener 9 dígitos adicionales, o ser un total de 13 dígitos empezando con "0034"', function (value) {
        if (!value) {
          return false; // Required validation is handled separately
        }
        const isNineDigitsStartingWith679 = /^[679][0-9]{8}$/.test(value);
        const isNineDigitsWith0034 = /^(0034)[679][0-9]{8}$/.test(value);
        const isThirteenDigitsWith0034 = /^(0034)[679][0-9]{8}$/.test(value);
        if (!isNineDigitsStartingWith679 && !isNineDigitsWith0034 && !isThirteenDigitsWith0034) {
          return false;
        }

        if (isNineDigitsStartingWith679 || isNineDigitsWith0034) {
          return true; // No need to check further for these cases
        }

        // Corrected regular expression for thirteen digits with "0034"
        return isThirteenDigitsWith0034;
      }),
  
      process_limit: Yup.number() 
      .nullable()
      .typeError('Límite de procesamiento debe ser un número')
      .integer('El límite de proceso debe ser un número entero')
      .positive('El límite de proceso debe ser un número positivo'),
      social_bond_financing_value: Yup.number()
      .typeError('El valor de financiación del bono social debe ser un número')
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(0.1, 'El valor debe ser menor o igual a 0.1')
      .test(
        'no-negative-zero',
        'El valor no puede ser -0',
        value => !(value === 0 && 1 / value === -Infinity)
      )
      .test({
        name: 'max-decimals',
        message: 'El valor debe tener no más de 12 decimales',
        test: function (value) {
          if (value === undefined || value === null) {
            // If the value is undefined or null, the validation is considered successful.
            return true;
          }

          // Check if the number of decimals is more than 12
          const decimalCount = (value.toString().split('.')[1] || []).length;
          return decimalCount <= 12;
        },
      }),
      
      recalculate_import_ie: Yup.number()
      .typeError('IE para recálculo de importes debe ser un número')
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(100, 'El valor debe ser menor o igual a 100')
      .test({
        name: 'max-decimals',
        message: 'El valor debe tener no más de 12 decimales',
        test: function (value) {
          if (value === undefined || value === null) {
            // If the value is undefined or null, the validation is considered successful.
            return true;
          }

          // Check if the number of decimals is more than 12
          const decimalCount = (value.toString().split('.')[1] || []).length;
          return decimalCount <= 12;
        },
      })
      .test(
        'no-negative-zero',
        'El valor no puede ser -0',
        value => !(value === 0 && 1 / value === -Infinity)
      ),
      variance_total_luz: Yup.number()
      .required('La variación total de luz es obligatoria')
      .typeError('La variación total de luz debe ser un número')
      .integer('La variación total de luz debe ser un número entero') 
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(100, 'El valor debe ser menor o igual a 100'),
      variance_total_gas: Yup.number()
      .required('La variación total de gas es obligatoria')
      .typeError('La variación total de gas debe ser un número')
      .integer('La variación total de gas debe ser un número entero') 
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(100, 'El valor debe ser menor o igual a 100'),
      variance_total_luz_pyme: Yup.number()
      .required('La variación total de luz 3.0TD es obligatoria')
      .typeError('La variación total de luz 3.0TD debe ser un número')
      .integer('La variación total de luz 3.0TD  debe ser un número entero') 
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(100, 'El valor debe ser menor o igual a 100'),
      cut_date_luz: Yup.date()
      .nullable()
      .typeError('Fecha de corte luz 2x debe ser una fecha válida')
      .max(new Date(), 'No se permiten fechas futuras'),
      cut_date_luz_3x: Yup.date()
      .nullable()
      .typeError('Fecha de corte luz 3x debe ser una fecha válida')
      .max(new Date(), 'No se permiten fechas futuras'),
      cut_date_gas: Yup.date()
      .nullable()
      .typeError('Fecha de corte gas debe ser una fecha válida')
      .max(new Date(), 'No se permiten fechas futuras'), 
      default_p1_supplied: Yup.number()
      .nullable()
      .typeError('Potencia P1 debe ser un número')
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(15, 'El valor debe ser menor o igual a 15'),
      default_p2_supplied: Yup.number()
      .nullable()
      .typeError('Potencia P2 debe ser un número')
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(15, 'El valor debe ser menor o igual a 15'),
      default_invoiced_days: Yup.number()
      .nullable()
      .typeError('Nº días debe ser un número')
      .min(0, 'El valor debe ser mayor o igual a 0')
      .max(365, 'El valor debe ser menor o igual a 365'),
      min_annual_savings: Yup.number()
      .typeError('Ahorro mínimo anual debe ser un número'),
      test: Yup.number()
      .typeError('Nº días debe ser un número'),
      r_plan_info_display_fmt_svgs: Yup.string()
      .required('Formato de visualización de datos de las tarifas obligatorio')
      .test('no-special-chars', 'No puede contener comillas de ningún tipo', function(value) {
        const specialCharacters = ["'", '"', '`', '´'];
        if (value) {
          return !specialCharacters.some(char => value.includes(char));
        }
        return true;
      })
      .test('xss-safe', 'Datos inválidos: Contenido potencialmente dañino detectado', (value) => {
        return isSafeContent(value);
      })
      .matches(
        /(<%=\s?object\.[\w\s\.\+\-\*\/%]+\s?%>)/,
        'Debe contener al menos un placeholder <%=object.field%> o <%=object.related_model.field%> (object es obligatorio y representa un objeto saving_calculation)'
      ),
      r_plan_info_display_fmt_inform_pr: Yup.string()
      .required('Formato de visualización de datos en informar precios obligatorio')
      .test('no-special-chars', 'No puede contener comillas de ningún tipo', function(value) {
        const specialCharacters = ["'", '"', '`', '´'];
        if (value) {
          return !specialCharacters.some(char => value.includes(char));
        }
        return true;
      })
      .test('xss-safe', 'Datos inválidos: Contenido potencialmente dañino detectado', (value) => {
        return isSafeContent(value);
      })
      .matches(
        /(<%=\s?object\.[\w\s\.\+\-\*\/%]+\s?%>)/, 
        'Debe contener al menos un placeholder <%=object.field%> o <%=object.related_model.field%> (object es obligatorio y representa un objeto rate_plan)',
      ),
      password_expiration_time: Yup.string()
      .matches(/\d+\.(hours|months|weeks|years|days)/, "Debe ser un formato válido: 'XX.[hours, days, weeks, months, years]'."),
      min_adjusted_rate_plan_price_range: Yup.number()
      .nullable()
      .typeError('El rango de precio mínimo debe ser un número')
      .min(-1, 'El valor del rango de precio mínimo debe ser mayor o igual a -1')
      .max(1, 'El valor del rango de precio mínimo debe ser menor o igual a 1')
      .required('El rango de precio mínimo es obligatorio'),

      max_adjusted_rate_plan_price_range: Yup.number()
      .nullable()
      .typeError('El rango de precio máximo debe ser un número')
      .min(-1, 'El valor del rango de precio máximo debe ser mayor o igual a -1')
      .max(1, 'El valor del rango de precio máximo debe ser menor o igual a 1')
      .required('El rango de precio máximo es obligatorio')
      .test('max-greater-than-min', 'El rango de precio máximo debe ser mayor o igual que el rango de precio mínimo', function(value) {
        const { min_adjusted_rate_plan_price_range } = this.parent;
        if (min_adjusted_rate_plan_price_range !== undefined && value !== undefined) {
          return value >= min_adjusted_rate_plan_price_range;
        }
        return true;
      }),
      show_e_inv_not_older_than: Yup.string()
      .nullable()
      .matches(
        /^\d+\.(h|d|w|mo|y)$/, 
        'Debe seguir el formato X.[h|d|w|mo|y], donde X es un número no negativo'
      ),
      r_plan_info_display_fmt_invoice_sims: Yup.string()
      .required('Formato de visualización de datos en simulaciones de facturas obligatorio')
      .test('no-special-chars', 'No puede contener comillas de ningún tipo', (value) => {
        return has_special_chars(value);
      })
      .test('xss-safe', 'Datos inválidos: Contenido potencialmente dañino detectado', (value) => {
        return isSafeContent(value);
      })
      .matches(
        /(<%=\s?object\.[\w\s\.\+\-\*\/%]+\s?%>)/, 
        'Debe contener al menos un placeholder <%=object.field%> o <%=object.related_model.field%> (object es obligatorio y representa un objeto saving_calculation)'
      ),
  }), 
});
