import * as Yup from "yup";

export const validationSchema = Yup.object({
  commission: Yup.object({
    commission_name: Yup.string().required("Nombre de comisión es obligatorio"),
    code: Yup.string().required("Código de comisión es obligatorio"),
    cost: Yup.number().required("Coste de comisión es obligatorio"),
    range_start: Yup.string().required("Los rangos no pueden estar vacíos").matches(
      /^[0-9]+$/,"Solamente puede introducir dígitos"
    )
    ,
    range_end: Yup.string().required("Los rangos no pueden estar vacíos").matches(
      /^[0-9]+$/,"Solamente puede introducir dígitos"
    )
    ,
    range_cost: Yup.string().required("Coste para rangos es obligatorio").matches(
      /^\d+(\.\d+)?$/,"Solamente puede introducir decimales o enteros"
    ),
  }),
});
