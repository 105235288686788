export const transformFromOptionsValues = (htmlData) => {
    const regex = /<option value="([^"]+)">([^<]+)<\/option>/g;
  
    return Array.from(htmlData.matchAll(regex), ([, value, title]) => ({
      id: value,
      name: title,
    }));
  };

export const findValueMatch = (array, valuePlaceholder) => {
    let matchingName = null;

    array.forEach((option) => {
      if (option.id === valuePlaceholder) {
        valuePlaceholder = option.id;
        matchingName = option.name;
      }
    });
    return matchingName;
  };

  export const filterArrayByEmptyValues = (array) => {
    return array.filter(item => item !== "");
  }