import React, {useState} from "react";
import { SideBarContainer, SideBarHeader } from "../../../../styles/rate_plans";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export const SideBar = ({ sideBarState, setSideBarState, fetchData }) => {
  if (sideBarState.client == null) return null;
  
  const eliminar = async () => {
    try {
      await axios.delete(`/admin/clients/${sideBarState.client.id}`, {
        headers: {
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
      });
      toast.success(<div>Cliente eliminado con éxito</div>);
      fetchData();
      sideBarState.show = false
    } catch (error) {
      toast.error(<div>No se pudo eliminar el cliente solicitado</div>);
    }
  };

  const changeOpportunityStatus = async(id) => {
    try {
      await axios.post(
        `/api/v2/opportunities/${id}/change_status`,
        {
          status: 'cancelled_client_lost'
        },
        {
          headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        }
      );
     
      toast.success(<div>Opportunidad cerrada</div>);
      fetchData();
      window.location.reload();
    } catch (error) {
      toast.error(<div>No se pudo cerrar la oportunidad</div>);
    }
  }

  const changeContractStatus = async(id) => {
    try {
      await axios.post(
        `/api/v2/contracts/${id}/change_status`,
        {
          status: 'rejected'
        },
        {
          headers: {
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')
              .content,
          },
        }
      );
     
      toast.success(<div>Contrato cerrado</div>);
      fetchData();
      window.location.reload();
    } catch (error) {
      toast.error(<div>No se pudo cerrar el contrato</div>);
    }
  }

  const statusIconClasses = {
    //Contract status
    pending: "fa fa-circle text-warning",
    active: "fa fa-circle text-success",
    finished: "fa fa-circle text-danger",
    rejected: "fa fa-circle text-danger",
    //Opportunity status
    processed: "fa fa-circle text-success",
    saving_calculation_created:"fa fa-circle text-warning",
    sent: "fa fa-circle text-success",
    pending_call: "fa fa-circle text-warning",
    closed_client_acquired: "fa fa-circle text-warning",
    cancelled_client_lost:"fa fa-circle text-danger",
    invoice_request_sent:"fa fa-circle text-warning",
    default: "fa fa-circle text-secondary"
  };

  function contractStatusLabel(status) {
    const className = statusIconClasses[status] || statusIconClasses.default;
    return <i className={className}></i>;
  }

  const SectionBox = (props) => {
    const {objects, type, code, title, action } = props
    return <div style={styles.sectionContainer}>
      {objects?.map(obj =>(
        <div style={styles.sectionSubContainer}>
          <p>{title} ({obj[code]})</p>
          <div style={{flexDirection: 'row', display:'flex', justifyContent:'center', alignItems:'center', marginTop: -10}}>
            <div key={obj.id} style={styles.sectionBox}>
              <p style={{margin: 0, fontSize: '14px'}}>{contractStatusLabel(obj.status)} {obj.cups? obj.cups : obj.code}</p>    
              <a
                href={`/admin/${type}/${obj.id}/${action === "edit" ? 'edit' : ''}`}
                style={{cursor: "pointer"}}>
                <i className={`far fa-${action === "show" ? 'eye' : 'edit'}`} aria-hidden="true"></i>
              </a>
            </div>

            <button
              onClick={() => type === "opportunities" ? changeOpportunityStatus(obj.id) : changeContractStatus(obj.id)}
              style={{cursor: "pointer",  border: 'none', background: 'none', }}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      ))}
    </div>
  }

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const {client} = sideBarState
  
  const invoiceRequests = client?.opportunities.filter(op => op.status === "invoice_request_sent") || [];
  const visibleRequests = showMore ? invoiceRequests : invoiceRequests.slice(0, 1);


  return (
    <SideBarContainer isVisible={sideBarState.show} style={{ padding:0 , justifyContent: 'flex-start'}}>
      <div style={styles.header}>
        <h3 style={styles.headerText}>{sideBarState.client.name}</h3>
        <button
          style={styles.headerIcon}
          onClick={() => setSideBarState({ ...sideBarState, show: false })}>
          <IoClose size={30} />
        </button>
      </div>
      <div style={{height: "96%"}}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "10px 5px",
          }}
        >
          <SectionTitle name="Contratos asociados" size={client?.contracts?.length} />
          <SectionBox 
            title="Contrato vinculado" 
            objects={client?.contracts} 
            type="contracts" 
            code="cups"
            action="edit"  
          />
         
          <SectionTitle name="Ofertas tramitadas" size={client?.opportunities.filter(op => op.status != "invoice_request_sent")?.length} />
          <SectionBox 
            title="Oferta tramitada" 
            objects={client?.opportunities.filter(op => op.status != "invoice_request_sent")} 
            type="opportunities" 
            code="code"
            action="show"
          />
          <SectionTitle 
            name="Ofertas enviadas"
            size={invoiceRequests?.length} />
          <div style={{maxHeight: showMore ? 'none' : '100', overflowY: showMore ? 'visible' : 'hidden'}}>
            <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
              <SectionBox 
                title="Oferta enviada" 
                objects={visibleRequests} 
                type="opportunities" 
                code="code"
                action="show"
              />
              {invoiceRequests.length > 1 && (
                <div onClick={toggleShowMore} style={{ textAlign: 'center', cursor: 'pointer', marginTop: '10px', color: 'blue', textDecoration: 'underline' }}>
                  {showMore ? 'Ver menos' : 'Ver más'}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 15px",
          }}
        >
          <a
            href={`/admin/clients/${sideBarState.client.id}/edit`}
            style={{
              border: "none",
              borderRadius: "5px",
              backgroundColor: "#21103d",
              padding: "5px 15px",
              color: "#f5f5f5",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            Editar
          </a>
          <button
            style={{
              border: "none",
              backgroundColor: "#21103d",
              padding: "5px 15px",
              color: "#f5f5f5",
              borderRadius: "5px",
            }}
            onClick={eliminar}
          >
            Eliminar
          </button>
      </div>
    </SideBarContainer>
  );
};

const SectionTitle = ({name,size}) => (
  <div style={styles.sectionTitle}>
    <h5>{name} ({size})</h5>{" "}
  </div>
)

const styles = {
  header: {
    display: "flex", 
    width: "100%",
    alignItems: "center",
    backgroundColor:'#21103d',
    justifyContent:'space-between',
    padding: '0 15px',
    height: 70
  },
  headerText: {
    marginTop: "5px", 
    color: '#ffffff' 
  },
  headerIcon:{
    border: "none",
    backgroundColor: "transparent",
    color: "#ffffff",
    fontSize: "20px", 
  },
  sectionTitle:{
    width:"100%",  
    borderBottom: "1px solid #21103d", 
    alignContent: 'center',
    marginTop: 15
  },
  sectionContainer: {
    height: "90%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  sectionSubContainer:{
    width: "100%",
    justifyContent: "center",
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 10
  },
  sectionBox:{
    height: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    border: "1px solid #21103d",
    borderRadius: 10, 
    padding: 1,
    width: "100%"
  }
};
