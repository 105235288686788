import React from "react";

import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";

export const Pagination = ({
  nextPage,
  prevPage,
  sideBarState,
  data,
  currentPage,
}) => {
  return (
    <div
      style={
        sideBarState.show
          ? {
              position: "absolute",
              transition: "all 0.4s ease-in-out",
              bottom: "0px",
              right: "calc(3% + 300px)",
              display: "flex",
            }
          : {
              position: "absolute",
              bottom: "0px",
              right: "3%",
              transition: "all 0.4s ease-in-out",
              display: "flex",
            }
      }
    >
      <button
        style={{
          border: "1px solid #21103d",
          borderRadius: "4px",
          color: "#f5f5f5",
          backgroundColor: "#21103d",
          padding: "0px 15px",
          marginRight: "10px",
        }}
        onClick={() => prevPage()}
      >
        <MdNavigateBefore size={25} />
      </button>
      <div
        style={{
          border: "1px solid #21103d",
          borderRadius: "4px",
          color: "#f5f5f5",
          backgroundColor: "#21103d",
          padding: "0px 10px",
          marginRight: "10px",
        }}
      >
        {currentPage}
      </div>
      <button
        style={{
          border: "1px solid #21103d",
          borderRadius: "4px",
          color: "#f5f5f5",
          backgroundColor: "#21103d",
          padding: "0px 15px",
        }}
        onClick={() => {
          if (data.length === 50) {
            nextPage();
          }
        }}
      >
        <MdNavigateNext size={25} />
      </button>
    </div>
  );
};
