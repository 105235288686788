import React, { useRef } from "react";
import styles from "./modal.module.css";

const Modal = ({ data, onClose }) => {
  const modalContentRef = useRef();

  const handleContainerClick = (e) => {
    if (modalContentRef.current && modalContentRef.current.contains(e.target)) {
      return;
    }
    onClose();
  };

  return (
    <div className={styles.modalContainer} onClick={handleContainerClick}>
      <div ref={modalContentRef} className={styles.modalContent}>
        {data.map((item) => {
          return (
            <div key={data.indexOf(item)}>
              <h6>{item.header}</h6>
              <p>{item.text}</p>
            </div>
          );
        })}

        <button onClick={onClose} className={styles.button}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
