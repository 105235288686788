// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "/* Funcionalidades */\n.checkbox-module__checkboxContainer___1sMOI {\n    display: flex;\n}\ninput[type='checkbox'] {\n    accent-color: #9df2ff;\n    width: 19px;\n}\n.checkbox-module__checkbox___1GODu {\n    /* margin-right: 10px; */\n    position: relative;\n    width: 14px;\n    height: 14px;\n}\n.checkbox-module__textCheckbox___3NsGD {\n    position: relative;\n    top: 5px;\n    font-weight: 300;\n    left: 5px;\n    font-size: 14px;\n}\n.checkbox-module__checkboxWrapper___3S_BO {\n    display: flex;\n    align-items: center; /* Align items vertically in the center */\n  }\n", "",{"version":3,"sources":["checkbox.module.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;AACjB;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,gBAAgB;IAChB,SAAS;IACT,eAAe;AACnB;AAEA;IACI,aAAa;IACb,mBAAmB,EAAE,yCAAyC;EAChE","file":"checkbox.module.css","sourcesContent":["/* Funcionalidades */\n.checkboxContainer {\n    display: flex;\n}\n\ninput[type='checkbox'] {\n    accent-color: #9df2ff;\n    width: 19px;\n}\n\n.checkbox {\n    /* margin-right: 10px; */\n    position: relative;\n    width: 14px;\n    height: 14px;\n}\n\n.textCheckbox {\n    position: relative;\n    top: 5px;\n    font-weight: 300;\n    left: 5px;\n    font-size: 14px;\n}\n  \n.checkboxWrapper {\n    display: flex;\n    align-items: center; /* Align items vertically in the center */\n  }\n"]}]);
// Exports
exports.locals = {
	"checkboxContainer": "checkbox-module__checkboxContainer___1sMOI",
	"checkbox": "checkbox-module__checkbox___1GODu",
	"textCheckbox": "checkbox-module__textCheckbox___3NsGD",
	"checkboxWrapper": "checkbox-module__checkboxWrapper___3S_BO"
};
module.exports = exports;
