import React from "react";
import styles from "./select.module.css";

const SelectOptionList = ({
  selectedOptions,
  inputValue,
  toggleSelected,
  matchedSelected,
  selectedItemString,
  toggleDiv,
  multiSelect,
}) => {
  let allItems = selectedOptions;
  let match = [];

  if (inputValue) {
    match = selectedOptions.filter((plan) =>
      plan.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (match.length === 0) {
      return <div className={styles.errorMessage}>No hay coincidencias</div>;
    } else {
      allItems = match;
    }
  }

  return allItems.map((option) => (
    <li
      onClick={(e) => {
        toggleSelected(option.id);
        if (option.name) {
          selectedItemString(option.name);
        } 
        if(option.name === null){
          selectedItemString(option.plantilla ? option.plantilla : "Ningún elemento seleccionado");
        }
        if (!multiSelect) {
          toggleDiv();
        }
      }}
      key={option.id}
      value={option.id}
      className={`${styles.listOptions} ${matchedSelected(option.id)}`}
    >
      {option.plantilla ? option.plantilla : option.name}
    </li>
  ));
};

export default SelectOptionList;
