import { useState } from "react";

const useCustomModal = (initState = false) => {
  const [showModal, setShowModal] = useState(initState);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return { showModal, openModal, closeModal };
};
export default useCustomModal;
