import axios from "axios";
export default async function calculateIndexedPrices(ratePlan, price) {
  let newPrices = await axios.get(
    `/api/v2/rate_plans/${ratePlan.id}/${price.id}`
  );

  if (ratePlan.planType === "luz_2x" || ratePlan.planType === "luz_3x") {
    ratePlan.electricPrices.map((electricPrice) => {
      if (electricPrice.id == price.id) {
        price.energy_price_p1 = newPrices.data.luz.energy_price_p1;
        price.energy_price_p2 = newPrices.data.luz.energy_price_p2;
        price.energy_price_p3 = newPrices.data.luz.energy_price_p3;
        price.energy_price_p4 = newPrices.data.luz.energy_price_p4;
        price.energy_price_p5 = newPrices.data.luz.energy_price_p5;
        price.energy_price_p6 = newPrices.data.luz.energy_price_p6;
      }
    });
  } else if (ratePlan.planType === "gas") {
    price.indexed_variable_price = newPrices.data.gas.indexed_variable_price;
  }
}
