import React, { useState } from "react";
import { Pagination } from "./Pagination";
import { Table } from "./Table";
import { Container, Title } from "../../../../styles/rate_plans";
import useTableData from "../../../../hooks/useTableData";
import { Searcher } from "./Searcher";
import { SideBar } from "./SideBar";
import { ToolBar } from "./ToolBar";
import { Exports } from "./Exports";

const Index = ({show, client}) => {
  const [sideBarState, setSideBarState] = useState({
    show: show || false,
    client: client || null,
  });
  const {
    data,
    fetchData,
    markedRecords,
    markRecord,
    unMarkAllRecords,
    filters,
    setFilters,
    nextPage,
    prevPage,
    setPage,
    currentPage,
  } = useTableData({
    url: "/admin/clients/index/",
  });
  return (
    <Container>
      <Title>Clientes</Title>
      <Searcher filters={filters} setFilters={setFilters} />
      <Exports/>
      <ToolBar
        markedRecords={markedRecords}
        unMarkAllRecords={unMarkAllRecords}
        fetchData={fetchData}
      />
      
      <SideBar sideBarState={sideBarState} setSideBarState={setSideBarState} fetchData={fetchData} />

      {data != null ? (
        <>
          <Table
            data={data}
            markedRecords={markedRecords}
            markRecord={markRecord}
            sideBarState={sideBarState}
            setSideBarState={setSideBarState}
          />

          <Pagination
            nextPage={nextPage}
            prevPage={prevPage}
            setPage={setPage}
            sideBarState={sideBarState}
            data={data}
            currentPage={currentPage}
          />
        </>
      ) : (
        <div style={{ marginLeft: "3%", marginTop: "100px" }}>
          No se encontraron coincidencias en los registros
        </div>
      )}
    </Container>
  );
};

export default Index;
