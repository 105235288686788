import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
`;

export const SubmitButton = styled.button`
  margin-top: 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: ${props => props.env_color};
  width: 260px;
  border-top: 10px;
  border-radius: 50px;
  border: 1px solid ${props => props.env_color};
  padding: 15px 30px 15px 30px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: #ffff;
    color: ${props => props.env_color};
  }
  :active {
    border: none;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  margin-top: -15px;
  color: ${props => props.env_color};
  margin-bottom: 15px;
  @media only screen and (max-width: 1000px) {
    font-size: 30px;
  }
`;
