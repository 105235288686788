import React, { useState, memo, useMemo } from "react";
import styles from "./formStyles.module.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Select from "../../../reactComponents/select/Select";
import Modal from "../../../reactComponents/modal/Modal";
import Checkbox from "../../../reactComponents/checkbox/CheckBox";
import { findValueMatch } from "../utils/utils";

const Campañas = ({ organization, campaigns, selectedCampaigneAsoToOrg }) => {
  //modal
  const [showCampañaModal, setShowCampañaModal] = useState(false);

  const campañas = useMemo(() => {
    return campaigns.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  }, [campaigns]);


  const addObjectToPostionZero = (array, id, name) => {
    const newObject = { id, name };
    const newArray = [newObject, ...array]; 
    return newArray;
  };
 
  const campañas_array = addObjectToPostionZero(campañas, null, "Sin Campaña");

  const mainCampaignPlaceholder = findValueMatch(
    campañas_array,
    organization.main_campaign_id
  );

  const memoizedSelectOrg = useMemo(
    () => (
      <Select
        multiSelect={true}
        searchBar={true}
        name="Campañas asociadas a la Organización"
        data={campañas}
        placeholder={selectedCampaigneAsoToOrg}
        dataSelected={selectedCampaigneAsoToOrg}
        selectId="organization_campaign_ids"
        selectName="organization[campaign_ids][]"

      />
    ),
    [campañas, selectedCampaigneAsoToOrg]
  );
  // Checkboxes
  const [promoLuz, setPromoLuz] = useState(organization.luz_2x_promo_enabled);
  const [promoGas, setPromoGas] = useState(organization.gas_promo_enabled);

  return (
    <>
      {showCampañaModal && (
        <Modal data={modalText} onClose={() => setShowCampañaModal(false)} />
      )}
      <h3 className={styles.title}>
        Campañas
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowCampañaModal(true)}
        />
      </h3>
      {memoizedSelectOrg}
      <div className={styles.selectsCntainer}>
        <Select
          multiSelect={false}
          searchBar={false}
          name="Campaña principal"
          data={campañas_array}
          placeholder={
            mainCampaignPlaceholder === null
              ? "Seleccione un elemento"
              : mainCampaignPlaceholder
          }
          dataSelected={organization.main_campaign_id}
          selectId="organization_main_campaign_id"
          selectName="organization[main_campaign_id]"
        />
      </div>
      <div className={styles.checkBoxContainerCamp}>
        <Checkbox
          onChange={() => setPromoLuz(!promoLuz)}
          checked={promoLuz}
          name="organization[luz_2x_promo_enabled]"
          id="organization_luz_2x_promo_enabled"
          label="Promociones Luz 2X "
        />
        <Checkbox
          onChange={() => setPromoGas(!promoGas)}
          checked={promoGas}
          name="organization[gas_promo_enabled]"
          id="organization_gas_promo_enabled"
          label="Promociones Gas"
        />
      </div>
    </>
  );
};

export default memo(Campañas);
