import React from "react";
import styles from "./input.module.css";
import { MdErrorOutline } from "react-icons/md";


const Input = (props) => {
  if (props.only_input){
    return (
      <input
        name={props.name}
        id={props.id}
        className={props.error ? styles.inputError : styles.input}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value || ''} 
        required={props.required}
        type={props.type}
        onClick={props.onClick}
        checked={props.checked}
        onBlur={props.onBlur}
      />
    );
  }
  return (
    <>
    <div>
    <label className={styles.label}>{props.label}</label>
    <div className={styles.inputDiv}>
    <input
        name={props.name}
        id={props.id}
        className={props.error ? styles.inputError : styles.input}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value || ''} 
        required={props.required}
        type={props.type}
        onClick={props.onClick}
        checked={props.checked}
        onBlur={props.onBlur}
      />
        {props.error && <MdErrorOutline className={props.type == "date" ||props.type == "number"  ? styles.iconErrorDate : styles.iconError}/>}
    </div>
      {props.touched && props.error && props.value}
    <span id={styles.error}>{props.error}</span>
    </div>

    </>
  );
};

export default Input;
