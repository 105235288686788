import React from "react";
import { Container, Title } from "../../../../styles/rate_plans";
import { Table } from "./Table";
import useTableData from "../../../../hooks/useTableData";
import { Searcher } from "./Searcher";
import { ToolBar } from "./ToolBar";
import { Exports } from "./Exports";
const Index = () => {
  const {
    data,
    fetchData,
    markedRecords,
    markRecord,
    unMarkAllRecords,
    filters,
    setFilters,
  } = useTableData({
    url: "/admin/rate_plans/index_json/",
  });

  return (
    <Container>
      <Title>Tarifario de Planes</Title>
      <Searcher
        unMarkAllRecords={unMarkAllRecords}
        filters={filters}
        setFilters={setFilters}
      />
      <Exports status={filters.status}/>
      <ToolBar
        unMarkAllRecords={unMarkAllRecords}
        markedRecords={markedRecords}
        fetchData={fetchData}
        status={filters.status}
      />
      {data != null ? (
        <Table
          data={data}
          markedRecords={markedRecords}
          markRecord={markRecord}
        />
      ) : (
        <div style={{ marginLeft: "3%" }}>
          No se encontraron coincidencias en los registros
        </div>
      )}
    </Container>
  );
};

export default Index;
