import React, { useState, useEffect } from "react";
import ratePlanAdapter from "../../../adapters/ratePlanAdapter";
import electricPriceAdapter from "../../../adapters/electricPriceAdapter";
import gasPriceAdapter from "../../../adapters/gasPriceAdapter";
import { ElectricPrices } from "./priceDetails/ElectricPrices";
import { GasPrices } from "./priceDetails/GasPrices";
import informPrices from "../../../services/informPrices";
import toast, { Toaster } from "react-hot-toast";
import {
  Row,
  SubmitButton,
  Container,
  Title,
} from "../../../styles/informPrices";
import Select from "../../reactComponents/select/Select";
import powerSuppliedAdapter from "../../../adapters/powerSuppliedAdapter";
import calculateIndexedPrices from "../../../services/calculateIndexedPrices";

function Index({ rate_plans, env_color, market_in_use, organization, languages = [] }) {
  const [ratePlans, setRatePlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedElectricPrice, setSelectedElectricPrice] = useState(null);
  const [selectedGasPrice, setSelectedGasPrice] = useState(null);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [additionalPrice, setAdditionalPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [language, setLanguage] = useState(null);

  const handleAdditionalPriceChange = (e) => {
    const value = e.target.value;
    if ((value === "." || value === "," || value === "")) {
      setAdditionalPrice("");
      setErrorMessage("");
      return;
    }

    let normalizedValue = value.replace(',', '.');
    const decimalValue = normalizedValue.match(/^-?\d*(?:[.,]\d*)?$/);
    if (decimalValue ) {
      setAdditionalPrice(normalizedValue); 
      setErrorMessage("");  
    } else {
      setErrorMessage("Por favor, introduce un número decimal válido.");
    }

    if (organization.min_adjusted_rate_plan_price_range && value !== "") {
      const numericValue = parseFloat(normalizedValue);

      if (numericValue < organization.min_adjusted_rate_plan_price_range) {
        setErrorMessage(
          `El precio debe ser mayor o igual a ${organization.min_adjusted_rate_plan_price_range}.`
        );
      } else if (numericValue > organization.max_adjusted_rate_plan_price_range) {
        setErrorMessage(
          `El precio debe ser menor o igual a ${organization.max_adjusted_rate_plan_price_range}.`
        );
      } else {
        setErrorMessage("");
      }
    }
  };

  useEffect(() => {
      setRatePlans(ratePlanAdapter(rate_plans));
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPlan && selectedPlans.length == 0) {
      toast.error("Debe seleccionar un plan");
      return;
    }
    if (!language && languages?.length > 1) {
      toast.error("Debe seleccionar un idioma");
      return;
    }
    const params = {
      name: e.target.name.value,
      email: e.target.email.value,
      planType: market_in_use == 'energy' ? selectedPlan.planType : "telco",
      language: e.target.language?.value
    };
    if (params.planType === "telco") {
      params.rate_plan_ids = selectedPlans;
    }
    else if (params.planType === "dual") {
      params.price_id1 = selectedElectricPrice.id;
      params.price_type1 = "luz_2x";
      params.price_id2 = selectedGasPrice.id;
      params.price_type2 = "gas";
    } else if (selectedPlan.planType === "luz_2x" || selectedPlan.planType === "luz_3x") {
      params.price_id1 = selectedElectricPrice.id;
      params.price_type1 = selectedPlan.planType
      params.additionalPrice = e.target?.additionalPrice?.value;
    } else if (selectedPlan.planType === "gas") {
      params.price_id1 = selectedGasPrice.id;
      params.price_type1 = selectedPlan.planType;
      params.additionalPrice = e.target?.additionalPrice?.value;
    }


    try {
      let sendEmail = informPrices(params)

      toast.promise(sendEmail, {
        loading: <div>Enviando....</div>,
        success: <div>Informe de Precios enviado!</div>,
        error: <div>Hubo un error en el envio</div>,
      });
      await sendEmail;
      setTimeout(() => {
        document.getElementById("closeModal").click();
        e.target.reset();
        setSelectedPlan(null);
        setLanguage(null);
        setSelectedElectricPrice(null);
        setSelectedGasPrice(null);
        setSelectedPlans([]);
      }, 1500);
    } catch (error) {
      return null;
    }
  };

  const changePlan = async (idSet) => {
    if (idSet.size === 0) {
      setSelectedPlan(null);
    } else {
      if(market_in_use == "energy") {
        for (const elementId of idSet) {
          const newRatePlan = ratePlans.find((item) => item.id === elementId);
          if (
            newRatePlan.planType === "luz_2x" ||
            newRatePlan.planType === "luz_3x"
          ) {
            if (newRatePlan.isIndexedPrice) {
              await calculateIndexedPrices(
                newRatePlan,
                newRatePlan.electricPrices[0]
              );
            }
            setSelectedElectricPrice(electricPriceAdapter(newRatePlan.electricPrices[0]));
          } else if (newRatePlan.planType === "gas") {
            if (newRatePlan.isIndexedPrice) {
              await calculateIndexedPrices(newRatePlan, newRatePlan.gasPrices[0]);
            }
            setSelectedGasPrice(gasPriceAdapter(newRatePlan.gasPrices[0]));
          } else if (newRatePlan.planType === "dual") {
            if (newRatePlan.isIndexedPrice) {
              await calculateIndexedPrices(
                newRatePlan,
                newRatePlan.electricPrices[0]
              );
              await calculateIndexedPrices(newRatePlan, newRatePlan.gasPrices[0]);
            }
            setSelectedElectricPrice(electricPriceAdapter(newRatePlan.electricPrices[0]));
            setSelectedGasPrice(gasPriceAdapter(newRatePlan.gasPrices[0]));
          }
          setSelectedPlan(newRatePlan);
          setSelectedPlans([newRatePlan])
        }
      }else{
        let selectedPlansVar = [];
        for (const elementId of idSet) {
          selectedPlansVar.push(elementId);
        }
        setSelectedPlans(selectedPlansVar)
      }
    }
  };

  const changeElectricPrice = async (e) => {
    const price = selectedPlan.electricPrices.find((item) => {
      return parseInt(item.id) === parseInt(e.target.value);
    });
    if (selectedPlan.isIndexedPrice) {
      await calculateIndexedPrices(selectedPlan, price);
    }
    setSelectedElectricPrice(electricPriceAdapter(price));
  };

  const changeGasPrice = async (e) => {
    const price = selectedPlan.gasPrices.find((item) => {
      return parseInt(item.id) === parseInt(e.target.value);
    });
    if (selectedPlan.isIndexedPrice) {
      await calculateIndexedPrices(selectedPlan, price);
    }
    setSelectedGasPrice(gasPriceAdapter(price));
  };

  return (
    <>
      <Toaster />
      <form onSubmit={(e) => handleSubmit(e)}>
        <Title env_color={env_color}>Informar Precios</Title>
        <Container>
          <Select
            selectId="ratePlans"
            searchBar={true}
            multiSelect={market_in_use == "telco"}
            reactSetter={changePlan}
            data={rate_plans.map(rate_plan => ({ name: rate_plan.custom_display_text, id: rate_plan.id}))}
            dataSelected={selectedPlans}
            placeholder={"Seleccione el plan"}
          />
          {languages?.length > 1 && <Select
            selectId="language"
            multiSelect={false}
            reactSetter={setLanguage}
            data={languages?.map(lang => ({ name: lang[0], id: lang[1]})) || []}
            dataSelected={language}
            placeholder={"Seleccione el idioma"}
          />}
          

          {selectedPlan && (selectedPlan.planType === "luz_2x" || selectedPlan.planType === "dual") && (
            <>
              {selectedPlan.electricPrices.length > 1 ? (
                <select
                  style={{ margin: "20px 0px 20px 0px" }}
                  onChange={(e) => changeElectricPrice(e)}
                >
                  {selectedPlan.electricPrices.map((price) => (
                    <option key={price.id} value={price.id}>
                      {powerSuppliedAdapter(price)}
                    </option>
                  ))}
                </select>
              ) : (
                <div style={{ margin: "20px 0px 20px 0px" }}>
                  {powerSuppliedAdapter(selectedPlan.electricPrices[0])}
                </div>
              )}

              <ElectricPrices
                pricing={selectedElectricPrice}
                isIndexedPrice={selectedPlan.isIndexedPrice}
              />
            </>
          )}
          {selectedPlan && selectedPlan.planType === "luz_3x" && (
            <div style={{ paddingTop: "20px", width: "100%" }}>
              <ElectricPrices
                pricing={selectedElectricPrice}
                isIndexedPrice={selectedPlan.isIndexedPrice}
              />
            </div>
          )}

          {selectedPlan && (selectedPlan.planType === "gas" || selectedPlan.planType === "dual") && (
            <>
              {selectedPlan.gasPrices.length > 1 ? (
                <select
                  style={{ margin: "20px 0px 20px 0px" }}
                  onChange={(e) => changeGasPrice(e)}
                >
                  {selectedPlan.gasPrices.map((price) => (
                    <option key={price.id} value={price.id}>
                      {price.access_toll}
                    </option>
                  ))}
                </select>
              ) : (
                <div style={{ margin: "20px 0px 20px 0px" }}>
                  {selectedPlan.gasPrices[0].access_toll}
                </div>
              )}

              <GasPrices
                pricing={selectedGasPrice}
                isIndexedPrice={selectedPlan.isIndexedPrice}
              />
            </>
          )}
          

          {console.log("Valor de selectedPlan:", selectedPlan)}
          {organization.allow_adjust_rate_plan_price && selectedPlan?.allowAdjustRatePlanPrice && (
            <div style={{margin: "20px 0",textAlign: "center"}}>
              <label style={{display: "block", marginBottom: "10px"}}>
                Precio Adicional
              </label>
              <input
                type="text"
                id="additionalPrice"
                value={additionalPrice}
                onChange={handleAdditionalPriceChange}
                required={organization.min_adjusted_rate_plan_price_range > 0}
                placeholder="Introduce un precio"
                style={{ padding: "10px",textAlign: "center", width: "200px", marginBottom: "10px"}}
              />
              {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            </div>
          )}

          <Row>
            <input
              style={{ width: "45%" }}
              type="text"
              id="name"
              name="name"
              placeholder="Nombre"
              required
            />
            <input
              style={{ width: "45%" }}
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              required
            />
          </Row>
          <SubmitButton env_color={env_color} type="submit">ENVIAR</SubmitButton>
        </Container>
      </form>
    </>
  );
}

export default Index;
