import React from "react";

import { CustomTable, TableContainer } from "../../../../styles/rate_plans";

export const Table = ({
  data,
  markRecord,
  markedRecords,
  sideBarState,
  setSideBarState,
}) => {
  const isMarked = (id) => {
    return markedRecords.includes(id);
  };
  if (data.length === 0) {
    return <div style={{ marginLeft: "3%", marginTop: "100px" }}></div>;
  }
  return (
    <TableContainer
      style={
        sideBarState.show
          ? { width: "calc(96% - 300px)", top: "170px" }
          : { top: "170px" }
      }
    >
      <CustomTable>
        <thead style={{ position: "sticky", top: "0", margin: "0" }}>
          <tr>
            <th
              style={{
                borderRadius: "5px 0px 0px 0px",
                color: "#f5f5f5",
                backgroundColor: "#21103d",
                textAlign: "center",
                padding: "10px",
              }}
            >
              {" "}
              Nombre
            </th>
            <th
              style={{
                color: "#f5f5f5",
                backgroundColor: "#21103d",
                textAlign: "center",
                padding: "10px",
              }}
            >
              id
            </th>
            <th
              style={{
                color: "#f5f5f5",
                backgroundColor: "#21103d",
                textAlign: "center",
                padding: "10px",
              }}
            >
              Telefono
            </th>
            <th
              style={{
                color: "#f5f5f5",
                backgroundColor: "#21103d",
                textAlign: "center",
                padding: "10px",
              }}
            >
              Email
            </th>
            <th
              style={{
                borderRadius: "0px 5px 0px 0px",
                color: "#f5f5f5",
                backgroundColor: "#21103d",
                textAlign: "center",
                padding: "10px",
              }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {data.map((client) => (
            <tr
              style={
                isMarked(client.id)
                  ? {
                      height: "50px",
                      cursor: "pointer",
                      backgroundColor: "RGBA(0, 219, 255, 0.4)",
                    }
                  : {
                      height: "50px",
                      cursor: "pointer",
                      backgroundColor: "#ffffff",
                    }
              }
              key={client.id}
              onClick={() => markRecord(client.id)}
            >
              <td
                style={{
                  textAlign: "center",
                  padding: "10px",
                  borderBottom: "1px solid #21103d",
                }}
              >
                {client.name}
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "10px",
                  borderBottom: "1px solid #21103d",
                }}
              >
                {client.nif}
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "10px",
                  borderBottom: "1px solid #21103d",
                }}
              >
                {client.phone_number}
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "10px",
                  borderBottom: "1px solid #21103d",
                }}
              >
                {client.email}
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "10px",
                  borderBottom: "1px solid #21103d",
                }}
              >
                <button
                  style={{
                    border: "none",
                    borderRadius: "8px",
                    padding: "0px 15px",
                    fontSize: "24px",
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                    color: "#4a4a4a",
                    paddingBottom: "5px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      sideBarState.show &&
                      sideBarState.client.id == client.id
                    ) {
                      setSideBarState({ ...sideBarState, show: false });
                      return;
                    }
                    window.history.pushState({}, '', `/admin/clients/${client.id}`);
                    setSideBarState({
                      show: true,
                      client: client,
                    });
                  }}
                >
                  ...
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </CustomTable>
    </TableContainer>
  );
};
