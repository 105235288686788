import React from "react";

import { IoCloseSharp } from "react-icons/io5";

import toast, { Toaster } from "react-hot-toast";
import { ToolBarStyled } from "../../../../styles/rate_plans";
import axios from "axios";

export const ToolBar = ({
  markedRecords,
  unMarkAllRecords,
  fetchData,
}) => {

  const eliminar = async () => {
    try {
      await axios.get("/admin/rate_plans/destroy_multiple", {
        params: { ids: markedRecords },
      });
      toast.success(<div>Planes eliminados con éxito</div>);
      fetchData();
      unMarkAllRecords();
    } catch (error) {
      toast.error(
        <div>No se pudo realizar la acción sobre los planes seleccionados</div>
      );
    }
  };
  const exportPlans = () => {
    const idsComoCadena = markedRecords.join(",");
    window.location.href = `/admin/rate_plans/export_selected_rate_plans.xlsx?rate_plan_ids=${idsComoCadena}`;
    unMarkAllRecords();
  };

  return (
    <div>
      <Toaster />
      {markedRecords.length > 0 ? (
        <ToolBarStyled>
            <div>
              <IoCloseSharp
                size="18px"
                color="red"
                cursor="pointer"
                onClick={() => unMarkAllRecords()}
              />
              <strong>{markedRecords.length}</strong>&nbsp;clientes
              seleccionados
            </div>
        </ToolBarStyled>
      ) : null}
    </div>
  );
};
