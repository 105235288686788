import React, { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import styles from "./select.module.css";
import SelectOptionList from "./SelectOptionList";
import useCustomSelect from "./useCustomSelect";
import { array } from "prop-types";

const Select = ({
  data,
  dataSelected,
  selectId,
  selectName,
  searchBar,
  multiSelect,
  name,
  reactSetter,
  reactSetAll,
  placeholder,
  classNameName,
  onSelectItem,
}) => {
  const {
    selectedOptions,
    showListDiv,
    selected,
    inputValue,
    selectAll,
    selectedItemString,
    matchedSelected,
    toggleDiv,
    toggleSelected,
    toggleSelectAll,
    setSelectedItemString,
    setInputValue,
  } = useCustomSelect(
    data,
    dataSelected,
    selectId,
    multiSelect,
    reactSetter,
    reactSetAll,
    onSelectItem
  );

  const selectedArray = Array.from(selected);
  return (
    <div className={styles.container}>
      <select
        defaultValue={multiSelect ? selectedArray : selectedArray[0]}
        name={selectName}
        id={selectId}
        multiple={multiSelect}
      >
        {data?.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </select>
      <p className={classNameName ? classNameName : styles.title}>{name}</p>
      <div onClick={() => toggleDiv()} className={styles.selectHeader}>
        {multiSelect ? (
          <span>{selected.size} elementos seleccionados</span>
        ) : selected.size > 0 ? (
          selectedItemString || <span>{placeholder}</span>
        ) : (
          <span>{placeholder || "Seleccione un elemento"}</span>
        )}

        {showListDiv ? (
          <IoIosArrowUp className={styles.arrowIcon} />
        ) : (
          <IoIosArrowDown className={styles.arrowIcon} />
        )}
      </div>
      <div className={styles.toggledDiv}>
        {showListDiv && (
          <>
            <div className={styles.backdrop} onClick={toggleDiv}></div>
            <ul value={selectedOptions} className={styles.customSelector}>
              <div className={styles.searchBarContainer}>
                <div className={styles.checkboxContainer}>
                  {multiSelect && (
                    <>
                      <input
                        type="checkbox"
                        id="selectAllCheckbox"
                        name="selectAllCheckbox"
                        value="checked"
                        onChange={() => toggleSelectAll()}
                        checked={selectAll}
                        className={styles.checkboxes}
                      />
                      <label htmlFor="selectAllCheckbox">
                        Selecciona todos los elementos
                      </label>
                    </>
                  )}
                </div>
                {searchBar && (
                  <>
                    <AiOutlineSearch className={styles.searchIcon} />
                    <input
                      className={styles.searchBar}
                      placeholder="Buscar..."
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </>
                )}
              </div>
              <div className={styles.selectorContainer}>
                {data.length > 0 ? (
                  <SelectOptionList
                    selectedOptions={selectedOptions}
                    inputValue={inputValue}
                    toggleSelected={toggleSelected}
                    matchedSelected={matchedSelected}
                    selectedItemString={setSelectedItemString}
                    toggleDiv={toggleDiv}
                    multiSelect={multiSelect}
                  />
                ) : (
                  <p className={styles.noElements}>No hay elementos</p>
                )}
              </div>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default Select;
