import React, { useState, useEffect } from 'react';
import {
  FiltersRow,
  FilterItem
} from "../../../styles/individualComponents/AddTelcoProduct";

const LandlineFilters = ({ landlineFilters, handleLandlineFilterChange }) => {
  const [selectedMinutesNationals, setSelectedMinutesNationals] = useState(0);
  const [selectedMinutesInternationals, setSelectedMinutesInternationals] = useState(0);

  useEffect(() => {
    if (landlineFilters.landlineIsMinsNationalsUnlimited) {
      setSelectedMinutesNationals(0);
    }
  }, [landlineFilters.landlineIsMinsNationalsUnlimited]);

  useEffect(() => {
    if (landlineFilters.landlineIsMinsInternationalsUnlimited) {
      setSelectedMinutesInternationals(0);
    }
  }, [landlineFilters.landlineIsMinsInternationalsUnlimited]);

  useEffect(() => {
    handleLandlineFilterChange('landlineNumMinNationals', selectedMinutesNationals);
  }, [selectedMinutesNationals]);

  useEffect(() => {
    handleLandlineFilterChange('landlineNumMinInternationals', selectedMinutesInternationals);
  }, [selectedMinutesInternationals]);

  const handleCheckboxChange = (filterType, value) => { // Shows and hide the slide bars according to the behaviour of the checkboxes 
    handleLandlineFilterChange(filterType, value);
    if (filterType === 'landlineIsMinsNationalsUnlimited' || filterType === 'landlineIsMinsInternationalsUnlimited') {
      if (filterType === 'landlineIsMinsNationalsUnlimited') {
        setSelectedMinutesNationals(0);
      } else {
        setSelectedMinutesInternationals(0);
      }
    }
  };

  return (
    <>
      <div style={{ marginBottom: '20px' }}></div>
      <FiltersRow>
        <FilterItem>
          <label>
            <input
              type="checkbox"
              checked={landlineFilters.landlineIsMinsNationalsUnlimited}
              onChange={(e) => handleCheckboxChange('landlineIsMinsNationalsUnlimited', e.target.checked)}
            />
            Minutos nacionales ilimitados
          </label>
        </FilterItem>
        <FilterItem>
          <label>
            <input
              type="checkbox"
              checked={landlineFilters.landlineIsMinsInternationalsUnlimited}
              onChange={(e) => handleCheckboxChange('landlineIsMinsInternationalsUnlimited', e.target.checked)}
            />
            Minutos internacionales ilimitados
          </label>
        </FilterItem>
      </FiltersRow>
      {(!landlineFilters.landlineIsMinsNationalsUnlimited) && (
        <FiltersRow>
          <FilterItem style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span style={{ marginRight: '10px' }}>
              Minutos nacionales:
            </span>
            <input
              type="range"
              min="0"
              max="1000"
              step="10"
              value={selectedMinutesNationals}
              onChange={(e) => setSelectedMinutesNationals(e.target.value)}
              style={{ flexGrow: 1, marginRight: '10px' }}
            />
            <span>{selectedMinutesNationals} minutos</span>
          </FilterItem>
        </FiltersRow>
      )}
      {(!landlineFilters.landlineIsMinsInternationalsUnlimited) && (
        <FiltersRow>
          <FilterItem style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span style={{ marginRight: '10px' }}>
              Minutos internacionales:
            </span>
            <input
              type="range"
              min="0"
              max="1000"
              step="10"
              value={selectedMinutesInternationals}
              onChange={(e) => setSelectedMinutesInternationals(e.target.value)}
              style={{ flexGrow: 1, marginRight: '10px' }}
            />
            <span>{selectedMinutesInternationals} minutos</span>
          </FilterItem>
        </FiltersRow>
      )}
    </>
  );
};

export default LandlineFilters;
