// ResponsiveTable.js
import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Pagination from "./Pagination"; // Import the Pagination component
import {
  MdOutlineRemoveRedEye,
  MdDelete,
  MdSimCardDownload,
  MdOutlineEuroSymbol,
} from "react-icons/md";
import styles from "./index.module.css";

const ResponsiveTableRow = ({
  invoice,
  selectedRows,
  handleRowSelect,
  handleSelectedInvInnerBody,
  clickedInvoiceId,
}) => (
  <>
    <div
      onClick={() => handleSelectedInvInnerBody(invoice.invoice.id)}
      className={`${
        selectedRows.includes(invoice.invoice.id)
          ? styles.tableRowSelected
          : styles.tableRow
      }`}
    >
      <input
        type="checkbox"
        className={`${
          selectedRows.includes(invoice.invoice.id) ? styles.selectedRow : ""
        }`}
        checked={selectedRows.includes(invoice.invoice.id)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => handleRowSelect(invoice.invoice.id, e)}
      ></input>
      {clickedInvoiceId === invoice.invoice.id ? (
        <IoIosArrowUp className={styles.responsiveArrow} />
      ) : (
        <IoIosArrowDown className={styles.responsiveArrow} />
      )}

      <p>
        <span className={styles.resTitle}>CUPS: </span>
        {invoice.invoice.invoice_type === "dual" ? (
          <span>
            {invoice.cups}
            <br />
            {invoice.gas_cups}
          </span>
        ) : invoice.cups ? (
          invoice.cups
        ) : invoice.gas_cups ? (
          invoice.gas_cups
        ) : (
          ""
        )}
      </p>
      <p>
        <span className={styles.resTitle}> Comercial:</span>
      </p>
    </div>
    {clickedInvoiceId === invoice.invoice.id && (
      <span className={styles.resTableInnerBody}>
        <p>
          <span className={styles.resTitle}> Org:</span>
          {invoice.organization_name}
        </p>
        <p>
          <span className={styles.resTitle}> Tipo:</span>
          {invoice.gas_access_toll ? invoice.gas_access_toll : invoice.access_toll}
        </p>
        <p>
          {" "}
          <span className={styles.resTitle}> Tarifa:</span>
          {invoice.invoice.invoice_type}
        </p>
        <p className={styles.actionsSpace}>
          <span className={styles.resTitle}> Acciones:</span>
          <span
            onClick={(e) => e.stopPropagation()}
            className={styles.acciones}
          >
            <>
              {invoice.savings.length > 0 ? (
                <a
                  href={`/energy_invoices/${invoice.invoice.id}/saving_calculations`}
                >
                  <MdOutlineEuroSymbol
                    className={`${styles.icons} ${styles.compIcon}`}
                  />
                </a>
              ) : (
                ""
              )}
            </>
            <a href={`/energy_invoices/${invoice.invoice.id}`}>
              <MdOutlineRemoveRedEye className={styles.icons} />
            </a>
            <a
              href={`/api/v2/energy_invoices/${invoice.invoice.id}/download_input_document`}
              className="invoice-download"
              download
            >
              <MdSimCardDownload className={styles.icons} />
            </a>
            <MdDelete
              className={`${styles.icons} ${styles.trashIcon}`}
              onClick={() => deleteEnergyInvoice(invoice.invoice.id)}
            />
          </span>
        </p>
      </span>
    )}
  </>
);

const ResponsiveTable = ({
  records,
  selectedRows,
  handleRowSelect,
  handleSelectedInvInnerBody,
  clickedInvoiceId,
  currentPage,
  totalPages,
  onChangePage,
}) => (
  <>
    <div className={styles.responsiveTable}>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChangePage={onChangePage}
      />
      {records.map((invoice, index) => (
        <ResponsiveTableRow
          key={index}
          invoice={invoice}
          selectedRows={selectedRows}
          handleRowSelect={handleRowSelect}
          handleSelectedInvInnerBody={handleSelectedInvInnerBody}
          clickedInvoiceId={clickedInvoiceId}
        />
      ))}
    </div>
  </>
);

export default ResponsiveTable;
