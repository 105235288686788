import React, {useState, useEffect} from "react"
import { useFormik } from "formik";
import { validationSchema } from "../validations/validationSchema";

import TextArea from "../../../reactComponents/textArea/TextArea";
import Select from "../../../reactComponents/select/Select";
import Input from "../../../reactComponents/input/Input";

import {findValueMatch} from "../utils/utils"

const Index = ({commission, current_user_roles}) => {
  const [customErrors, setCustomErrors] = useState({});
  
  useEffect(() => {
    formik.setErrors(customErrors);
  }, [customErrors]);

  const setCustomError = (field, message) => {
    setCustomErrors((prevErrors) => ({
      ...prevErrors,
      commission: {
        ...prevErrors.commission,
        [field]: message,
      },
    }));
  };

  const formik = useFormik({
    initialValues: {
      commission: {
        commission_name: commission?.commission_name || "",
        code: commission?.code || "",
        cost: commission?.cost || 0,
        range_start: "",
        range_end: "",
        range_cost: ""
      },
    },
    validationSchema: validationSchema,
  });

  const commissionTypeData = [
    {name:"Fija Por Producto",id:"fixed"},
    {name:"En Función De La Potencia Contratada", id:"power_supplied_based"},
    {name:"En Función Del Consumo",id: "consumption_based"},
  ];
  const invoiceType = [{name: "Luz",id:"electric"}, {name:"Gas", id:"gas"}]

  const [showSpecialCommission, setShowSpecialCommission] = useState(
    current_user_roles.some((rol) => rol.name === "Admin")
  );
  const [rangeStart, setRangeStart] = useState("");
  const [description, setDescription] = useState(commission.description);
  const [message, setMessage] = useState(commission.message);
  const [special_commission, setSpecialCommission] = useState(commission.special_commission);
  const placeholdercommissionType = findValueMatch(
    commissionTypeData,
    commission.commission_type
  );
  const placeholderInvoiceType = findValueMatch(
    invoiceType,
    commission.invoice_type
  );


  const [selectedValue, setSelectedValue] = useState(
    commission.commission_type
  );   
  const onSelectValueFunction = (neWselected,item) => {
    setSelectedValue(item);
  };
  
  const transform_ranges = (array_text) => {
    let ranges = []
    let elements_length = array_text.length
    if (elements_length == 0 || elements_length % 3 != 0){return ranges}

    let i = 0
    while (i < elements_length){
      ranges.push([{
        id: ranges.length,
        periodo: array_text[i],
        rango: array_text[i+1],
        coste: array_text[i+2]
      }])
     i+= 3
    }
    return ranges;
  };

  const [commissions, setCommissions] = useState(transform_ranges(commission.range_cost));
  const datacomision = [
    { id: "p1", name: "p1" },
    { id: "p2", name: "p2" },
    { id: "total_consumption", name: "Consumo Total" },
  ]; 

  const [showPowerselect, setShowPowerSelect] = useState(false);
  const [periodo, setPeriodo] = useState("");
  const onSelectPeriodo = (item) => {
    setPeriodo(Array.from(item)[0])
  }
  const onClickAddRange = (e) => {
    e.preventDefault();
    setShowPowerSelect(true);
  };

  const stringIsEmpty = (range) => {
    return (range.length === 0);
  };

  const stringHasOnlyDigitsChars = (range) => {
    return (/^[0-9]+$/.test(range));
  };

  const stringHasDigitsOrDecimalFormat = (str) => {
    return (/^\d+(\.\d+)?$/.test(str));
  };

  const rangeStartGreaterThanEqualRangeEnd = (start_range, end_range) => {

    if (parseInt(start_range) >= parseInt(end_range)) {
      setCustomError('range_start', 'El rango de inicio tiene que ser menor que el rango final');
      setCustomError('range_end', 'El rango final tiene que ser mayor que el rango de inicio');
      return true;
    }
    return false;
  };

  function isRangeCovered(rangesArray, rangeStart, rangeEnd) {
    let userStart = Number(rangeStart);
    let userEnd = Number(rangeEnd);
    
    for (let i = 0; i < rangesArray.length; i++) {
        let rangeString = rangesArray[i][0].rango;
        let [start, end] = rangeString.split('-').map(Number);
        
        if ((userStart >= start && userEnd <= end) || (userStart <= end && userEnd >= start)) {
            return true;
        }
    }
    
    return false;
  }

  const rangesAlreadyCovered = (range_start, range_end) => {
    let length = commissions.length;
    let isCovered = false;

    if (length > 0){
        isCovered = isRangeCovered(commissions, range_start, range_end)
    }
  
    if (isCovered){
        setCustomError('range_start', 'El rango introducido ya está dentro de un rango creado');
        setCustomError('range_end', 'El rango introducido ya está dentro de un rango creado');
    } 
    return isCovered;
  };

  const validStartRange = (start_range, end_range) => {
    return (
      !stringIsEmpty(start_range) &&
      stringHasOnlyDigitsChars(start_range) &&
      !rangeStartGreaterThanEqualRangeEnd(start_range, end_range)
    );
  };

  const validEndRange = (end_range) => {
    return (
      !stringIsEmpty(end_range) &&
      stringHasOnlyDigitsChars(end_range)
    );
  };

  const validRangeCost = (range_cost) => {
    return (
      !stringIsEmpty(range_cost) &&
      stringHasDigitsOrDecimalFormat(range_cost)
    );
  }

  const validPeriod = () => {
    return (
      !stringIsEmpty(periodo)
    );
  }

  const addNewPower = (e) => {
    e.preventDefault();
    let start_range = document.getElementById("commission_range_start").value;
    let end_range = document.getElementById("commission_range_end").value;
    let range_cost = document.getElementById("ranges_cost").value;

    let start_range_valid = validStartRange(start_range);
    let end_range_valid = validEndRange(end_range);
    let ranges_covered = rangesAlreadyCovered(start_range, end_range);

    if (!start_range_valid || !end_range_valid || ranges_covered){
      return;
    }

    if (!validRangeCost(range_cost)){
      return;
    }

    if (!validPeriod()){
      return;
    }

    const newCommission = [
      {
        id: commissions.length,
        periodo: periodo,
        rango: `${formik.values.commission?.range_start}-${formik.values.commission?.range_end}`,
        coste: `${formik.values.commission?.range_cost}`,
      },
    ];

    setCommissions([...commissions, newCommission]);
    setShowPowerSelect(false);
    setPeriodo("");
    formik.setFieldValue('commission.range_start', '');
    formik.setFieldValue('commission.range_end', '');
    formik.setFieldValue('commission.range_cost', '');
  };

  return (
    <>
      
      {commissions.map((element, index) => {
        commission = element[0];
        return (
          <React.Fragment key={commission.id + '-' + index}>
            <Input
              key={commission.id + '-period-' + index}
              name="commission[range_cost][]"
              value={commission.periodo}
              type="hidden"
              only_input="true"
            />
            <Input
              key={commission.id + '-range-' + index}
              name="commission[range_cost][]"
              value={commission.rango}
              type="hidden"
              only_input="true"
            />
            <Input
              key={commission.id + '-cost-' + index}
              name="commission[range_cost][]"
              value={commission.coste}
              type="hidden"
              only_input="true"
            />
          </React.Fragment>
          )
        })}
      <Input
        label={
          <>
            Nombre de la comisión <span style={{ color: "red" }}>*</span>
          </>
        }
        type="text"
        name="commission[commission_name]"
        id="commission_name"
        required="required"
        value={formik.values.commission.commission_name}
        onChange={(e) => formik.handleChange(e)}
        error={formik.errors.commission?.commission_name}
        touched={formik.touched.commission?.commission_name}
      />
      <Input
        label={
          <>
            Código <span style={{ color: "red" }}>*</span>
          </>
        }
        type="text"
        id="commission_code"
        name="commission[code]"
        required="required"
        value={formik.values.commission.code}
        onChange={(e) => formik.handleChange(e)}
        error={formik.errors.commission?.code}
        touched={formik.touched.commission?.code}
      />
      <TextArea
        labelText="Descripción"
        name="commission[description]"
        id="commission_description"
        defaultValue={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextArea
        labelText="Mensaje"
        name="commission[message]"
        id="commission_message"
        defaultValue={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      {showSpecialCommission && (
        <div>
          <label htmlFor="commission_special_commission">
            Comisión especial (Ruby)
          </label>
          <small className="form-text">
            Código Ruby para retornar el coste de la comisión especial. Código Ruby donde el dato a manejar es la variable "s_calc".
          </small>
          <TextArea
            name="commission[special_commission]"
            id="commission_special_commission"
            defaultValue={special_commission}
            onChange={(e) => setSpecialCommission(e.target.value)}
          />
        </div>
      )}

      <Select
        multiSelect={false}
        searchBar={false}
        name="Tipo de factura"
        data={invoiceType}
        dataSelected={commission.invoice_type}
        selectId="commission_invoice_type"
        selectName="commission[invoice_type]"
        placeholder={placeholderInvoiceType}
      />
      <Select
        multiSelect={false}
        searchBar={false}
        name="Tipo de comisión"
        data={commissionTypeData}
        dataSelected={selectedValue}
        selectId="commission_commission_type"
        selectName="commission[commission_type]"
        placeholder={placeholdercommissionType}
        reactSetter={onSelectValueFunction}
      />
      {selectedValue === "fixed" && (
        <Input
          label={
            <>
              Coste Comisión <span style={{ color: "red" }}>*</span>
            </>
          }
          type="number"
          id="commission_cost"
          name="commission[cost]"
          required="required"
          value={formik.values.commission.cost}
          onChange={formik.handleChange}
          error={formik.errors.commission?.cost}
          touched={formik.touched.commission?.cost}
        />
      )}

      {(selectedValue === "power_supplied_based" || selectedValue === "consumption_based" ) && (
        <div
          style={{
            padding: "10px",
            margintop: "10px",
            borderRadius: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Rangos:</p>{" "}
            <button onClick={ onClickAddRange }>
              Añadir Rango
            </button>
          </div>
          <div>
            {commissions.map((innerArray, outerIndex) => (
              <div key={commission.id+outerIndex}>
                {Array.isArray(innerArray) && innerArray.map((mission, innerIndex) => (
                    <p
                      key={mission.id+innerIndex}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "2px",
                        padding: "5px",
                        marginBottom: "2px",
                      }}
                    >
                      {mission.id}: Periodo: {mission.periodo} - rango:{" "}
                      {mission.rango} - Coste: {mission.coste}
                    </p>
                  ))}
              </div>
            ))}
          </div>
        </div>
      )}
      {showPowerselect && (
        <div
          style={{
            backgroundColor: "white",
            width: "80%",
            borderRadius: "10px",
            margin: "0 auto",
            padding: "10px",
            margintop: "10px",
          }}
        >
          <Select
            multiSelect={false}
            searchBar={false}
            name="Seleccione Periodo/Consumo"
            data={datacomision}
            dataSelected={periodo}
            selectId="comsion_id"
            selectName="comsion_name"
            placeholder="Seleccionar"
            reactSetter={onSelectPeriodo}
          />
          <div style={{ display: "flex" }}>
            <span
              style={{ padding: "10px", position: "relative", top: "25px" }}
            >
              Rango:
            </span>
            <Input
              type="text"
              id="commission_range_start"
              name="commission[range_start]"
              required="required"
              value={formik.values.commission?.range_start}
              onChange={(e) => formik.handleChange(e)}
              error={formik.errors.commission?.range_start}
              touched={formik.touched.commission?.range_start}
            />
            <span
              style={{ padding: "10px", position: "relative", top: "25px" }}
            >
              a
            </span>
            <Input
              type="text"
              id="commission_range_end"
              name="commission[range_end]"
              required="required"
              value={formik.values.commission?.range_end}
              onChange={(e) => formik.handleChange(e)}
              error={formik.errors.commission?.range_end}
              touched={formik.touched.commission?.range_end}
            />
          </div>
          <div style={{ display: "flex" }}>
            <span
              style={{ padding: "10px", position: "relative", top: "25px" }}
            >
              Coste
            </span>

            <Input
              type="text"
              id="ranges_cost"
              name="commission[range_cost]"
              required="required"
              value={formik.values.commission?.range_cost}
              onChange={(e) => formik.handleChange(e)}
              error={formik.errors.commission?.range_cost}
              touched={formik.touched.commission?.range_cost}
            />
          </div>
          <button style={{margin: "0 auto"}} onClick={addNewPower}>Añadir Rango</button>
        </div>
      )}
    </>
  );
}

export default Index;