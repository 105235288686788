import React, { useState } from "react";
import styles from "./formStyles.module.css";
import { useFormik } from 'formik';
import {validationSchema} from "../validations/validationSchema"
import { AiOutlineInfoCircle } from "react-icons/ai";

//components

import Select from "../../../reactComponents/select/Select";
import Input from "../../../reactComponents/input/Input";
import TextArea from "../../../reactComponents/textArea/TextArea";
import Checkbox from "../../../reactComponents/checkbox/CheckBox";
import Modal from "../../../reactComponents/modal/Modal";

const configSimulaciones = ({ organization, current_user_roles }) => {
  //modal
  const formik = useFormik({
    initialValues: {
      organization: {
        r_plan_info_display_fmt_invoice_sims: 
        organization.r_plan_info_display_fmt_invoice_sims
      },
    },
    validationSchema: validationSchema,
  });
  const userIsAdmin = () => {
    return current_user_roles.some((rol) => rol.name === "Admin");
  };
  const [showConfSimuModal, setShowConfSimuModal] = useState(false);
  const configSimulacionesModalText = [
    {
      header: "¿Permitir simulaciones entre fechas específicas?",
      text: "Permite realizar simulaciones entre fechas específicas. Si no se \
      activa, entonces para la simulación simplemente habrá que introducir el nº \
      de días.",
    },
    {
      header: "¿Permitir simulaciones detalladas de Luz 2.0TD?",
      text: "Habilita la funcionalidad de realizar simulaciones detalladas para 2.0TD. \
      En este caso es una simulación con mayor detalle donde se comparan costes de Potencia \
      y Energía."
    },
    {
      header: "Tipo de Tarifas a aplicar en Simulaciones",
      text: "Permite elegir entre los tipos de tarifas que se aplicarán para las \
      simulaciones. Se puede seleccionar entre Fijas o Indexadas (o ámbas).",
    },
  ];

  const [allowSimulationBetweenDates, setAllowSimulationBetweenDates] =
    useState(organization.allow_simulation_between_dates);
  const [allowDetailedSimulations, setAllowDetailedSimulations] = useState(
    organization.allow_detailed_simulations
  );

  const configSimulacionGenericaCheckboxData = [
    {
      onChange: () =>
        setAllowSimulationBetweenDates(!allowSimulationBetweenDates),
      checked: allowSimulationBetweenDates,
      name: "organization[allow_simulation_between_dates]",
      id: "organization_allow_simulation_between_dates",
      label: "¿Permitir simulaciones entre fechas específicas?",
    },
    {
      onChange: () => setAllowDetailedSimulations(!allowDetailedSimulations),
      checked: allowDetailedSimulations,
      name: "organization[allow_detailed_simulations]",
      id: "organization_allow_detailed_simulations",
      label: "¿Permitir simulaciones detalladas de Luz 2.0TD?",
    },
  ];

  const simulationRPlanesData = [
    {
      id: "fixed_prices",
      name: "Fijas",
    },
    {
      id: "indexed_prices",
      name: "Indexadas",
    },
  ];
  
  return (
    <>
      {showConfSimuModal && (
        <Modal
          data={configSimulacionesModalText}
          onClose={() => setShowConfSimuModal(false)}
        />
      )}
      <h3 className={styles.title}>
        Configuración Simulaciones
        <AiOutlineInfoCircle
          className={styles.infoIcon}
          onClick={() => setShowConfSimuModal(true)}
        />
      </h3>
      <div className={styles.checkboxDivFullWidth}>
        <h6>Configuración Simulaciones</h6>
        <div className={styles.scrollDiv}>
          <div className={styles.checkBoxContent}>
            {configSimulacionGenericaCheckboxData.map((item) => {
              return (
                <Checkbox
                  key={item.id}
                  onChange={item.onChange}
                  checked={item.checked}
                  name={item.name}
                  id={item.id}
                  label={item.label}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Select
        multiSelect={true}
        searchBar={true}
        name="Tipo de Tarifas a aplicar en Simulaciones"
        data={simulationRPlanesData}
        dataSelected={organization.simulation_rate_plan_types}
        selectId="organization_simulation_rate_plan_types_"
        selectName="organization[simulation_rate_plan_types][]"
      />

      {userIsAdmin() &&
        <>
          <TextArea
              labelText="Formato de visualización de datos de tarifario en simulaciones"
              name="organization[r_plan_info_display_fmt_invoice_sims]"
              id="r_plan_info_display_fmt_invoice_sims"
              required="required"
              placeholder={organization.r_plan_info_display_fmt_invoice_sims}
              defaultValue={formik.values.organization.r_plan_info_display_fmt_invoice_sims}
              value={formik.values.organization.r_plan_info_display_fmt_invoice_sims}
              onChange={formik.handleChange}
              error={formik.errors.organization?.r_plan_info_display_fmt_invoice_sims}
              touched={formik.touched.organization?.r_plan_info_display_fmt_invoice_sims}
            />
        </>
      }
    </>
  );
};

export default configSimulaciones;
