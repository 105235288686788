import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: start;
  padding: 0px 20px 0px 20px;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #5c6166;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
  padding-left: 50px;
`;


export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
`;

export const Price = styled.div`
  margin-bottom: 5px;
  text-align: left;
  margin-left: 60px;
`;
