import React, { useState } from "react";
import styles from "./historicoTarifario.module.css";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const HistoricoTarifarioLuz = ({ electricPricesData }) => {
  const [toggle, setToggle] = useState(false);
  const handleToggleClick = () => {
    setToggle(!toggle);
  };

  const pricesElectric = (electricPricesData) => {
    return (
      <div key={electricPricesData.id} className={styles.preciosContainer}>
        <p>
          <span className={styles.tarifaDetails}>Precios Para: </span>
          {electricPricesData.power_supplied_allowance}
        </p>
        <p>
          <span className={styles.tarifaDetails}>Tipo DH: </span>
          {electricPricesData.dh_type}
        </p>
        <p>
          <span className={styles.tarifaDetails}>Selección de la comisión: </span>
          {electricPricesData.commission_id}
        </p>
        
        <p>
          <span className={styles.tarifaDetails}>Precio Potencia P1: </span>
          {electricPricesData.power_price_p1}
        </p>
        <p>
          <span className={styles.power_price_p2}>Precio Potencia P2: </span>
          {electricPricesData.power_price_p2}
        </p>
        <p>
          <span className={styles.tarifaDetails}>Precio Energía P1: </span>
          {electricPricesData.energy_price_p1}
        </p>
        <p>
          <span className={styles.tarifaDetails}>Precio Energía P2: </span>
          {electricPricesData.energy_price_p2}
        </p>
        <p>
          <span className={styles.tarifaDetails}>Precio Energía P3: </span>
          {electricPricesData.energy_price_p3}
        </p>
        <p>
          <span className={styles.tarifaDetails}>Fecha Inicio: </span>
          {electricPricesData.created_at}
        </p>
        <p>
          <span className={styles.tarifaDetails}>Closed Date: </span>
          {electricPricesData.closed_date}
        </p>
      </div>
    );
  };

  return (
    <div className={styles.historicoContainer}>
      <p onClick={handleToggleClick} className={styles.button}>
        Histórico Precios
        {toggle ? (
          <IoMdArrowDropup className={styles.icon} />
        ) : (
          <IoMdArrowDropdown className={styles.icon} />
        )}
      </p>
      {toggle && electricPricesData.map(pricesElectric)}
    </div>
  );
};

export default HistoricoTarifarioLuz;
